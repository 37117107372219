.shipment {
  display: flex;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 6px;
  padding: 12px;
  height: 520px;
  & .box {
    border-radius: 0px 6px 6px 0px;
    padding: 0 12px;
    &-divider {
      border-top: 1px solid #ebebeb;
      flex-basis: 100%;
      &-orange {
        border-top: 1px solid #fbb739;
      }
    }
    &-services {
      display: flex;
      flex-direction: column;
      flex-basis: 70%;
      height: 496px;
      padding-left: 0;
      &__label {
        font-size: 16px;
        line-height: 24px;
        color: #1a1a1a;
        font-weight: 500;
        white-space: nowrap;
      }
      &__attach {
        margin-bottom: 12px;
      }

      &__addresses {
        display: flex;
        flex-direction: column;
        &-item {
          display: flex;
          align-items: baseline;
          line-height: 22px;
          font-size: 14px;
          line-height: 22px;
          color: #1a1a1a;
          & > i {
            color: #707070;
            margin-right: 12px;
          }
          & .receiver {
            font-weight: 500;
          }
        }
        &-notice {
          display: flex;
          padding: 8px;
          background: #fff6e5;
          border: 1px solid #ffa600;
          border-radius: 6px;
          margin-top: 12px;
          align-items: center;
          & > i:first-of-type {
            color: #faa200;
          }
          position: relative;
          & > i:last-of-type {
            position: absolute;
            top: 12px;
            right: 12px;
          }

          & .content {
            font-size: 14px;
            line-height: 22px;
            color: #1a1a1a;
            margin-top: 0;
            padding: 0;
            background-color: unset;
            padding-right: 16px;
          }
        }
      }

      &__note {
        display: flex;
        flex-direction: column;
        &-item {
          display: flex;
          align-items: center;
          & .label {
            font-size: 12px;
            line-height: 20px;
            color: #707070;
          }
        }
      }
      & .connections-selection {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;
        & .ant-select {
          width: fit-content;
        }
      }
    }

    &-fees {
      flex-basis: 30%;
      display: flex;
      padding-right: 0;
      flex-direction: column;
      justify-content: space-between;
      border-left: 1px solid #ebebeb;
      height: 496px;
      &__shipment-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &__details {
        & .label,
        & .value {
          font-size: 14px;
          line-height: 22px;
        }

        & .label,
        & .value {
          color: #1a1a1a;
        }
      }
    }
  }

  .ant-btn-text:hover,
  .ant-btn-text:focus {
    background: transparent;
    color: #f59f32;
  }
  .empty-address .ant-empty-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > button {
      width: 141px;
    }
  }
}

.form-shipment {
  & .ant-form-item-label {
    flex-basis: 145px;
    & > label {
      flex-direction: row-reverse;
      font-size: 14px;
      line-height: 22px;
      color: #707070;
    }
  }

  & .ant-form-item-control-input-content input {
    border: 1px solid #c0c0c0;
    border-radius: 4px;
    padding: 4px 12px;
    line-height: 22px;
    &:hover {
      border-color: #ffce63;
      border-right-width: 1px;
    }

    &:placeholder-shown {
      font-size: 14px;
      line-height: 22px;
    }
  }
  .ant-form-item-label {
    & > label {
      color: #1a1a1a;
      &.ant-form-item-no-colon {
        &::after {
          margin: 0;
        }
      }
    }
  }
}

.consignment {
  &.provider-radio {
    &_light {
      &:hover {
        background-color: #f6f6f6;
        border: 1px solid #7e7e7e;
        & .ant-radio .ant-radio-inner {
          border-color: #7e7e7e;
        }
      }
      &.ant-radio-wrapper-checked {
        background: #fff9df !important;
        border: 1px solid #fcd535 !important;
        & .ant-radio .ant-radio-inner {
          border-color: #fcd535;
        }
      }
    }
    &_dark {
      &:hover {
        background-color: #32373e;
        border: 1px solid #7e7e7e;
        & .ant-radio .ant-radio-inner {
          border-color: #7e7e7e !important;
        }
      }
      &.ant-radio-wrapper-checked {
        border: 1px solid #fcd535 !important;
        background-color: #32373e;
        & .ant-radio .ant-radio-inner {
          border-color: #fcd535;
        }
        .ant-radio-input:focus + .ant-radio-inner {
          box-shadow: 0 0 0 3px rgb(252 213 53 / 0%);
        }
      }
    }
  }

  &-popover {
    & .ant-popover-inner {
      border-radius: 8px;
      & .ant-popover-inner-content {
        padding: 16px;
      }
    }
  }
  & .ant-radio {
    margin-right: 8px;
    & + * {
      padding-right: 0;
      padding-left: 0;
    }
  }
  &.active {
    border-color: transparent !important;
  }

  & .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    height: 44px;
    line-height: 44px;
  }

  & .ant-input {
    border-radius: 8px;
    height: 44px;
  }

  &-popover-services {
    margin-bottom: 16px;
    &-content {
      display: flex;
      flex-direction: column;
      & > .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 16px;
        font-weight: 500;
      }

      & > .popover-services {
        display: flex;
        margin-bottom: 16px;
        & > .ant-btn {
          &.ant-btn-default:not([disabled]):not(.included) {
            &:hover,
            &:focus {
              background-color: #ebebeb !important;
              color: #1a1a1a !important;
              border-color: transparent !important;
            }
          }
          &.active.ant-btn-default:not(.included):hover {
            background-color: #fcd535 !important;
            color: #1a1a1a !important;
          }
        }
      }
    }
  }
}

.single-service {
  &.active:hover {
    background: #fcd535 !important;
    border-color: transparent !important;
  }
  &:hover {
    background-color: #ebebeb !important;
    color: #1a1a1a;
    border-color: transparent !important;
  }
}

.condition-service {
  //background: #fff9df;
  border: 1px solid #c0c0c0 !important;
  border-radius: 16px;
  padding: 2px 12px;
  font-size: 12px;
  line-height: 20px;
  margin-right: 16px;
  margin-bottom: 12px;
}

.consignment-tooltip {
  & .ant-tooltip-inner {
    white-space: nowrap;
    width: fit-content;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.15);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
}
.box-services__addresses {
  &.light {
    .address-info-icon {
      color: #5a5a5a;
    }
  }
}
.box-services__addresses-notice {
  position: relative;
  padding: 12px 24px 12px 36px;
  background-color: #fff9df;
  border: 1px solid #ffeb97;
  border-radius: 6px;
  color: #fdb924;
  & .fas.fa-times {
    position: absolute;
    top: 17px;
    right: 12px;
  }
  & .icon-notice {
    position: absolute;
    top: 13px;
    left: 12px;
  }
}

.box-services__addresses-notice_dark {
  @extend .box-services__addresses-notice;
  background-color: transparent;
}

.box-services__addresses-notice_light {
  @extend .box-services__addresses-notice;
}
