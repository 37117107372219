._note-icon-custom {
  :hover {
    cursor: pointer;
  }
}
._btn-order-custom {
  border-radius: 4px !important;
  border: 1px solid #ffd7a5 !important;
  height: 42px !important;
  background-color: #ffffff !important;
  color: #ffa634 !important;
  &._btn-order-custom:hover {
    color: white !important;
    background-color: #ffa634 !important;
  }
}
.notice-warehouse {
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px;
  /* Lighter */
  background: #fff6e5;
  margin-top: 12px;

  /* Border */
  border: 1px solid #ffa600;
  box-sizing: border-box;
  border-radius: 6px;
}

.box-step-service {
  background-color: transparent !important;

  &.ant-card {
    div.ant-card-body {
      background-color: transparent;
    }

    & .box-step-service__left {
      padding: 16px;
      flex: 7;

      & .ant-select-selector {
        border-radius: 4px;
        & .ant-avatar-image {
          border: none;
        }
      }

      & .label-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        margin-top: 32px;
      }
    }

    & .box-step-service__right {
      flex: 3;
      padding: 16px;
      border-radius: 8px;
      height: fit-content;

      .freeContainer {
        box-sizing: border-box;
        display: flex !important;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;

        .total-money {
          background-color: var(--bg-lighter-3-color);
          padding: 12px;
          gap: 16px;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
.btn-edit-address {
  color: var(--text-secondary-color) !important;
  &:hover {
    color: var(--text-active) !important;
  }
}
