.fee-box {
	&-top {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}
	&-bottom {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}
}

