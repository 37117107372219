.payment-info {
  background: var(--bg-lighter-3-color);
  border-radius: 6px;
  padding: 12px 16px;
  display: grid;
  gap: 16px;
  font-weight: 500;
}

.money-missing-warning {
  background-color: var(--bg-error);
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  border-radius: 8px;
  color: $error-bg-hover;

  .link {
    color: #008df9;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
}

.light {
  --method-item-title: #7d7e7e;
  --method-item-des: #b1b1b1;
  --method-item-title-hover: #1a1a1a;
  --method-item-des-hover: #7d7e7e;
  --method-item-title-active: #1a1a1a;
  --method-item-des-active: #7d7e7e;
  --method-item-border-active: #f0c100;
}

.dark {
  --method-item-title: #666666;
  --method-item-des: #666666;
  --method-item-title-hover: #bdbdbd;
  --method-item-des-hover: #bdbdbd;
  --method-item-title-active: #fcd535;
  --method-item-des-active: #bdbdbd;
  --method-item-border-active: #fcd535;
}

.method-item {
  border: 1px solid var(--border-normal);
  border-radius: 8px;
  padding: 16px 24px;
  display: grid;
  gap: 30px;
  cursor: pointer;
  grid-template-columns: 50px auto 20px;
  align-items: center;

  > svg {
    width: 100%;
    height: 100%;
    fill: var(--method-item-title);
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    color: var(--method-item-title);
  }
  .description {
    color: var(--method-item-des);
    display: grid;
    gap: 24px;
  }
  .icon {
    opacity: 0;
  }

  &:hover {
    border-color: #7e7e7e;

    > svg {
      fill: var(--method-item-title-hover);
    }

    .title {
      color: var(--method-item-title-hover);
    }
    .description {
      color: var(--method-item-des-hover);
    }
  }

  &.active {
    border-color: var(--method-item-border-active);

    > svg {
      fill: var(--method-item-title-active);
    }

    .title {
      color: var(--method-item-title-active);
    }
    .description {
      color: var(--method-item-des-active);
    }
    .icon {
      opacity: 1;
      color: var(--method-item-title-active);
    }
  }

  &.disabled {
    border-color: var(--border-color);
    cursor: no-drop;
    > svg {
      fill: var(--method-item-title);
    }
    .title {
      color: var(--muted-color);
    }
    .description {
      color: var(--disable-color);
    }
  }
}
