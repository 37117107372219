@import './main';
@import './text';
@import './background';
@import './uncategorized';
@import './chat';

@mixin generateVariables($mode, $themesInput) {
  @each $colorKey, $colorsWithMode in $themesInput {
    @each $modeName, $color in $colorsWithMode {
      @if $modeName == $mode {
        --#{$colorKey}: #{$color};
      }
    }
  }
}

.dark {
  @include generateVariables('darkTheme', $mainTheme);
  @include generateVariables('darkTheme', $textThemes);
  @include generateVariables('darkTheme', $backgroundThemes);
  @include generateVariables('darkTheme', $unCategorizedTheme);
  @include generateVariables('darkTheme', $chatTheme);
}

.light {
  @include generateVariables('lightTheme', $mainTheme);
  @include generateVariables('lightTheme', $textThemes);
  @include generateVariables('lightTheme', $backgroundThemes);
  @include generateVariables('lightTheme', $unCategorizedTheme);
  @include generateVariables('lightTheme', $chatTheme);
}

:root {
  --blue-link: #1890FF;
  --main-text: #1a1a1a;
}

.dark {
  --blue-link: #ECEDF8;
  --main-text: #1a1a1a;
}