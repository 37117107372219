// .packages-filter {
// 	.ant-form-item {
// 		margin: 0;
// 		color: #1a1a1a;
// 		font-size: 14px;
// 		// line-height: 22px;
// 		font-family: 'Roboto', sans-serif;
// 		display: flex;
// 		align-items: center;
// 		.ant-form-item-label {
// 			margin-right: 4px;
// 			& > label {
// 				font: inherit;
// 				height: 22px;
// 				color: #1a1a1a;
// 				&::after {
// 					margin: 0;
// 				}
// 			}
// 		}
// 		& .ant-input-affix-wrapper {
// 			height: 28px;
// 			border-radius: 4px;
// 			&:not(:focus, :hover) {
// 				border-color: #c7c7c7;
// 				box-shadow: none;
// 			}

// 			& > input {
// 				font-size: 14px;
// 				line-height: 22px;
// 				padding: 0;
// 			}
// 		}
// 		input {
// 			font: inherit;
// 			border-radius: 4px;
// 			&::placeholder {
// 				color: #bdbdbd;
// 			}
// 			padding: 2px 9px;
// 			&[type='date'] {
// 				border: none;
// 			}
// 		}

// 		&.status-cutoff,
// 		&.query {
// 			& .ant-form-item-label {
// 				flex-basis: 142px;
// 				flex-shrink: 0;
// 			}
// 		}

// 		.ant-picker-range {
// 			border-color: #c7c7c7;
// 			padding: 0 9px;
// 			border-radius: 4px;
// 			font: inherit;
// 		}
// 	}
// 	& .ant-row {
// 		& .type-search {
// 			display: flex;
// 			flex-wrap: nowrap;
// 			align-items: center;
// 			font-family: 'Roboto', sans-serif;
// 			font-size: 14px;
// 			line-height: 22px;
// 			& .ant-select {
// 				flex-basis: 23%;
// 			}
// 			& .ant-form-item {
// 				&:first-of-type {
// 					margin-right: 4px;
// 				}
// 				flex-basis: 100%;
// 				flex-direction: row-reverse;
// 			}
// 		}
// 	}

// 	& .ant-select:not(.ant-select-customize-input) .ant-select-selector {
// 		border-color: #c7c7c7;
// 		box-shadow: none;
// 		border-radius: 4px;
// 		line-height: 22px;
// 		padding-top: 3px;
// 		padding-bottom: 3px;
// 		height: 28px;
// 		font: inherit;
// 		font-size: 14px;
// 		& .ant-select-selection-item {
// 			display: flex;
// 			align-items: center;
// 			gap: 4px;
// 			font-size: 14px;
// 			.ant-image {
// 				display: flex;
// 				align-items: center;
// 			}
// 		}
// 		.ant-select-selection-placeholder {
// 			display: flex;
// 			align-items: center;
// 		}
// 	}
// }

// .m22-badge {
// 	font-family: 'Roboto', sans-serif;
// 	padding: 4px 16px;
// 	line-height: 20px;
// 	font-size: 12px;
// 	border-radius: 4px;
// 	border: none !important;
// 	background-color: #f6f6f6;
// 	color: #fbb739;
// 	font-weight: 500;
// 	margin-right: 12px;
// 	margin-bottom: 12px;
// 	white-space: nowrap;
// 	text-transform: capitalize;
// 	border-radius: 16px;
// 	cursor: pointer;
// 	color: #5a5a5a;

// 	&:hover {
// 		background-color: #ebebeb;
// 	}
// 	&.active {
// 		background-color: #faa200;
// 		color: white;
// 	}
// }

.packages {
  .package-timeline {
    .ant-steps-item {
      &.ant-steps-item-finish,
      &.ant-steps-item-wait {
        .ant-steps-item-tail {
          margin: 0 auto !important;
          bottom: 1px !important;
          top: unset !important;
          &::after {
            height: 0 !important;
            margin-left: 15px !important;
          }
        }
        .ant-steps-item-container {
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-between;
          gap: 2px;
          height: 100%;
          .ant-steps-item-content {
            margin: 0;
            .ant-steps-item-title {
              display: flex;
              align-items: flex-start;
            }
          }
        }
      }
      &.ant-steps-item-finish {
        i {
          color: #c7c7c7;
        }
        .ant-steps-item-tail {
          &::after {
            border: 1px solid #c7c7c7;
          }
        }
        &.tail-none {
          .ant-steps-item-tail {
            display: none;
          }
          &.last-tail {
            i {
              color: #fbb739;
            }
          }
        }
      }
      &.ant-steps-item-wait {
        &.tail-none {
          .ant-steps-item-title > span,
          .description > span {
            color: #bdbdbd;
          }
          .ant-steps-item-tail {
            display: none;
          }
          .ant-steps-item-icon {
            visibility: hidden;
          }
        }
        .ant-steps-item-tail {
          &::after {
            border: 1px solid #c7c7c7;
          }
        }
      }
    }
    .ant-steps-item-content {
      width: 100% !important;
      text-align: left !important;
    }
    .ant-steps-item-icon {
      margin: 0 !important;
      width: 10px !important;
      height: 10px !important;
    }
    td {
      padding-top: 12px !important;
    }
  }
  .ant-table-expanded-row {
    & > td {
      padding: 8px 32px 15px 32px !important;
    }
  }
  .ant-pagination-total-text {
    float: left;
    margin-right: auto;
    order: -2;
    border: none !important;
  }
  .ant-pagination-options {
    order: -1;
    margin-right: 16px;
    border-radius: 4px;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      font: inherit;
      color: #1a1a1a;
      border: 1px solid #ebebeb;
    }
  }

  .ant-pagination {
    font-family: 'Roboto', sans-serif;
    & .ant-pagination-item {
      border-radius: 4px !important;
      border: 1px solid #c7c7c7 !important;
      color: #1a1a1a;
      margin-right: 4px !important;
      &.ant-pagination-item-active {
        background-color: #faa200;
        border: none !important;
        > a {
          color: white;
          font: inherit;
        }
      }
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      &.ant-pagination-disabled {
        background: #f5f5f5;
      }
      border-radius: 4px !important;
      border: 1px solid #c7c7c7;
      color: #1a1a1a;
      margin-right: 4px !important;
    }
  }
}
