.form-filter {
  .ant-form-item {
    margin: 0;
    color: #1a1a1a;
    font-size: 14px;
    line-height: 22px;
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    width: 100%;

    & .ant-form-item-row {
      width: 100%;
    }

    .ant-form-item-label {
      margin-right: 4px;
      display: flex;
      align-items: center;

      &>label {
        font: inherit;
        height: 22px;
        color: #1a1a1a;

        &::after {
          margin: 0;
        }
      }
    }

    input {
      font: inherit;
      border-radius: 4px;

      &::placeholder {
        color: #bdbdbd;
      }

      padding: 0px 1px;

      &[type='date'] {
        border: none;
      }
    }

    & .ant-input-affix-wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.status-cutoff,
    &.query {
      & .ant-form-item-label {
        flex-shrink: 0;
      }
    }

    .ant-picker-range {
      padding: 0 9px;
      border-radius: 4px;
      height: 44px;
      line-height: 44px;
      font: inherit;
    }
  }

  & .ant-row {
    & .type-search {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 12px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      line-height: 22px;

      & .ant-select {
        flex-basis: 23%;
      }

      & .ant-form-item {
        &:first-of-type {
          margin-right: 4px;
        }

        flex-basis: 100%;
        flex-direction: row-reverse;
        gap: 12px;
      }
    }
  }

  & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    border-radius: 4px;
    // line-height: 22px;
    // padding-top: 3px;
    // padding-bottom: 3px;
    // height: 28px;
    font: inherit;
    font-size: 14px;

    & .ant-select-selection-item {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;

      .ant-image {
        display: flex;
        align-items: center;
      }
    }

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
  }
}

.ant-form-item {
  margin-bottom: 12px;
}

.clean-filter {
  >span:first-child {
    transform: rotate(-90deg);
  }

  padding-right: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: none;

  &.ant-btn:not([disabled]):hover {
    color: #1a1a1a;
  }

  &[ant-click-animating-without-extra-node='true'] {
    &::after {
      animation: fadeEffect 3s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.8s cubic-bezier(0.08, 0.82, 0.17, 1);
      opacity: 0.2;
      box-shadow: 0px 0px 0px 3px #ced1f3 !important;
    }

    & .anticon-redo {
      transition: 3s all ease-in-out;
      transform: rotate(270deg) !important;
    }
  }

  &.ant-btn:not([disabled]):active,
  &:focus {
    color: #707070;
  }

  &:hover {
    color: #1a1a1a;
  }
}

.ant-form-item-explain>.ant-form-item-explain-error {
  padding-top: 6px;
  margin-bottom: 12px;
}

.ant-form-item.ant-form-item-with-help.ant-form-item-has-error+.label {
  top: 24px;
}

.floating-label {
  position: relative;

  .ant-form-item-label {
    z-index: 99;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 14px;
    top: 12px;
    transition: 0.2s ease all;
    font-size: 14px;
    padding: 0 4px !important;

    label {
      color: $txt-color-gray7;
    }
  }

  &.float {
    .ant-form-item-label {
      top: -10px;

      label {
        font-size: 12px;
        background: #FFF;
        padding: 0 4px !important;
        left: -4px;

        &::before {
          content: 'aa';
          width: 100%;
          height: 20px;

          background-color: red;
          display: inline-block;
          position: absolute;
          top: 0;
        }
      }
    }
  }

}

.ant-form-item-has-error.floating-label.float {
  .ant-form-item-label label{
    color: #ff4559;
  }
}

.floating-label {
  .ant-form-item-explain {
    margin-bottom: 8px;
  }

  .ant-form-item-explain-error {
    padding-left: 16px;
    font-size: 12px;
  }
}