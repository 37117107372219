.status-view {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-radius: 30px;
  height: 30px;
  color: #fff;
}

.txt-error {
  color: var(--error-color);
}

.txt-success {
  color: var(--success-color);
}

.txt-disable {
  color: var(--disable-color);
}

.txt-muted {
  color: var(--muted-color);
}

.txt-muted-2 {
  color: var(--muted-color-2);
}

.txt-muted-3 {
  color: var(--muted-color-3);
}

.txt-color-main {
  color: $txt-color-main;
}

.txt-primary {
  color: var(--text-primary-color);
}

.txt-primary-2 {
  color: var(--text-primary-color-2) !important;
}

.txt-secondary {
  color: var(--text-secondary-color) !important;
}

.txt-secondary-hover {
  @extend .txt-secondary;
  &:hover {
    color: var(--text-secondary-hover-color);
  }
}

.txt-sub-tab {
  color: var(--text-profile-sub-tab);
}

.vertial-middle {
  vertical-align: middle;
}

.text-link {
  color: var(--text-link) !important;
}

.text-tabs-dashboard {
  color: var(--text-tabs-dashboard);
}
