.ant-steps {
  .ant-steps-item-process,
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: var(--text-secondary-color);
  }

  &.order-process,
  &.claim-process {
    .ant-steps-item-title {
      font-size: 14px;

      i {
        font-size: 24px;
        display: block;
        margin-bottom: 0px;
        color: $success-color;
      }
    }

    .ant-steps-item-tail::after {
      margin-left: 15px;
    }

    .ant-steps-item {
      > .ant-steps-item-container {
        > div.ant-steps-item-icon {
          width: 12px;
          height: 12px;

          .ant-steps-icon {
            font-size: 18px;
            color: $bg-color-blue5;
            left: -3px;
            top: -2px;
          }
        }
        > div.ant-steps-item-tail {
          z-index: 1;
          padding: 0;
          left: -10px;
          padding-top: 20px;
          padding-bottom: 8px;

          &::after {
            width: 2px;
            background-color: $bg-color-blue5 !important;
          }
        }
      }

      &.ant-steps-item-active {
        div.ant-steps-item-tail {
          padding-top: 10px;
        }

        .ant-steps-item-icon {
          box-shadow: 0px 0px 0px 4px var(--success-shadow-color);
          margin-top: 8px;

          background: $bg-color-blue5;
        }
      }

      &.ant-steps-item-wait {
        .ant-steps-item-content {
          span,
          p {
            color: var(--disable-color);
          }
        }
      }

      &:last-of-type {
        .ant-steps-item-tail::after {
          height: 0;
        }
      }
    }
  }
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 24px !important;
}

.ant-steps.ant-steps-small {
  .ant-steps-label-vertical .ant-steps-item-content {
    margin-top: 12px;
  }
  .ant-steps-item-title {
    font-weight: 500;
  }
  .ant-steps-item-description {
    font-size: 12px;
  }
}