._m24-pagination {
    .ant-pagination-item {
        border-radius: 6px;
    }
    .ant-pagination-item-active {
        background-color: #f9a400;
        //border: 1px solid #CC8700;
        a {
            color: #ffffff;
            :active {
                color: white;
            }
            &:hover {
                color: white;
            }
        }
    }
}

._m24-pagination {
    .ant-pagination-item-link {
        border-radius: 6px !important;
    }
    & .ant-pagination-item-active {
        border: none;
    }
}

._m24-pagination {
    .ant-pagination-disabled .ant-pagination-item-link {
        background-color: #ebebeb;
    }
}

._size-changer {
    &.dark {
        &:not(.ant-select-customize-input) {
            div {
                &.ant-select-selector {
                    border-radius: 4px;
                    border-color: #5e5e5e;
                    &:hover {
                        border-color: #af9730;
                    }
                }
            }
        }
    }
    &:not(.ant-select-customize-input) {
        div {
            &.ant-select-selector {
                border-radius: 4px;
                border-color: #ebebeb;
            }
        }
    }
}

.ant-select._size-changer {
    margin-right: 16px;
}
