.ant-tabs {
  .ant-tabs-nav-wrap {
    height: 40px;

    .ant-tabs-nav-list {
      height: 100%;
    }
  }
  &.ant-tabs-top {
    .ant-tabs-nav {
      &::before {
        display: none;
      }

      .ant-tabs-nav-list {
        border-bottom: 1px solid var(--border-color);
      }
    }
  }
}

.dark {
  .ant-tabs-tab {
    &:not(.ant-tabs-tab-active) {
      sup.ant-scroll-number.ant-badge-count {
        background-color: #32373e !important;
      }
    }
  }
}
