.package-data {
	tr {
		>td {
			padding: 5px 16px 11px 16px !important;

			&:first-of-type {
				padding-left: 12px !important;
			}
		}
	}
}

.package-timeline-scroll {
	overflow: auto !important;
	width: calc(100vw - 415px);

	@media screen and (min-width:1320px) {
		width: calc(100vw - 479px);
	}
}