.shipment {
  &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    box-shadow: 0px 2px 14px #e6e6e6;
    border-radius: 6px;
    background-color: white;
    padding: 16px;
    margin-bottom: 16px;
    &.dark {
      .shipment-heading__left .finance-item__label {
        color: #bdbdbd;
      }
    }
    &__left {
      display: flex;
      align-items: center;
      gap: 48px;

      & .info {
        display: flex;
        gap: 16px;

        & .ant-avatar {
          border-radius: 4px;
        }

        &-text {
          display: flex;
          flex-direction: column;
          justify-content: center;

          &__code-status {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 4px;

            & .status-shipment {
              border-radius: 25px;
              color: white;
              padding: 2px 12px;
              text-transform: capitalize;
              margin-right: 4px;
            }
            & .code-shipment {
              font-size: 14px;
              color: #1a1a1a;
            }
          }

          &-connection {
            display: flex;
            align-items: center;
            color: #1a1a1a;
            & .ant-divider {
              border-left: 1px solid #1a1a1a;
              margin: 0 4px;
            }
          }

          &__code {
            color: #1a1a1a;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }

      & .finance {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 4px;

        &-item {
          display: flex;
          align-items: center;
          &__label {
            color: #707070;
            margin-right: 4px;
            white-space: nowrap;
          }

          &__value {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            // color: #1a1a1a;
          }
        }
      }

      & .merchant {
        display: flex;
        & .ant-avatar {
          margin-right: 4px;
        }
        &-label {
          color: #707070;
          margin-right: 4px;
        }
        &-info {
          &__name {
            color: #1a1a1a;
          }
        }
      }
    }

    &-left {
      & .ant-btn {
        font-size: 14px;
      }
    }
  }

  &-details {
    display: flex;
    gap: 16px;

    &.dark {
      .shipment-details__text-top {
        background-color: #1f2328;
      }

      .shipment-details__text-top .basic-item__label,
      .shipment-details__text-top .basic .item__label {
        color: #bdbdbd;
      }

      .shipment-details__text-top .finance-details-fees,
      .shipment-details__text-top .finance .summary {
        background-color: #32373e;
      }

      .shipment-details__text-top .finance-details-fees .item .label {
        color: #e6e6e6;
      }

      .shipment-details__milestone {
        background-color: #1f2328;
        box-shadow: none;
      }
    }

    &__text {
      flex-basis: calc((100% - 12px) * 8 / 10);

      &-top {
        display: grid;
        grid-template-columns: 60% auto;
        background-color: white;
        margin-bottom: 16px;
        padding: 24px;
        border-radius: 6px;
        gap: 24px;

        position: relative;

        &.collapsed {
          padding: 16px;
        }

        & .basic {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;
          &-collapsed {
            margin-bottom: 0;
          }
          &-title {
            color: #1a1a1a;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 5px;
          }
          &-item {
            .service {
              font-weight: 500;
            }

            .whitespace:first-child .service {
              margin-left: -6px;
            }

            &:not(:last-of-type) {
              margin-bottom: 8px;
            }
          }

          &-item,
          & .item {
            display: flex;

            &__label {
              line-height: 20px;
              color: var(--muted-color);
              white-space: nowrap;
              margin-right: 2px;
              min-width: 210px;
              display: inline-block;

              &.no-space {
                min-width: unset;
                margin-right: 12px;
              }
            }

            &__content {
              font-size: 14px;
              flex-basis: 80px;
              flex-grow: 1;
            }
          }

          &-number-data {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            & .item {
              display: flex;
              align-items: baseline;
              flex-basis: calc((100% - 24px) / 2);
              font-size: 14px;
              line-height: 22px;
              flex-shrink: 0;
              margin-right: 10px;
              & .ant-typography-ellipsis {
                & > span:first-of-type {
                  white-space: nowrap;
                }
              }
              &-services {
                flex-basis: 100%;
                align-items: baseline;
                & .item__label {
                  line-height: 22px;
                }
                & .service {
                  font-size: 14px;
                  white-space: nowrap;
                }
              }

              & .service-divider {
                margin: 0 4px;
              }
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }

          &-note,
          & .address-warehouse {
            display: flex;
            flex-direction: column;
            & .item {
              display: flex;
              align-items: baseline;
              &__label {
                font-size: 12px;
                line-height: 20pxx;
                color: #5a5a5a;
                white-space: nowrap;
                margin-right: 3px;
              }

              & .info-item {
                position: relative;
                & .fa-copy,
                & .fa-check {
                  position: absolute;
                  bottom: 5px;
                  cursor: pointer;
                }
              }
            }
          }

          & .ant-divider {
            margin: 16px 0;
          }

          &-collapsed {
            & .basic-title {
              margin-bottom: 0;
            }
          }
        }
        & .finance {
          padding-bottom: 24px;
          display: flex;
          flex-direction: column;
          &-title {
            color: #1a1a1a;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
          &-details-fees,
          & .summary {
            border-radius: 4px;
            padding: 16px;
            margin-top: 8px;
            margin-bottom: 16px;
            background-color: var(--bg-lighter-3-color);
            & .item {
              display: flex;
              align-items: center;
              justify-content: space-between;

              &:not(:last-of-type) {
                margin-bottom: 14px;
              }

              & .label,
              & .value {
                font-size: 14px;
                line-height: 22px;
                color: var(--text-primary-color);
              }

              & .label {
                &-notice {
                  color: var(--disable-color);
                }
              }

              & .value {
                &-by-marketplace {
                  font-size: 10px;
                  line-height: 12px;
                  color: #707070;
                }
              }
            }

            & .fee-items {
              display: flex;
              justify-content: space-between;
              padding: 0 8px;
              padding-right: 0;
              font-size: 14px;
              line-height: 22px;

              &:not(:last-of-type) {
                margin-bottom: 14px;
              }

              & .label {
                color: var(--text-secondary-color);

                &-notice {
                  color: var(--disable-color);
                }
              }
            }

            & .ant-divider {
              margin: 6px 0;
              border-top: 1px solid #ffd7a5;
            }
          }
          &-collapse {
            padding: 0;
          }
        }
      }
    }

    &__milestone {
      background-color: white;
      box-shadow: 0 2px 14px #e6e6e6;
      border-radius: 6px;
      padding: 24px;
      flex-basis: calc((100% - 12px) * 2 / 10);
      height: fit-content;
      min-width: 228px;

      & .heading {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-tabs {
      background-color: white;
      border-radius: 4px;
      & .ant-tabs-nav-wrap {
        padding: 0 12px;
      }
    }
  }

  &-collapse {
    position: absolute;
    bottom: 12px;
    right: calc(50% - 25px);
    cursor: pointer;
    color: var(--muted-color);
    font-size: 12px;

    &.collapsed {
      right: 16px;
      top: 18px;
    }
  }

  &-confirm-delete {
    & .ant-modal-close {
      right: -10px;
    }
    & .ant-modal-confirm-body {
      & .ant-modal-confirm-content {
        font-size: 14px;
        line-height: 22px;
      }
      & .ant-modal-confirm-title {
        font-size: 16px;
        line-height: 24px;
      }
    }
    & .ant-modal-confirm-btns {
      display: flex;
      justify-content: flex-end;
    }
  }
  &-product-form,
  &-delete-product {
    & .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      order: 1;
    }

    & .ant-modal-content {
      & .ant-form-item-label > label {
        font-size: 14px;
        line-height: 22px;
        // color: #1a1a1a;
      }

      & .ant-input,
      & .ant-input-number {
        // border: 1px solid #c0c0c0;
        border-radius: 6px;
        // color: #1a1a1a;
        padding: 0 12px 0 16px;
        font-size: 14px;
        width: 100%;
      }

      & .ant-input-number {
        & .ant-input-number-input {
          padding: 0;
        }
      }

      & .ant-modal-body {
        & .ant-modal-confirm-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #1a1a1a;
        }

        & .ant-form-item.image {
          height: 112px;
        }
      }
    }
  }

  &-modal-create-waybill {
    & .ant-modal-content {
      & .ant-modal-title {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.create-waybills {
  &-error,
  &-warning {
    border-radius: 4px;
    padding: 4px 5px;
    display: flex;
    align-items: center;
  }
  &-error {
    background: #ffedef;
    border: 1px solid #ff4559;
    color: #ff4559;
  }

  &-warning {
    background: #fff9ea;
    border: 1px solid #fdb924;
    border-radius: 4px;
    color: #fdb924;
  }
}
.btn {
  & [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
    border-color: transparent !important;
  }
  &-edit {
    &:hover {
      color: var(--muted-color-2) !important;
    }
    border: none !important;
    background: none !important;
    & > button {
      background: none !important;
      border: none !important;
    }
  }
}
