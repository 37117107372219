.address-btn-custom {
	background-color: transparent;
	&.ant-btn-text:not(:disabled) {
		color: #F5222D !important;
	}
	&:hover {
		&.ant-btn-text:not(:disabled) {
			color: #F5222D !important;
				background: none !important;
		}
	}
	
}

._address_count {
	border-radius: 5px;
	background-color: rgba(232, 229, 229, 0.92);
	width: 20px;
	display: inline-block;
	color: orange;
	text-align: center;
}
._address-table-custom thead > tr > th {
	//text-align: center;
}
._address-table-custom thead {
	height: 32px;
}
._address-table-custom {
	& .ant-modal-content {
		.ant-modal-header {
			padding: 12px 0;
			& .ant-modal-title > span.count {
				color: #faa200 !important;
			}
		}
		& .ant-modal-body {
			padding-top: 0;
		}
		& .ant-modal-footer {
			padding-bottom: 12px;
		}
	}
}
._address-table-custom tbody > tr > td {
	border: none;
	padding-top: 12px;
	padding-bottom: 12px;
}
._address-create-modal {
	.ant-modal-content {
		.ant-modal-header {
			padding-top: 12px;
			padding-bottom: 12px;
      border: none;
			& .ant-modal-title {
				color: #1a1a1a;
        font-size: 16px;
        line-height: 24px;
			}
		}
    & .ant-modal-body {
			padding-top: 0;
			padding-bottom: 0;
			// height: 480px;
			& .ant-input, & .ant-select, & .ant-select-item {
				// font-size: 14px;
				color: #1a1a1a;
			}
		}
	}
	& .ant-modal-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

.address-table {
	.ant-table-body {
		&  table {
			border-spacing: 0 10px!important;
		}

	}

	& .ant-table-thead {
		tr > th {
			&.ant-table-cell {
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				//background-color: #f4f6f8;
				padding: 0 16px!important;
				border-bottom: none;
				color: #1a1a1a;
				white-space: nowrap;
				&::before {
					width: 0 !important;
				}
				&.ant-table-row-expand-icon-cell {
					padding: 0 !important;
					width: 0 !important;
					padding-left: 12px;
				}
			}
		}
	}
	& table > thead > tr:first-child th:first-child {
		border-top-left-radius: 6px!important;
		//background-color: red;
	}

	& table > thead > tr:first-child th:last-child {
		border-top-right-radius: 6px!important;
		//background-color: red;
	}

	& table > tbody > tr > td:first-child {
		border-top-left-radius: 12px!important;
		border-bottom-left-radius: 12px !important;
		//background-color: red;
	}

	& table > tbody > tr > td:last-child {
		border-top-right-radius: 12px!important;
		border-bottom-right-radius: 12px !important;
		//background-color: red;
	}

	.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
		background: #f6f6f6;
	}
	.ant-table tbody > tr > td {
		border-bottom: 1px solid transparent!important;
	}

}

.address-table-light {
	& .ant-table-thead {
		tr > th {
			&.ant-table-cell {
				background-color: #F6F6F6;
				color: #5A5A5A;
			}
		}
	}
}

.address-table-dark {
	& .ant-table-thead {
		tr > th {
			&.ant-table-cell {
				background-color: #32373E;
				color: #BDBDBD;
			}
		}
	}

	.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
		background: #32373E!important;
	}
}


.edit-action  {
	color: var(--disable-color);
	opacity: 0;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
	cursor: pointer;
}

// .tableWareHouse.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
// 	cursor: default;
// }

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover .edit-action {
	opacity: 1;
}


.edit-action_dark {
	@extend .edit-action;
	&:hover {
		color: #FFFFFF;
	}
}

.m22-modal-create-address_dark {
	.ant-modal-content, .ant-modal-header, .ant-modal-body {
		background: #1F2328
	}
}
.edit-action_light{
	@extend .edit-action;
	&:hover {
		color: #1A1A1A
	}
}

.m22-form-item_mg0 {
	& .ant-form-item-row {
		height: 22px;
		& .ant-form-item-control-input {
			min-height: 22px;
		}
	}
}

.m22-modal-create-address_dark {
	.ant-modal-content, .ant-modal-header, .ant-modal-body {
		background: #1F2328
	}
}
