.package-timeline {
  // &.detail {
  //   width: calc(100vw - 500px);
  // }
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 12px;
  padding-bottom: 12px;

  .ant-steps {
    // min-width: 1280px;
  }

  &.dark {
    .ant-steps-item {
      &.ant-steps-item-finish,
      &.ant-steps-item-wait,
      &.ant-steps-item-process {
        .ant-steps-item-tail {
          margin: 0 auto !important;
          bottom: 1px !important;
          top: unset !important;
          &::after {
            height: 0 !important;
            margin-left: 15px !important;
          }
        }
        .ant-steps-item-container {
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-between;
          gap: 2px;
          height: 100%;
          .ant-steps-item-content {
            margin: 0;
            .ant-steps-item-title {
              display: flex;
              align-items: flex-start;
            }
          }
        }
      }
      &.ant-steps-item-finish {
        .ant-steps-item-title {
          span {
            // color: #1a1a1a;
            color: #e6e6e6;
          }
        }

        i {
          color: #666666;
        }
        .ant-steps-item-tail {
          &::after {
            border: 1px solid #666666;
          }
        }

        &.tail-none {
          .ant-steps-item-tail {
            display: none;
          }
          &.last-tail {
            i {
              color: #fcd535;
            }
          }
        }
      }
      &.ant-steps-item-wait {
        &.tail-none {
          .ant-steps-item-title > span,
          .description > span {
            color: #666666;
          }
          .ant-steps-item-tail {
            display: none;
          }
          .ant-steps-item-icon {
            visibility: hidden;
          }
        }
        .ant-steps-item-tail {
          &::after {
            // border: 1px solid #fbb739;
            border: 1px solid #666666;
          }
        }
      }
    }
    .ant-steps-item-content {
      width: 100% !important;
      text-align: left !important;
    }
    .ant-steps-item-icon {
      margin: 0 !important;
      width: 10px !important;
      height: 10px !important;
    }
  }

  .ant-steps-item {
    &.ant-steps-item-finish,
    &.ant-steps-item-wait,
    &.ant-steps-item-process {
      .ant-steps-item-tail {
        margin: 0 auto !important;
        bottom: 1px !important;
        top: unset !important;
        &::after {
          height: 0 !important;
          margin-left: 15px !important;
        }
      }
      .ant-steps-item-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        gap: 2px;
        height: 100%;
        .ant-steps-item-content {
          margin: 0;
          .ant-steps-item-title {
            display: flex;
            align-items: flex-start;
          }
        }
      }
    }

    &.ant-steps-item-finish {
      i {
        color: #c7c7c7;
      }

      .ant-steps-item-tail {
        &::after {
          border: 1px solid #ebebeb;
        }
      }

      &.tail-none {
        .ant-steps-item-tail {
          display: none;
        }
        &.last-tail {
          i {
            color: #fcd535;
          }
        }
      }
    }

    &.ant-steps-item-wait {
      &.tail-none {
        .ant-steps-item-title > span,
        .ant-steps-item-description > span {
          color: var(--disable-color);
        }
        .ant-steps-item-tail {
          display: none;
        }
        .ant-steps-item-icon {
          visibility: hidden;
        }
      }
      .ant-steps-item-tail {
        &::after {
          border: 1px solid #c7c7c7;
        }
      }
    }
  }

  .ant-steps-item-content {
    width: 100% !important;
    text-align: left !important;
  }
  .ant-steps-item-icon {
    margin: 0 !important;
    width: 10px !important;
    height: 10px !important;
  }
}
