.profile-container {
	.menu-item {
		padding-top: 30px;

		.item {
			margin-bottom: 12px;
			width: 100%;
			align-items: center;
			padding: 12px 16px;
			background: #ffffff;
			border-radius: 6px 0px 0px 6px;
			color: #1a1a1a;
			cursor: pointer;

			&.__current {
				background-color: color(color-primary);
				color: color(color-black-primary);
			}
		}
	}

	.content-container {
		//padding: 12px;
		background: #ffffff;
		box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
		border-radius: 6px;
	}
	.content-container-dark {
		//padding: 12px;
		background: #1f2328;
		// box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
		border-radius: 6px;
	}

	.profile-title {
		padding: 12px;
		//margin: 0 0 0 -10px;
		width: 100%;

		&.ant-divider-horizontal.ant-divider-with-text-left::before {
			width: 0;
		}

		&.ant-divider-horizontal.ant-divider-with-text-left::after {
			//border: 1px solid #EBEBEB;
			border-color: #ebebeb;
		}
	}

	.user-info {
		margin-top: -11px;
		//background-color: gray;
		.ant-form-item {
			margin: 12px;
			width: 90%;
		}

		.ant-form-item-label {
			color: #1a1a1a;

			label {
				font-family: Roboto, sans-serif;
				font-weight: 400;
				font-size: 14px;
				color: #1a1a1a;
			}
		}

		.ant-form-item-control {
			//margin-left: 40px;
			color: #1a1a1a;
			font-family: Roboto, sans-serif;
			font-weight: 400;
			font-size: 14px;
			width: 70%;
		}

		.ant-form-item-control .ant-form-item-explain {
			padding: 4px 0 24px 0;
		}

		.ant-form-item-control-input .ant-form-item-control-input-content {

			.ant-picker {
				.ant-picker-input input {
					font-size: 14px;
				}
			}

			.ant-upload {
				color: #008df9;
				font-size: 14px;

				&:hover {
					color: #1a1a1a;
				}
			}
		}

		.btn-save-info {
			width: 180px;
			margin: 5px 0 0 0;
			border: 1px solid #3340b6;
			box-sizing: border-box;
			border-radius: 4px;
			color: #3340b6;

			&:hover {
				color: #ffffff;
				background-color: #3340b6;
				box-sizing: border-box;
				//box-shadow: 0 0 0 3px #c1f3f0;
			}

			&:disabled {
				color: #bdbdbd;
				background-color: #ebebeb;
				border: 1px solid #c7c7c7;
			}
		}
	}

	.vendor-list {
		background-color: #ffffff;
		margin-top: 16px;

		.vendor-item {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin: 0 12px 16px 12px;
			padding: 20px 32px;
			background: #f4f6f8;
			border-radius: 6px 0 0 6px;

			&-expired {
				background: #ffedef;
				border-radius: 6px 0 0 6px;
			}

			.ant-row {
				display: flex;
			}

			.ant-col {
				display: grid;
				//align-items: center;
			}

			.vendor-description {
				display: flex;
				flex-direction: column;
				text-align: justify;
				margin: 0 10px 0 25px;
			}
		}

		.purchase-btn {
			font-family: 'Roboto', sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			color: #3340b6;
			background: #ffffff;
			box-shadow: 0 19px 20px -11px rgba(51, 64, 182, 0.15);
			border-radius: 4px;
			height: 40px;
			border: none !important;
			text-transform: capitalize;

			&:hover {
				box-shadow: 0 0 0 3px #ced1f3;
				border-radius: 4px;
				border: 1px solid #3340b6;
			}
		}
	}
}

.package-modal {
	background: #ffffff;
	box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
	border-radius: 6px !important;
	padding: 0 0 0 0;
	overflow: hidden;

	.ant-list-item:hover {
		background: unset;
	}
	//max-height: 500px;
	//max-height: calc(100vh - 150px);

	.ant-modal-content {
		.ant-modal-header {
			border-bottom: none !important;
			padding: 18px 12px 12px 0;
			display: flex;
			flex-direction: row;
			width: 100%;
		}

		.ant-modal-header .ant-modal-title {
			display: flex;
			flex-direction: row;
			width: 100%;

			& span {
				font-size: 20px !important;
				font-weight: 500;
				font-family: Roboto, sans-serif;
				color: #1a1a1a;
			}
		}

		.ant-modal-body {
			padding-top: 0;
		}

		.ant-modal-footer {
			padding: 0 0 12px 0;
			margin: 0 0 0 0;
			display: block;

			.package-modal-footer {
				display: flex;
				justify-content: flex-end;
			}

			button {
				width: 16%;
				padding: 5px 16px;
				border: 1px solid;
				border-radius: 4px;
				background: #ffffff;
				font-family: 'Roboto', sans-serif;
				font-weight: 400;
				font-size: 14px;

				&.purchase {
					border: 1px solid #3340b6;
					color: #3340b6;

					&:hover {
						background-color: #3340b6;
						color: #ffffff;
						box-shadow: 0 0 0 3px #ced1f3;
					}

					&:disabled {
						background: #f5f5f5;
						border: 1px solid #c7c7c7;
						box-sizing: border-box;
						border-radius: 4px;
						color: #bdbdbd;

						&:hover {
							box-shadow: none !important;
						}
					}
				}

				&.cancel {
					border: 1px solid #c7c7c7;
					color: #1a1a1a;

					&:hover {
						background-color: #707070;
						color: $txt-color-white;
						border: 1px solid #707070;
					}
				}
			}
		}
	}
}

.vendor-package-list {
	max-height: 500px;
	overflow: auto;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(196, 196, 196, 0.6);
		border-radius: 20px;
	}

	.vendor-package-item {
		border: none;

		.ant-row {
			display: flex;
		}

		.ant-col {
			align-items: center;
			padding: 16px 0 24px 10px;
			display: grid;
		}

		.vendor-package-item-content {
			height: 100%;
			background: #f4f6f8;
			border: 1px solid transparent !important;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			padding: 0 0 0 5px;

			&:hover {
				background-color: #eff0fd;
				cursor: pointer;
			}

			&-selected {
				border: 1px solid #3340b6 !important;
				box-shadow: 0 0 0 4px #ecedf8;
				border-radius: 6px;
				background-color: #ffffff;
			}
		}

		.item-title {
			display: inline-flex;
			margin: 0;
			padding: 0;
			align-items: center;
		}

		.item-description {
			display: block;
			height: 100%;
			text-align: justify;

			& div {
				&::-webkit-scrollbar {
					width: 2px;
					display: flex;
					//margin-bottom: 10px;
				}

				&::-webkit-scrollbar-track {
					box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
				}

				&::-webkit-scrollbar-thumb {
					//background: rgba(196, 196, 196, 0.6);
					background: #c7c7c7;
					border-radius: 20px;
				}
			}
		}

		.item-price {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	.package-activated {
		margin: 0 0 0 14px;
		padding: 5px 12px;
		background: #ced1f3;
		border-radius: 4px;
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		color: #3340b6;
	}

	.policy-content {
		display: flex;
		flex-direction: row;
	}
}

.payment-container {
	padding: 12px;

	.package-order-list {
		margin: 16px 0 0 0;

		thead[class*='ant-table-thead'] th {
			padding: 0.32em 1em !important;
			border: none;
			background-color: #f4f6f8;
			font-family: Roboto, sans-serif;
			font-weight: 500;
			font-size: 14px;

			&:before {
				opacity: 0;
			}
		}

		.package-order-status-tag {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding: 0 12px;
			border-radius: 23px;
			font-family: 'Roboto', sans-serif;
			font-weight: 400;
			font-size: 12px;
			color: #ffffff;
			text-transform: capitalize;
			justify-content: center;
		}

		.request-bill-btn {
			color: #e63d4f;
			padding-top: 4px;
			padding-bottom: 0;
			height: 20px;

			> span {
				text-decoration: underline;
			}
		}

		.promoted-check {
			&.check-PROMOTED {
				color: #09b8af;
			}

			&.check-NOTIFIED {
				color: #ffa634;
			}

			&.check-UNPROMOTED {
				color: #c7c7c7;
			}
		}
	}

	.payment-status {
		min-width: 105px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&.WAITING {
			align-items: flex-end;
		}

		button {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.orderInfo-box {
	background-color: #fff6e5;
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	align-items: flex-start;
	padding: 12px 12px 12px 12px;

	&-topContent {
		display: flex;
		flex-direction: column;
		row-gap: 5px;
		//padding: 12px 12px 0 12px;
	}

	&-bottomContent {
		display: flex;
		flex-direction: column;
		row-gap: 5px;
		//padding: 6px 12px 12px 12px;
	}

	span.linear {
		display: block;
		width: 100%;
		border-top: 1px dashed #1a1a1a;
		margin: 12px 0 12px 0;
	}
}

.transfer-guide-box {
	font-family: Roboto, sans-serif;
	color: #1a1a1a;
}

.upload-bill-image {
	.ant-upload-select-picture-card {
		width: 80%;
		height: 150px;
		//padding: 10px;
	}

	.ant-upload-list-picture-card-container {
		width: 70%;
		height: 150px;
		//padding: 10px;
	}

	.ant-upload-list-picture-card {
		//width: 100%;
		display: flex;
		//:hover {
		//  display:none;
		//}
	}
}
.bill-preview-img-popup {
	width: 1000px !important;
	resize: none;
	top: 100px;
	.ant-modal-header .ant-modal-title {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		.txt-size-h5 {
			font-size: 16px;
		}
		.txt-size-h7 {
			font-size: 14px;
			font-weight: 400;
		}
	}
	.ant-modal-body {
		&::-webkit-scrollbar {
			width: 4px;
			display: flex;
			//margin-bottom: 10px;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
		}

		&::-webkit-scrollbar-thumb {
			//background: rgba(196, 196, 196, 0.6);
			background: #c7c7c7;
			border-radius: 20px;
		}
	}
}

.request-bill-modal {
	.ant-radio-group {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	&__header {
		border-bottom: 1px solid #ebebeb;
		line-height: 0.1em;
		margin: 10px 0 20px;
		text-transform: capitalize;
		width: calc(100% - 22px);

		> span {
			background: #fff;
			padding-right: 12px;
			font-weight: 500;
			font-size: 16px;
		}
	}

	.ant-form {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		label,
		input {
			font-size: 14px;
		}

		.ant-form-item-required {
			&::after {
				display: inline-block;
				margin-left: 8px;
				color: #ff4d4f;
				font-size: 12px;
				font-family: SimSun, sans-serif;
				line-height: 1;
				content: '*';
			}
		}

		.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
			display: inline-block;
			color: #1a1a1a;
			font-size: 12px;
			margin-right: 0;
			font-family: SimSun, sans-serif;
			line-height: 1;
			content: ':';
			position: absolute;
			right: 17px;
		}
	}
}

.disabled-input {
	&.float-label .label-float, .float-label-input_disabled .label-float, .float-label .label-range-float, .label-range-float {
		top: -1px !important;
		transform: translate(0, 0);
		background-color: #f6f6f6;
		> span {
			display: block;
			margin-top: -11px;
			background: transparent;
		}
	}
	&.dark {
		&.float-label.dark .label-float, .float-label.dark .label-range-float {
			margin-top: 1px;
			background-color: #313439;
			color: #bdbdbd;
		}
	}
}

.m22-table-address {
	&.m22-table .ant-table table {
		border-spacing: 0 10px;
	}

}
