//Color
@use 'sass:math';
.txt-color-blue {
  color: $txt-color-blue;
}

.txt-color-blue1 {
  color: $txt-color-blue1;
}
.txt-color-blue2 {
  color: $txt-color-blue2;
}
.txt-color-blue3 {
  color: $txt-color-blue3;
}

.txt-color-black2 {
  color: $txt-color-black2;
}
.txt-color-black3 {
  color: $txt-color-black3 !important;
}
.txt-color-secondary {
  color: $txt-color-secondary;
  &.dark {
    color: color(color-gray-quaternary-plus);
  }
}
.txt-color-primary {
  color: $txt-color-primary;
}
.txt-color-white {
  color: $txt-color-white !important;
}
.txt-color-gray {
  color: $txt-color-gray;
}
.txt-color-gray2 {
  color: $txt-color-gray2;
}
.txt-color-gray3 {
  color: $txt-color-gray3;
}
.txt-color-gray4 {
  color: $txt-color-gray4 !important;
  &.dark {
    color: color(color-gray-quaternary-plus) !important;
  }
}
.txt-color-gray7 {
  color: $txt-color-gray7 !important;
}
.txt-color-gray9 {
  color: $txt-color-gray9;
}
.txt-color-red {
  color: $txt-color-red;
}
.txt-color-red2 {
  color: $txt-color-red2;
}
.txt-color-red3 {
  color: $txt-color-red3;
}
.txt-color-red4 {
  color: $txt-color-red4;
}
.txt-color-green {
  color: $txt-color-green;
}
.txt-color-orange {
  color: $txt-color-orange !important;
}
.txt-color-orange1 {
  color: $txt-color-orange1 !important;
}
.txt-color-orange2 {
  color: $txt-color-orange2 !important;
}
.txt-color-orange3 {
  color: $txt-color-orange3;
}
.txt-color-orange4 {
  color: $txt-color-orange4;
}
.txt-color-orange5 {
  color: $txt-color-orange5;
}
.txt-color-orange6 {
  color: $txt-color-orange6;
}

.bg-color-orange5 {
  background-color: $txt-color-orange5;
}
.txt-color-only-staff {
  color: $txt-color-only-staff;
}
.txt-color-1 {
  color: #80c8ff !important;
}
.txt-color-blue5 {
  color: $txt-color-blue5;
}
.txt-color-blue6 {
  color: $txt-color-blue6 !important;
}
.txt-color-blue7 {
  color: $txt-color-blue7;
}
.txt-color-blue8 {
  color: $txt-color-blue7;
}
.txt-color-pink4 {
  color: $txt-color-pink4;
}
.txt-color-green1 {
  color: $txt-color-green1;
}
.txt-color-success {
  color: $txt-color-success;
}
.text-color-balance {
  color: var(--text-color-balance);
}

.txt-color-blue7 {
  color: var(--txt-color-blue7);
}
.txt-color-cart-success {
  color: var(--text-input-suffix);
}
//Font
// .service-pending {
// 	font-family: $robotofont;
// 	font-weight: 500;
// }
.robotomedium {
  font-family: $robotofont;
  font-weight: 500 !important;
}
.robotoregular {
  font-weight: 400;
}
.robotobold {
  font-family: $robotofont;
  font-weight: 700;
}

.font-medium {
  font-weight: 500 !important;
  input {
    font-weight: 500;
  }
}

//Background color
.bg-color-primary {
  background-color: $bg-color-primary;
}
.bg-color-gray {
  background-color: $bg-color-gray !important;
}
.bg-color-gray2 {
  background-color: $bg-color-gray2;
}
.bg-color-gray3 {
  background-color: $bg-color-gray3;
}
.bg-color-gray4 {
  background-color: $bg-color-gray4;
}
.bg-color-gray7 {
  background-color: $bg-color-gray7;
}
.bg-color-gray-badge {
  background-color: $bg-color-gray-badge;
}
.bg-color-gray5 {
  background-color: $bg-color-gray5;
}
// .bg-color-gray7{background-color: $bg-color-gray7}
.bg-color-white {
  background-color: $bg-color-white !important;
}
.bg-color-green {
  background-color: $bg-color-green;
}
.bg-color-blue {
  background-color: $bg-color-blue;
}
.bg-color-blue2 {
  background-color: $bg-color-blue2;
}
.bg-color-blue3 {
  background-color: $bg-color-blue3;
}
.bg-color-orange {
  background-color: $bg-color-orange;
}
.bg-color-orange2 {
  background-color: $bg-color-orange2;
}
.bg-color-orange3 {
  background-color: $bg-color-orange3;
}
.bg-color-orange7 {
  background-color: $txt-color-orange7;
}
.bg-color-mix-blue {
  background-color: #a6d9f9;
}
.bg-color-blue4 {
  background-color: $bg-color-blue4;
}
.bg-color-blue5 {
  background-color: $bg-color-blue5;
}
.bg-color-blue6 {
  background-color: $bg-color-blue6;
}
.bg-color-blue7 {
  background-color: $bg-color-blue7;
}
.bg-color-pink {
  background-color: $bg-color-pink;
  &.dark {
    background-color: color(color-disabled-primary-dark);
  }
}
.bg-color-pink1 {
  background-color: $bg-color-pink1;
}
.bg-color-pink2 {
  background-color: $bg-color-pink2;
  &.dark {
    background-color: color(color-disabled-primary-dark);
  }
}
// .bg-color-pink3{background-color: $bg-color-pink3}
.bg-color-pink4 {
  background-color: $bg-color-pink4;
}
.bg-color-pink5 {
  background-color: $bg-color-pink5;
}
.bg-color-gray-tab {
  background-color: $bg-color-gray-tab;
}
.bg-color-orange1 {
  background-color: $bg-color-orange1;
}
.bg-color-gray6 {
  background-color: $bg-color-gray6;
}
.bg-color-pink6 {
  background-color: $bg-color-pink6 !important;
}
.bg-error-request-delivery {
  background-color: var(--bg-error-request-delivery);
}
.bg-success-request-delivery {
  background-color: var(--bg-success-request-delivery);
}
.bg-color-balance {
  background-color: var(--bg-color-balance);
}
//Font-size
.txt-size-32 {
  font-size: $txt-size-32;
}
.txt-size-h1 {
  font-size: $txt-size-h1;
}
.txt-size-h2 {
  font-size: $txt-size-h2;
}
.txt-size-h3 {
  font-size: $txt-size-h3;
}
.txt-size-h4 {
  font-size: $txt-size-h4;
}
.txt-size-h5 {
  font-size: $txt-size-h5;
}
.txt-size-h6 {
  font-size: $txt-size-h6;
}
.txt-size-h7 {
  font-size: $txt-size-h7;
}
.txt-size-h8 {
  font-size: $txt-size-h8;
}
.txt-size-h9 {
  font-size: $txt-size-h9;
}
.txt-size-min {
  font-size: 1px !important;
}

//Padding
.pd0 {
  padding: 0;
}
.pd30 {
  padding: 30px;
}
.pd20 {
  padding: 20px;
}
.pd24 {
  padding: 24px;
}
.pd15 {
  padding: 15px;
}
.pd16 {
  padding: 16px;
}
.pd10 {
  padding: 10px;
}
.pd8 {
  padding: 8px;
}
.pd5 {
  padding: 5px;
}
.pd4 {
  padding: 4px;
}
.pd12 {
  padding: 12px;
}

.pdl0 {
  padding-left: 0 !important;
}

.pdl5 {
  padding-left: 5px;
}
.pdl8 {
  padding-left: 8px !important;
}
.pdl6 {
  padding-left: 6px;
}
.pdl10 {
  padding-left: 10px;
}
.pdl12 {
  padding-left: 12px;
}
.pdl15 {
  padding-left: 15px;
}
.pdl20 {
  padding-left: 20px !important;
}
.pdl24 {
  padding-left: 24px;
}
.pdl25 {
  padding-left: 25px;
}
.pdl30 {
  padding-left: 30px;
}
.pdl35 {
  padding-left: 35px;
}
.pdl40 {
  padding-left: 40px;
}
.pdl45 {
  padding-left: 45px;
}
.pdl50 {
  padding-left: 50px;
}
.pdl55 {
  padding-left: 55px;
}
.pdl65 {
  padding-left: 65px;
}
.pdl60 {
  padding-left: 60px;
}
.pdl70 {
  padding-left: 70px;
}
.pdl80 {
  padding-left: 80px;
}
.pdl95 {
  padding-left: 95px;
}
.pdl100 {
  padding-left: 100px;
}

.pdt160 {
  padding-top: 160px;
}
.pdt140 {
  padding-top: 140px;
}
.pdt120 {
  padding-top: 120px;
}
.pdt80 {
  padding-top: 80px;
}
.pdt70 {
  padding-top: 70px;
}
.pdt60 {
  padding-top: 60px;
}
.pdt50 {
  padding-top: 50px;
}
.pdt40 {
  padding-top: 40px;
}
.pdt35 {
  padding-top: 35px;
}
.pdt30 {
  padding-top: 30px;
}
.pdt25 {
  padding-top: 25px;
}
.pdt20 {
  padding-top: 20px;
}
.pdt16 {
  padding-top: 16px;
}
.pdt15 {
  padding-top: 15px;
}
.pdt10 {
  padding-top: 10px;
}
.pdt8 {
  padding-top: 8px;
}
.pdt7 {
  padding-top: 7px;
}
.pdt5 {
  padding-top: 5px;
}
.pdt3 {
  padding-top: 3px;
}
.pdt4 {
  padding-top: 4px;
}
.pdt0 {
  padding-top: 0;
}

.pdr0 {
  padding-right: 0 !important;
}
.pdr3 {
  padding-right: 3px;
}
.pdr4 {
  padding-right: 4px !important;
}
.pdr5 {
  padding-right: 5px;
}
.pdr8 {
  padding-right: 8px !important;
}
.pdr10 {
  padding-right: 10px;
}
.pdr12 {
  padding-right: 12px;
}
.pdr15 {
  padding-right: 15px;
}
.pdr20 {
  padding-right: 20px;
}
.pdr24 {
  padding-right: 24px;
}
.pdr25 {
  padding-right: 25px;
}
.pdr30 {
  padding-right: 30px;
}
.pdr40 {
  padding-right: 40px;
}
.pdr45 {
  padding-right: 45px;
}
.pdr50 {
  padding-right: 50px;
}
.pdr60 {
  padding-right: 60px;
}
.pdr80 {
  padding-right: 80px;
}
.pdr100 {
  padding-right: 100px;
}

.pdbt200 {
  padding-bottom: 100px;
}
.pdbt105 {
  padding-bottom: 105px;
}
.pdbt70 {
  padding-bottom: 70px;
}
.pdbt60 {
  padding-bottom: 60px;
}
.pdbt50 {
  padding-bottom: 50px;
}
.pdbt40 {
  padding-bottom: 40px;
}
.pdbt35 {
  padding-bottom: 35px;
}
.pdbt30 {
  padding-bottom: 30px;
}
.pdbt25 {
  padding-bottom: 25px;
}
.pdbt24 {
  padding-bottom: 24px;
}
.pdbt20 {
  padding-bottom: 20px;
}
.pdbt16 {
  padding-bottom: 16px;
}
.pdbt15 {
  padding-bottom: 15px;
}
.pdbt10 {
  padding-bottom: 10px;
}
.pdbt8 {
  padding-bottom: 8px;
}
.pdbt5 {
  padding-bottom: 5px;
}
.pdbt4 {
  padding-bottom: 4px;
}
.pdbt3 {
  padding-bottom: 3px;
}
.pdbt0 {
  padding-bottom: 0px;
}

//Margin

.mgau {
  margin: auto;
}
.mg15 {
  margin: 15px;
}
.mg20 {
  margin: 20px;
}
.mg25 {
  margin: 25px;
}
.mg0 {
  margin: 0;
}

.mgbt90 {
  margin-bottom: 90px;
}
.mgbt80 {
  margin-bottom: 80px;
}
.mgbt70 {
  margin-bottom: 70px;
}
.mgbt65 {
  margin-bottom: 65px;
}
.mgbt60 {
  margin-bottom: 60px;
}
.mgbt50 {
  margin-bottom: 50px;
}
.mgbt55 {
  margin-bottom: 55px;
}
.mgbt45 {
  margin-bottom: 45px;
}
.mgbt40 {
  margin-bottom: 40px;
}
.mgbt35 {
  margin-bottom: 35px;
}
.mgbt30 {
  margin-bottom: 30px;
}
.mgbt25 {
  margin-bottom: 25px;
}
.mgbt24 {
  margin-bottom: 24px;
}
.mgbt20 {
  margin-bottom: 20px;
}
.mgbt18 {
  margin-bottom: 18px;
}
.mgbt16 {
  margin-bottom: 16px;
}
.mgbt15 {
  margin-bottom: 15px;
}
.mgbt12 {
  margin-bottom: 12px;
}
.mgbt10 {
  margin-bottom: 10px !important;
}
.mgbt8 {
  margin-bottom: 8px;
}
.mgbt7 {
  margin-bottom: 7px;
}
.mgbt5 {
  margin-bottom: 5px;
}
.mgbt3 {
  margin-bottom: 3px;
}
.mgbt0 {
  margin-bottom: 0;
}
.mgbt-ipt-0 {
  margin-bottom: 0 !important;
}

.mgl80 {
  margin-left: 80px;
}
.mgl70 {
  margin-left: 70px;
}
.mgl60 {
  margin-left: 60px;
}
.mgl40 {
  margin-left: 40px;
}
.mgl30 {
  margin-left: 30px;
}
.mgl25 {
  margin-left: 25px;
}
.mgl20 {
  margin-left: 20px;
}
.mgl15 {
  margin-left: 15px;
}
.mgl10 {
  margin-left: 10px;
}
.mgl9 {
  margin-left: 9px;
}
.mgl50 {
  margin-left: 50px;
}
.mgl8 {
  margin-left: 8px;
}
.mgl5 {
  margin-left: 5px;
}
.mgl2 {
  margin-left: 2px;
}
.mgl3 {
  margin-left: 3px;
}
.mgl4 {
  margin-left: 4px;
}
.mgl0 {
  margin-left: 0;
}
.mgl8 {
  margin-left: 8px;
}
.mgl12 {
  margin-left: 12px;
}
.mg-minus-2 {
  margin-top: -2px;
}

.mgt5-minus {
  margin-top: -5px;
}
.mgt0 {
  margin-top: 0;
}
.mgt2 {
  margin-top: 2px;
}
.mgt4 {
  margin-top: 4px;
}
.mgt5 {
  margin-top: 5px;
}
.mgt6 {
  margin-top: 6px;
}
.mgt7 {
  margin-top: 7px;
}
.mgt8 {
  margin-top: 8px;
}
.mgt9 {
  margin-top: 9px;
}
.mgt10 {
  margin-top: 10px;
}
.mgt12 {
  margin-top: 12px;
}
.mgt15 {
  margin-top: 15px;
}
.mgt16 {
  margin-top: 16px;
}
.mgt18 {
  margin-top: 18px;
}
.mgt20 {
  margin-top: 20px;
}
.mgt25 {
  margin-top: 25px;
}
.mgt26 {
  margin-top: 26px;
}
.mgt30 {
  margin-top: 30px;
}
.mgt35 {
  margin-top: 35px;
}
.mgt39 {
  margin-top: 39px;
}
.mgt40 {
  margin-top: 40px;
}
.mgt45 {
  margin-top: 45px;
}
.mgt50 {
  margin-top: 50px;
}
.mgt53 {
  margin-top: 53px;
}
.mgt60 {
  margin-top: 60px;
}
.mgt70 {
  margin-top: 70px;
}

.mgr2 {
  margin-right: 2px;
}
.mgr3 {
  margin-right: 3px;
}
.mgr4 {
  margin-right: 4px;
}
.mgr5 {
  margin-right: 5px;
}
.mgr6 {
  margin-right: 6px;
}
.mgr8 {
  margin-right: 8px;
}
.mgr10 {
  margin-right: 10px;
}
.mgr12 {
  margin-right: 12px;
}
.mgr15 {
  margin-right: 15px;
}
.mgr20 {
  margin-right: 20px;
}
.mgr25 {
  margin-right: 25px;
}
.mgr30 {
  margin-right: 30px;
}
.mgr35 {
  margin-right: 35px;
}
.mgr40 {
  margin-right: 40px;
}
.mgr45 {
  margin-right: 45px;
}
.mgr50 {
  margin-right: 50px;
}
.mgr55 {
  margin-right: 55px;
}
.mgr60 {
  margin-right: 60px;
}
.mgr85 {
  margin-right: 85px;
}
.mgr-10 {
  margin-right: -10px;
}

//Line height
.line-height134 {
  line-height: 1.34;
}
.line-height167 {
  line-height: 1.67;
}
.line-height20 {
  line-height: 20px;
}

//Border-color
.border-bottom-0x {
  border-bottom: 0 !important;
}
.border-bottom-1x {
  border-bottom: 1px solid;
}
.border-bottom-1x-grey {
  border-bottom: 1px solid #e8e8e8;
}
.border-bottom-2x {
  border-bottom: 2px solid;
}
.border-top-1x {
  border-top: 1px solid;
}
.border-top-1x-grey {
  border-bottom: 1px solid #e8e8e8;
}
.border-top-2x {
  border-top: 2px solid;
}
.border-right-1x {
  border-right: 1px solid;
}
.border-left-1x {
  border-left: 1px solid;
}
.bd0 {
  border: 0px !important;
}
.bd1px {
  border: 1px solid;
}
.borderdotted-bootom-2x {
  border-bottom: 2px dotted;
}
.bdbt0 {
  border-bottom: 0;
}
.bdt0 {
  border-top: 0;
}
.border-top-3x {
  border-top: 3px solid;
}

.bd-color-black {
  border-color: #2f3439;
}
.bd-color-gray {
  border-color: rgba(0, 0, 0, 0.14);
}
.bd-color-gray2 {
  border-color: rgba(0, 0, 0, 0.25);
}
.bd-color-gray3 {
  border-color: #e5e5e5;
}
.bd-color-gray5 {
  border-color: $txt-color-gray5;
}
.bd-color-gray6 {
  border-color: $txt-color-gray6;
}
.bd-color-blue {
  border-color: #1890ff;
}
.bd-color-blue6 {
  border-color: #3340b6;
}

.bd-color-green {
  border-color: #7cb305 !important;
}
.bd-color-red {
  border-color: red;
}
.bd-color-white {
  border-color: white;
}
.bd-color-mix-blue {
  border-color: #4673c1;
}
.bd-color-primary {
  border-color: #f59f32;
}
.bd-color-primary-1 {
  border-color: #ffa600;
  &:hover {
    border-color: #ffa600;
  }
}
.bd-color-transparent {
  border-color: transparent;
}
.bd-none {
  border: none;
}
.bd-none-impt {
  border: none !important;
}
.border-divider {
  &-horizontal {
    border-top: 1px solid $txt-color-gray5;
  }
  &-vertical {
    border-left: 1px solid $txt-color-gray5;
  }
}

//Position
.position-re {
  position: relative;
}
.position-ab {
  position: absolute;
}
.position-fix {
  position: fixed;
}

//z-index
.zindex0 {
  z-index: 0;
}
.zindex9 {
  z-index: 9;
}
.zindex99 {
  z-index: 99;
}
.zindex999 {
  z-index: 999;
}
.zindex9999 {
  z-index: 9999;
}

//Float
.fll {
  float: left;
}
.flr {
  float: right;
}
.clearboth {
  clear: both;
}

// Transformation
.txt-uppercase {
  text-transform: uppercase;
}
.txt-lowercase {
  text-transform: lowercase;
}
.txt-capitalize {
  text-transform: capitalize !important;
}
.txt-transform-none {
  text-transform: none !important;
}

//Decoration
.txt-underline {
  text-decoration: underline;
}
.txt-linethrough {
  text-decoration: line-through;
}
.txt-overline {
  text-decoration: overline;
}

// Alignment
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right !important;
}
.txt-center {
  text-align: center;
}
.txt-justify {
  text-align: justify;
}
.txt-nowrap {
  white-space: nowrap;
}
.txt-initial {
  white-space: initial;
}

//Display
.dpl-none {
  display: none;
}
.dpl-block {
  display: block !important;
}
.dpl-il-block {
  display: inline-block;
}
.dpl-il {
  display: inline;
}
.dpl-flex {
  display: flex;
}
.dpl-none-mobile {
  display: block;
}
.dpl-none-pc {
  display: none;
}

.dpl-grid {
  display: grid;
}

.dpl-content {
  display: contents !important;
}
//Flex
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end !important;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-no-wrap {
  flex-wrap: nowrap;
}
.flex-grow-0 {
  flex-grow: 0;
}
.flex-grow-2 {
  flex-grow: 2;
}
.flex-basis-571 {
  flex-basis: 571px;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center;
  &-impt {
    align-items: center !important;
  }
}
.align-items-baseline {
  align-items: baseline;
}
.align-items-stretch {
  align-items: stretch;
}

.align-items-end {
  align-items: flex-end;
}
.flex-col {
  flex-direction: column;
}
.flex-basis-180 {
  flex-basis: 180px;
}
.flex-basis-half {
  flex-basis: calc((100% - 12px) / 2);
}
.flex-basis-80pc {
  flex-basis: calc((100% - 12px) * 8 / 10);
}
.flex-basis-20pc {
  flex-basis: calc((100% - 12px) * 2 / 10);
}
.flex-basis-100pc {
  flex-basis: 100%;
}
.flex-basis-btn-filter {
  flex-basis: 116px;
}
.flex-shrink-0 {
  flex-shrink: 0;
}

//Overflow
.overflow-hidden {
  overflow: hidden;
}
.overflowy-hidden {
  overflow-y: hidden;
}
.overflow-auto {
  overflow: auto;
}
.overflowXscroll {
  overflow-x: scroll;
}
.overflowYscroll {
  overflow-y: scroll;
}

.wordbreakall {
  word-break: break-all;
}
.wordbreakword {
  word-break: break-word;
}

.text-underline {
  text-decoration: underline;
}
.cursor-nodrop {
  cursor: no-drop;
}
.whitespace {
  white-space: nowrap;
}
.whitespace-initial {
  white-space: initial !important;
}
.whitespace-inherit {
  white-space: inherit !important;
}

.opacity6 {
  opacity: 0.6;
}

.bottom0 {
  bottom: 0;
}

//Border-radius
.border-radius2 {
  @include border-radius(2px);
}
.border-radius3 {
  @include border-radius(3px);
}
.border-radius4 {
  @include border-radius(4px);
}
.border-radius6 {
  @include border-radius(6px);
}
.border-radius20 {
  @include border-radius(20px);
}
.border-radius25 {
  @include border-radius(25px);
}
.border-radius-none {
  border-radius: 0;
}

.bdr0 {
  border-right: 0;
}
.width15 {
  width: 15%;
}
.width20 {
  width: 20%;
}
.width30 {
  width: 30%;
}
.width45 {
  width: 45%;
}
.width50 {
  width: 50%;
}
.width70 {
  width: 70%;
}
.width80 {
  width: 80%;
}
.width100px {
  width: 100px;
}
.width100pc {
  width: 100% !important;
}
.width182px {
  width: 182px;
}
.width80px {
  width: 80px;
}
.width120px {
  width: 120px;
}
.width50px {
  width: 50px;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.bdr0 {
  border-right: 0;
}

.width30 {
  width: 30%;
}
.width70 {
  width: 70%;
}
.width50 {
  width: 50%;
}
.width80 {
  width: 80%;
}
.width100px {
  width: 100px;
}
.width100pc {
  width: 100% !important;
}
.width182px {
  width: 182px;
}
.width80px {
  width: 80px;
}
.width120px {
  width: 120px;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.imgthumb32 {
  width: 32px;
  height: 32px;
}

.imgthumb14 {
  width: 14px;
  height: 14px;
}
.imgthumb120 {
  width: 120px;
  height: 120px;
}
// .ant-avatar {
// 	border: 1px solid rgba(0, 0, 0, 0.25);
// }
.width100 {
  width: 100%;
}
.width50 {
  width: 50%;
}
.width25 {
  width: 25%;
}

.min-width-135 {
  min-width: 135px;
}
.min-width-150 {
  min-width: 150px;
}
.min-width-200 {
  min-width: 200px;
}
.height100 {
  height: 100%;
}
.width250px {
  width: 250px;
}

.height32px {
  height: 32px;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-initial {
  cursor: initial;
}
.font-italic {
  font-style: italic;
}

.tooltip-custom-yellow {
  & .ant-tooltip-inner {
    color: #1a1a1a;
    font-size: 12px;
    line-height: 22px;
    font-family: 'Roboto', sans-serif;
    background-color: #fbb739;
    min-height: 22px;
    padding: 0 6px;
  }
}

.container {
  width: calc(100% - 60px);
  float: right;
}

@media screen and (max-width: 767px) {
  .hidemobile {
    display: none;
  }
  .dpl-blockmb {
    display: block;
  }
  .mgbt24mb {
    margin-bottom: 24px;
  }
  .mgbt16mb {
    margin-bottom: 16px;
  }
  .mgbt8mb {
    margin-bottom: 8px;
  }
  .mgbt0mb {
    margin-bottom: 0px;
  }
  .txt-centermb {
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .mgbt16ip {
    margin-bottom: 16px;
  }
  .mgbt8ip {
    margin-bottom: 8px;
  }
  .mgt0ip {
    margin-top: 0px;
  }
}

$class: 'gap-';
@for $i from 0 through 100 {
  .#{$class}#{$i} {
    gap: #{$i}px;
  }
}

$class: 'fsz-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    font-size: #{$i}px !important;
  }
}

$class: 'mg-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    margin: #{$i}px !important;
  }
}
$class: 'mx-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    margin: 0 #{$i}px !important;
  }
}
$class: 'my-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    margin: #{$i}px 0 !important;
  }
}

$class: 'mg-t-';
@for $i from 0 through 160 {
  .#{$class}#{$i} {
    margin-top: #{$i}px !important;
  }
}
$class: 'mg-r-';
@for $i from 0 through 100 {
  .#{$class}#{$i} {
    margin-right: #{$i}px !important;
  }
}
$class: 'mg-l-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    margin-left: #{$i}px !important;
  }
}

$class: 'mg-bt-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    margin-bottom: #{$i}px !important;
  }
}

$class: 'pd-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    padding: #{$i}px !important;
  }
}
$class: 'pd-imp-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    padding: #{$i}px !important;
  }
}
$class: 'pd-l-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    padding-left: #{$i}px !important;
  }
}
$class: 'pd-r-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    padding-right: #{$i}px !important;
  }
}

$class: 'pd-t-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    padding-top: #{$i}px !important;
  }
}

$class: 'pd-bt-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    padding-bottom: #{$i}px !important;
  }
}
$class: 'px-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }
}
$class: 'py-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }
}

$class: 'line-h-';
@for $i from 0 through 60 {
  .#{$class}#{$i} {
    line-height: #{$i}px !important;
  }
}
$class: 'w-';
@for $i from 0 through 160 {
  .#{$class}#{$i} {
    width: #{$i}px;
  }
}
$class: 'h-';
@for $i from 0 through 100 {
  .#{$class}#{$i} {
    height: #{$i}px;
  }
}

.primary-txt {
  color: $bg-color-primary;
}

.white-box {
  background-color: white;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 12px;
}

.txt-color-order-detail {
  color: $txt-color-order-detail;
}

.box-order {
  background: #ffffff;
  box-shadow: 0px 2px 14px #e6e6e6;
  border-radius: 4px;
  .ant-tabs-nav {
    margin-bottom: 12px;
    &::before {
      border-bottom: 1px solid #e5e5e5 !important;
    }
  }
  .ant-tabs-tab {
    padding: 0;
    margin: 0;
  }
  .ant-table-cell {
    &::before {
      height: 16px !important;
    }
  }
}
.h-fit-content {
  height: fit-content;
}
.w-fit-content {
  height: fit-content;
}

$class: 'truncate-multiline-';
@for $i from 0 through 5 {
  .#{$class}#{$i} {
    -webkit-line-clamp: $i;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    max-width: calc(100% - 30px);
  }
}
$class: 'rad-';
@for $i from 0 through 70 {
  .#{$class}#{$i} {
    border-radius: #{$i}px !important;
  }
}

$class: 'flex-basis-gap-';
@for $i from 0 through 200 {
  .#{$class}#{$i} {
    flex-basis: calc((100% - #{$i}px) / 2);
  }
}
.flex-basis-145 {
  flex-basis: 145px;
}

.word-break-all {
  word-break: break-all;
}

.primary-hover {
  &:hover {
    border: 1px solid $txt-color-primary;
  }
}

.bd-bt-none {
  & > td {
    border-bottom: none !important;
  }
}
.confirm-pop-product {
  padding: 4px 12px !important;
  border-radius: 2px;
  border: none !important;
}
.td-table-cell {
  border-bottom: 1px solid $txt-color-gray5;
  padding-top: 8px !important;
  padding-bottom: 0 !important;
}
.confirm-order-item {
  position: fixed;
  top: 64px;
  right: 20px;
  padding: 0;
  .ant-message-notice-content {
    background-color: $bg-color-blue6;
    padding: 4px 12px;
    font-size: 14px;
    line-height: 22px;
    font-family: $robotofont;
    color: $txt-color-blue5;
    border: 1px solid #8dd8d4;
    box-shadow: unset !important;
    border-radius: 4px;
  }
}
.none-visible {
  visibility: hidden;
}

.service {
  font-weight: 400;

  &-normal {
    color: var(--text-service-color) !important;
  }

  &-pending {
    color: #ffa634;
  }

  &-approve {
    color: #489e26;
  }

  &-refuse {
    color: #d0302c;
  }

  &-reject {
    color: #09b8af;
    text-decoration: none;
  }
}

.mi-h-100vh {
  min-height: 100vh;
}

.overflow-y-auto {
  overflow-y: auto;
}

.message-error-delivery {
  position: fixed;
  top: 64px;
  right: 20px;
  padding: 0;
  .ant-message-notice-content {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 22px;
    font-family: $robotofont;
    color: $txt-color-blue5;
    box-shadow: unset !important;
    border-radius: 4px;
  }
}

.align-self-end {
  align-self: flex-end;
}

.calculate-box {
  position: fixed;
  bottom: 0;
  left: 85px;
  width: calc(100% - 85px);
  padding: 24px;
  padding-bottom: 40px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5);
  &.dark {
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.35) !important;
  }
}

.min-h-100vh {
  min-height: 100vh;
  padding-bottom: 241px;
}

.success-delivery-box {
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #ffa600;
  border-radius: 6px;
  padding: 16px;
  background-color: #fff6e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: fit-content;
}
.h-50pc {
  height: 50%;
}
.h-96pc {
  height: 96%;
}
.min-h-152 {
  min-height: 152px;
}
.order-image {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  object-fit: cover;
  object-position: center center;
}
.image-attachment {
  border: 1px solid #d9d9d9;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  object-fit: cover;
  object-position: center center;
}
.white-box {
  background-color: white;
  padding: 15px;
  border-radius: 4px;
}
.divider {
  border-bottom: 1px solid #f6f6f6;
}
.bold {
  font-weight: bold;
}
.supplier-icon {
  width: 12px;
  height: 12px;
  object-fit: cover;
  object-position: center center;
  border-radius: 100%;
}
.box-shadow {
  box-shadow: 0px 2px 14px #e6e6e6;
}
.align-self-flex-end {
  align-self: flex-end;
}
.status-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.order-image {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  object-fit: cover;
  object-position: center center;
}
.image-attachment {
  border: 1px solid #d9d9d9;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  object-fit: cover;
  object-position: center center;
}
.white-box {
  background-color: white;
  padding: 16px;
  border-radius: 4px;
}
.divider {
  border-bottom: 1px solid #f6f6f6;
}
.bold {
  font-weight: bold;
}
.supplier-icon {
  width: 12px;
  height: 12px;
  object-fit: cover;
  object-position: center center;
  border-radius: 100%;
}
.box-shadow {
  box-shadow: 0px 2px 14px #e6e6e6;
}
.align-self-flex-end {
  align-self: flex-end;
}

.status-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.align-self-center {
  align-self: center;
}
.align-self-start {
  align-self: flex-start;
}
.d-none {
  display: none !important;
}
.empty-delivery {
  height: calc(100vh - 50%);
  display: flex;
  justify-content: center;
}
.white-space-pre-ln {
  white-space: pre-line;
}
.box-shadow-none {
  box-shadow: none !important;
}
.text-center {
  text-align: center;
}

.bd-cancel-popup {
  border: 1px solid #c7c7c7 !important;
}

.bd-left {
  border-left: 2px solid $txt-color-primary;
}

.flex-nowrap {
  flex-wrap: nowrap;
}
.flex {
  display: flex !important;
}

.notice-pending-order {
  padding: 5px 12px;
  border: 1px solid $bg-color-orange2;
  border-radius: 2px;
}
.flex-basis-60pc {
  flex-basis: 60%;
}
.flex-basis-40pc {
  flex-basis: 40%;
}
.flex-basis-fit-content {
  flex-basis: fit-content;
}

.flex-basis-auto {
  flex-basis: auto;
}

.flex-grow-1 {
  flex-grow: 1;
}
.w-fit-concent {
  width: fit-content;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.mg-top-35pc {
  margin-top: 35%;
}

.min-w-470 {
  min-width: 470px;
}

.close-x-payment {
  position: absolute;
  top: 0;
  left: 0;
}

.w-100-percent {
  width: 100%;
}

.m24-input {
  height: 32px;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  font-size: 14px;

  &:disabled {
    color: #1a1a1a;
    background-color: #f4f6f8;
  }
}

.m24-select {
  font-size: 14px;

  &.ant-select {
    div.ant-select-selector {
      border-radius: 4px;
    }
  }
}

input {
  &::-webkit-input-placeholder {
    /* Edge */
    font-weight: 400;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: 400;
  }

  &::placeholder {
    font-weight: 400;
  }
}

.min-h-100vh-250 {
  min-height: calc(100vh - 262px);
}
.min-h-100vh-248 {
  min-height: calc(100vh - 248px);
}
.min-h-100vh-303 {
  min-height: calc(100vh - 303px);
}

.item-link {
  color: #008df9 !important;
  &:hover {
    color: #008df9 !important;
  }
}

.h-auto {
  height: auto;
}

.bg-color-black {
  background-color: #000000;
}

.txt-capitalize {
  text-transform: capitalize;
}

.dark {
  .txt-color-gray8 {
    color: #bdbdbd;
  }

  button.ant-btn[disabled], button.ant-btn[disabled]:hover, button.ant-btn[disabled]:focus, button.ant-btn[disabled]:active {
    background-color: #32373E;
    color: #666;
    border-color: #575757;
  }
}

.txt-color-gray8 {
  color: $txt-color-gray8;
  &.dark {
    color: #bdbdbd;
  }
}

.white-space-pre-wrap-impt {
  white-space: pre-wrap !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.min-width-0 {
  min-width: 0;
}

.flex-1 {
  flex: 1;
}

.service-divider {
  margin: 0 4px;
  border-left: 1 solid #c7c7c7;
}

.orderDetail {
  &-collapse,
  &-expand {
    &.dark {
      color: #fcd535;
      font-size: 12px;
    }
    position: absolute;
    right: 50%;
    top: 95%;
    cursor: pointer;
    color: #7d7e7e;
    font-size: 12px;
  }
  &-collapse {
    top: 18px;
    right: 2%;
  }
  &-expand {
    bottom: 8px;
  }
}

.text-right .ant-skeleton-content > ul {
  display: flex;
  justify-content: flex-end;
}

.gobiz-gray {
  filter: grayscale(1);
  border: none;
}
.required-custom {
  position: absolute;
  bottom: -18px;
  left: 0;
  font-size: 12px;
  font-family: 'Roboto' sans-serif;
  color: red;
}

.bd-color-primary {
  border: 1px solid $bd-divider-success-box;
}

.claim-provider {
  & .supplier-name,
  & .supplier-customer {
    font-size: 14px;
    line-height: 22px;
  }
}

.divider-customize {
  color: #c7c7c7;
  font-size: 16px;
}

.vertical-divider {
  border-left: 1px solid #ebebeb;
}
.txt-warning {
  color: $txt-warning;
}

.link-hover:hover {
  background-color: #ecedf8;
  border-radius: 4px;
}

.mg-l-auto {
  margin-left: auto;
}

.service-divider-black {
  border-left: 1px solid #1a1a1a !important;
  height: 12px !important;
  &.dark {
    border-left: 1px solid #bdbdbd !important;
  }
}

.w-400 {
  width: 400px;
}

.flex-basis-300 {
  flex-basis: 300px;
}

.w-300 {
  width: 300px;
}
.w-200 {
  width: 200px;
}

.w-250 {
  width: 250px;
}

.orders-list-head {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.row-light,
.row-dark {
  & .ant-table-tbody {
    & > tr > td {
      &:first-child {
        border-top-left-radius: 0px;
      }
      &:last-child {
        border-top-right-radius: 0px;
      }
    }
  }
}

.row-dark {
  > td {
    background-color: var(--bg-table-triped);
  }
}

.row-light-peerPayment {
  background-color: #fafafa;
}
.row-extended {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding-bottom: 6px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding-top: 6px;
  // pointer-events: none;
  &-hovered {
    &.dark > td {
      background-color: #32373e !important;
    }
  }
}

.fa-icon-hover {
  &:hover {
    color: #fbb739;
  }
}

.user-dropdown-hover {
  &:hover {
    background-color: $bg-color-pink2;
  }
  &.dark:hover {
    background-color: #3a4048;
  }
}

.user-actions {
  //top: 60px !important;
  .ant-popover-inner {
    border-radius: 6px;
  }

  &.dark {
    .ant-popover-inner {
      background: #1f2328;
    }
    .ant-popover-arrow {
      .ant-popover-arrow-content:before {
        background: #1f2328;
      }
    }
    .popover-txt {
      color: #bdbdbd;
    }
  }

  &.ant-popover-placement-bottom {
    padding-top: 12px;
    position: fixed;
    top: 60px;
  }

  .ant-popover-content {
    .ant-popover-arrow {
      left: 84%;
    }
    .ant-popover-inner-content {
      padding: 0;
    }
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  &_item-light {
    margin: 12px 12px 0 12px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 12px;
    cursor: pointer;
    color: #5a5a5a;

    &:hover {
      background: #f6f6f6;
      color: #1a1a1a;
      font-weight: 500;
    }
  }

  & .logout-action {
    span {
      color: #fd5b5b;
    }
  }
}

.user-actions_item-dark {
  @extend .user-actions_item-light;

  &:hover {
    background: #252a2f;
    font-weight: 500;
    color: #ffffff;
    .popover-txt {
      color: #e6e6e6;
    }
  }
}

.w-70 {
  width: 70px;
}

.mg-r-135 {
  margin-right: 135px;
}
.line-d-h-1 {
  border-bottom: 1px solid #ebebeb;
  flex-grow: 1;
}
.bg-color-orange5 {
  background-color: $bg-color-orange5;
}
.bg-colo-gray8 {
  background-color: $bg-color-gray8;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span {
  opacity: 1 !important;
  margin-left: 0;
  text-align: center;
  margin-top: 5px;
  font-size: 12px;
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
  color: $txt-color-gray7;
  font-weight: 500;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.ant-menu {
  &.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title:hover {
    .ant-menu-item-icon:not(.menu-active) {
      color: #1a1a1a !important;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.09);
      border-radius: 16px;
      background-color: #ffffff;
    }
    .ant-menu-item-icon + span {
      color: #5a5a5a !important;
    }
    .ant-menu-title-content > a {
      color: #5a5a5a !important;
    }
  }
  &-dark {
    &.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title:hover {
      .ant-menu-item-icon:not(.menu-active) {
        color: #e6e6e6 !important;
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.09);
        border-radius: 16px;
        background-color: #33373d;
      }
      .ant-menu-item-icon + span {
        color: #cfcfcf !important;
      }
      .ant-menu-title-content > a {
        color: #cfcfcf !important;
      }
    }
  }
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon {
  font-size: 18px;
  line-height: 36px;
  height: 36px;
  text-align: center;
  width: 56px;
}

.bg-color-orange2 {
  background-color: $bg-color-orange2;
}

.menu-hovered {
  &.menu-expand-active {
    background-color: #fcd535 !important;
    & * {
      color: color(color-black-quaternary) !important;
    }
  }
  &:not(.menu-expand-active):hover {
    background-color: #f7f7f7 !important;

    > span {
      color: #1a1a1a;
      > i {
        color: #1a1a1a;
      }
    }
    .icon-hover {
      color: #1a1a1a;
    }
  }

  &.dark {
    &.menu-expand-active {
      background-color: #33373d !important;
      & * {
        color: color(color-primary) !important;
      }
    }
    &:not(.menu-expand-active) {
      background-color: transparent !important;
      & * {
        // color: color(color-gray-quaternary-plus) !important;
      }
      &:hover {
        background-color: #33373d !important;
        > span {
          color: #e6e6e6 !important;
          > i {
            color: #e6e6e6 !important;
          }
        }
        .icon-hover {
          color: color(color-gray-quaternary-plus) !important;
        }
      }
    }
  }
}

.luna-theme {
  border-radius: 8px;
  border: 1px solid #c0c0c0;
  padding: 4px 6px;
  width: 36px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #fcd535;
    border: none;

    & > i {
      color: #363636 !important;
    }
  }
}

.theme-icon-display {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-basis: 14px;

  img {
    width: 24px;
    height: auto;
  }
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  display: flex;
  flex-direction: column;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span {
  opacity: 1;
}

.rad-menu {
  border-radius: 0px 8px 8px 0px;
}

.up-market-result {
  border-radius: 4px 4px 0 0;
  .ant-card-head {
    border-radius: 4px 4px 0px 0px;
    background-color: #f4f6f8;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    .ant-card-head-wrapper > .ant-card-head-title {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.up-shop-progress {
  .ant-progress-steps-outer {
    border: 1px solid #3340b6;
    border-radius: 4px;
    padding: 1px 0;
    padding-right: 4px;
    .ant-progress-text {
      margin-left: 4px;
      padding: 0;
      width: fit-content;
    }
  }
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-impt {
  display: flex !important;
}

.txt-color-gray10 {
  color: $txt-color-gray10;
}

.txt-color-gray11 {
  color: $txt-color-gray11;
}

.txt-color-gray12 {
  color: $txt-color-gray12;
}

.text-shadow-none {
  text-shadow: none;
}

.txt-color-success {
  color: $txt-color-success;
}
.txt-color-warning {
  color: $txt-color-warning;
}

.fw-300 {
  font-weight: 300;
}

.m22-border-divider {
  border-top: 1px solid #e0e0e0 !important;
}

.rad-6-top {
  border-radius: 6px 6px 0 0 !important;
}

.rad-bottom-6 {
  border-radius: 0 0 6px 6px !important;
}

#cs-live-chat {
  right: 12px !important;
  bottom: 0 !important;
}

.menu-active {
  i {
    color: color(color-black-tertiary);
  }
  &.dark {
    i {
      color: color(color-primary);
    }
    background-color: #33373d;

    &.menu-item-label__dark {
      color: #e6e6e6;
    }
  }
}

a.menu-item-label {
  &__dark {
    //color: #E6E6E6 !important;
  }
}

.pagination-container {
  position: relative;
  width: 100%;
  // margin-bottom: 40px;
}

.edit-address {
  box-shadow: none !important;
  border: transparent;
  &.ant-btn-default:not(:disabled) {
    border: none;
    color: #3340b6;
    &:hover {
      color: #1a1a1a;
      background-color: white !important;
    }
  }

  &.dark {
    &.ant-btn-default:not(:disabled) {
      &:hover {
        color: #fcd535 !important;
        background-color: transparent !important;
      }
    }
  }
}
.txt-color-dark-secondary {
  color: #bdbdbd;
}

.txt-color-dark1 {
  color: #666666;
}

.txt-color-E6E6E6 {
  color: #e6e6e6;
}

.row-extended-hovered.light {
  background-color: color(color-table-row-hover) !important;
  &.ant-table-row {
    &:hover > td {
      background-color: #f6f6f6;
    }
  }
}

.mg-bt-200 {
  margin-bottom: 200px !important;
}

.ant-menu.ant-menu-dark,
.ant-layout-sider-dark {
  //color: rgba(255, 255, 255, 0.65);
  background: #1f2328 !important;
}

.dark {
  .ant-layout-sider-dark {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);

    > .ant-layout-sider-trigger {
      background: #1f2328;
    }
  }

  .menu-drawer.ant-drawer .ant-drawer-content-wrapper .ant-drawer-content {
    background: #1f2328;
  }
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px !important;
}
.ant-picker-range {
  height: 42px !important;
}
