.c-badge {
    background: #fbb739;
    line-height: 20px;
    border-radius: 20px;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    align-self: center;
    .c-badge-num {
        color: #ffffff;
        font-size: 12px;
    }
}
.c-badge-no-data {
    background: #f5f5f5;
    border-radius: 20px;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    align-self: center;
    .c-badge-num-no-data {
        color: #707070;
        font-size: 12px;
    }
}

.ant-badge {
    &.ant-badge-not-a-wrapper {
        .ant-badge-count {
            padding: 0 6px;
            font-weight: 500;
        }
    }
}

.default-badge_dark {
    border-color: #fcd535!important;
    background-color: transparent!important;
    > span {
        font-size: 12px;
        color:#fcd535!important;
    }
}

.default-badge_light {
    @extend .default-badge_dark;
    background-color: #FFF9DF!important;
    > span {
        color: #1A1A1A!important;
    }
}
