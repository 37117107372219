.ant-modal {
  height: fit-content;

  .ant-modal-header {
    padding: 12px 0;
    margin: 0 16px;
    background-color: var(--bg-main-color);
    border: none;
  }
  .ant-modal-content {
    background-color: var(--bg-modal);

    .ant-modal-header {
      background-color: var(--bg-modal);
    }

    .ant-modal-close-x {
      color: var(--text-primary-color);
    }

    .ant-modal-body {
      padding: 16px 16px !important;
      font-size: $txt-size-h7;
      max-height: 80vh;
      overflow: auto;
    }

    .ant-modal-footer {
      padding: 0 16px 16px 16px;
      border: 0;

      .btn_cancel {
        margin-top: 5px;
        border-radius: 4px;
      }
    }
  }
}

.ant-modal-custom {
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(236, 236, 236, 0.85);
    border-radius: 10px;
  }

  .ant-modal-content {
    padding: 0 12px;

    .ant-modal-header {
      padding: 16px 0;

      .ant-modal-title {
        span {
          color: black !important;
          font-size: $txt-size-h7;
          font-weight: 500;
        }
      }
    }

    .ant-modal-body {
      //padding: 16px 0;
      padding-top: 0;
      font-size: $txt-size-h7;
    }

    .ant-modal-footer {
      padding: 0 0 24px 0;
      border: 0;
      text-align: left;

      .btn_cancel {
        border-radius: 4px;
        margin-top: 5px;
      }
    }
  }
}

.ant-modal .ant-modal-close {
  top: 0;
}

.ant-modal-large {
  $space: 24px;

  .ant-modal-close {
    top: 6px;
    right: 4px;
  }
  .ant-modal-header {
    padding-top: $space;
    padding-bottom: 16px;
    margin: 0 $space;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding: 1px $space !important;
      max-height: 80vh;
      overflow-y: overlay !important;
    }
    .ant-modal-footer {
      padding: 16px $space;
    }
  }
}
