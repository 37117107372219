.ant-table {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.product-list,
.package-list,
.delivery-request {
  .ant-table {
    .ant-table-thead > tr > th {
      font-family: Roboto;
      font-weight: 500;
      font-size: 14px;
      background-color: #f2f2f2;
      padding: 5px 12px;
      border-bottom: none;
      // color: #707070;
      white-space: nowrap;
      &::before {
        background-color: #c7c7c7 !important;
      }
    }
    tbody {
      td {
        vertical-align: top;
        border-bottom: 1px solid #ebebeb;
        padding: 8px 12px;
        & .ant-badge-status-text {
          font-size: 14px;
          color: #1a1a1a;
          font-family: 'Roboto', sans-serif;
        }
      }
      tr {
        &:last-child > td {
          border-bottom: none !important;
        }
      }
    }
  }
  &.extended {
    .ant-table {
      tbody {
        td {
          vertical-align: top;
          // border-bottom: 1px solid #ebebeb;
          padding: 12px;
        }
      }
    }
  }
}

.product-list {
  .ant-table tbody td {
    vertical-align: middle;
  }
  .ant-table tbody tr:last-child td {
    border-bottom: none;
  }
}

.package-list {
  &.table-expandable {
    &_has-milestone {
      & .ant-table tbody .ant-table-expanded-row td {
        padding-bottom: 8px !important;
        border-bottom: 1px solid #ebebeb;
      }
    }
    & .ant-table.ant-table-small tbody .ant-table-expanded-row td:first-of-type {
      padding-right: 0 !important;
      padding-top: 0 !important;
      padding-left: 0 !important;
    }
    & .ant-table.ant-table-small tbody .ant-table-expanded-row .package-list-waybill .package-data td:first-of-type {
      padding: 5px 12px !important;
    }

    & .ant-table-expanded-row {
      & > td {
        background-color: color(color-nested-table-content-bg);
        // border-bottom: 1px solid #EBEBEB !important;
      }
      background-color: color(color-nested-table-content-bg);
    }
  }
  .ant-table.ant-table-small {
    .ant-table-thead > tr > th {
      padding: 6px 16px;
      &:first-of-type {
        padding-left: 12px;
      }
    }

    tbody {
      td {
        &:first-of-type {
          padding-left: 12px !important;
        }

        padding: 5px 16px !important;
        border-bottom: none;
      }
    }
  }

  &-waybill {
    .ant-table.ant-table-small .ant-table-tbody {
      background-color: color(color-nested-table-content-bg);
    }
    .ant-table.ant-table-small .ant-table-thead > tr > th {
      background: color(color-nested-table-content-bg);
      color: $txt-color-blue6;
      &::before {
        width: 0 !important;
      }
    }

    & .ant-table-measure-row {
      // dis
      padding: 0;
      height: 0;
    }
  }
}

.order-transaction-list {
  .ant-table-thead > tr > th {
    padding: 5px 16px;
    background-color: #f2f2f2;
    white-space: nowrap;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #c7c7c7;
  }
  .ant-table-tbody > tr > td {
    padding: 9px 16px;
  }
}

.main-table {
  z-index: 0;

  &.dark {
    .ant-table.ant-table-empty table tbody tr:hover > td {
      background-color: #1f2328 !important;
    }

    & .ant-table table tbody tr.active-row {
      background-color: #32373e;
    }

    &.selected-table tr.active-row > td {
      background-color: #3a4048 !important;
    }

    & .ant-table table tbody tr.ant-table-expanded-row,
    .ant-table table tbody tr.ant-table-expanded-row > td,
    &.delivery .ant-table .ant-table-content .ant-table-expanded-row-level-1 > td::before,
    &.table-expandable .ant-table-expanded-row > td .ant-table td {
      background-color: #32373e;
    }
  }
  &__nested-pink {
    .ant-table.ant-table-empty table tbody tr.ant-table-placeholder:hover > td {
      background-color: color(color-nested-table-content-bg) !important;
    }
    &.dark .ant-table {
      background-color: #32373e !important;
    }
  }

  &.selected-table {
    tr {
      &.active-row {
        & > td {
          background-color: color(color-table-nested-bg) !important;
          // border: none !important;
        }
        &::hover {
          & > td {
            // background-color: $bg-color-orange4 !important;
            background-color: #ffeece !important;
          }
        }
      }
    }
  }
  .ant-table {
    table > tbody > tr {
      & > td {
        &.ant-table-cell {
          vertical-align: middle;
          // border-bottom: 1px solid #ebebeb;
          // padding: 12px;
          padding: 8px 16px;
          white-space: nowrap;
        }
      }
      &::hover {
        background-color: #fafafa;
      }
    }
    &.ant-table-empty {
      table tbody tr {
        &:hover {
          & > td {
            background-color: white !important;
          }
        }
      }
    }
    & .ant-table-thead {
      tr > th {
        &.ant-table-cell {
          &.ant-table-row-expand-icon-cell {
            padding: 0 !important;
            width: 0 !important;
            padding-left: 12px;
          }
        }
      }
    }

    & .ant-table-title {
      // padding-top: 0;
      padding: 0;
    }
    table {
      tbody {
        tr {
          &.active-row {
            background-color: $bg-color-pink1;
            &::hover {
              & > td {
                background-color: $bg-color-pink1;
                border: none !important;
              }
            }
          }
          &.ant-table-expanded-row {
            background-color: color(color-nested-table-content-bg);
            & > td {
              background-color: color(color-nested-table-content-bg);
            }
          }
          td {
            &.ant-table-cell {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
  &.nested-px {
    &-36 {
      .ant-table-expanded-row > td {
        padding-left: 36px !important;
      }
    }
    &-44 {
      .ant-table-expanded-row > td {
        padding-left: 44px !important;
      }
    }
    &-48 {
      .ant-table-expanded-row > td {
        padding-left: 72px !important;
      }
    }
    &-74 {
      .ant-table-expanded-row > td {
        padding-left: 112px !important;
      }
    }
  }
  &__nested {
    &-pink {
      .ant-table .ant-table-thead tr > th.ant-table-cell {
        background-color: color(color-nested-table-content-bg) !important;
      }
    }
    & .ant-table {
      background-color: color(color-nested-table-content-bg);
      & .ant-table-row:not(:last-child) {
        & .ant-table-cell {
          border-bottom: 1px dashed #d2c5c5 !important;
        }
      }
    }
    &.m22-table {
      & .ant-table .ant-table-thead > tr > th:first-child {
        border-radius: 0 !important;
      }
    }

    &.dark .ant-table {
      background-color: #32373e !important;
    }

    table {
      width: auto;
    }
  }
}
.table-expandable {
  & .ant-table table tbody tr td.ant-table-cell.ant-table-row-expand-icon-cell {
    padding-left: 8px;
    padding-right: 8px;
  }
  &.m22-table .main-table__nested .ant-table .ant-table-content .ant-table-thead > tr > th,
  &.m22-table .main-table__nested .ant-table .ant-table-content .ant-table-tbody > tr > td {
    border-radius: 0 !important;
  }

  &.package-list {
    .ant-table.ant-table-small .ant-table-thead > tr {
      & > th {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        line-height: 22px;
        color: #1a1a1a;
        font-weight: 500;
        &:first-of-type {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
    .ant-table.ant-table-small .ant-table-tbody > tr {
      & > td:nth-child(1) {
        padding-left: 0 !important;
      }
    }
  }
  .ant-table-expanded-row {
    & > td {
      // padding: 0px auto !important;
      & .ant-table {
        margin: 0 !important;
        .ant-table-thead > tr > th.ant-table-cell {
          background-color: color(color-nested-table-content-bg);
          &::before {
            width: 0;
          }
        }
        td {
          padding: 6px 12px !important;
          background-color: color(color-nested-table-content-bg);
          &::hover {
            background-color: unset;
          }
          border-bottom: none;
        }
      }
    }
  }

  &-bordered {
    .ant-table-expanded-row {
      & > td {
        & .ant-table {
          & .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody {
            & > tr:last-child > td {
              border-bottom: none !important;
            }
            & > tr > td:not(:last-child) {
              border-right: none !important;
              border-bottom: 1px solid #ececec !important;
            }
          }
        }
      }
    }
  }
}

.table-improved {
  &.dark {
    & .ant-table-content {
      .ant-table-tbody > tr.ant-table-row:not(.data-row_extended):hover > td,
      .ant-table-tbody > tr.ant-table-row:not(.data-row_extended):hover + tr > td {
        background-color: #32373e !important;
        & > i {
          color: $txt-color-red;
        }
      }
    }
  }
  & .ant-table-content {
    & .ant-table-tbody {
      &::before {
        content: '-';
        display: block;
        line-height: 8px;
        color: transparent;
      }
      & > tr > td {
        vertical-align: middle;
        border-bottom: none;
        padding-bottom: 0;

        &:first-child {
          border-top-left-radius: 6px;
        }
        &:last-child {
          border-top-right-radius: 6px;
        }
      }
      & .ant-table-expanded-row {
        & > td {
          padding: 17px 50px;
          background-color: $bg-color-pink6;
          border-bottom: 0 !important;
          &:last-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
      & .active-row {
        border-radius: 8px !important;
        & > td {
          border-bottom: 0 !important;
        }
      }
    }

    .ant-table-tbody > tr.ant-table-row:not(.data-row_extended):hover > td,
    .ant-table-tbody > tr.ant-table-row:not(.data-row_extended):hover + tr > td {
      background-color: color(color-table-row-hover) !important;
      & > i {
        color: $txt-color-red;
      }
    }

    tr.ant-table-row.data-row_extended:hover > td {
      background-color: unset;
    }
  }

  & .ant-table {
    border-radius: 6px;
    padding: 0 16px 16px 16px;
    & .ant-table-title {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// override antd table
.ant-table {
  table {
    .ant-table-thead {
      > tr {
        > th.ant-table-cell {
          color: var(--text-secondary-color);
          font-size: 12px;
          padding: 8px 16px;
        }

        > th.ant-table-cell:nth-child(2) {
          border-radius: unset !important;
        }
      }
    }

    tbody {
      tr {
        .ant-table-row-expand-icon-cell {
          border-left: 1px solid transparent;

          > span {
            display: inline-block;
            width: 24px;
          }
        }

        &.ant-table-row:nth-child(even) {
          &:not(.row-light):not(.row-dark) {
            td {
              background-color: var(--bg-table-triped);
            }
          }
        }

        &.ant-table-row.active-row {
          background-color: var(--bg-lighter-color);
          position: relative;

          &:hover {
            & > td {
              background-color: var(--bg-lighter-color);
            }
          }

          &.show-expand-icon {
            td:nth-child(2) {
              border-radius: unset !important;
            }

            .ant-table-row-expand-icon-cell {
              border-radius: 6px 0 0 0 !important;
              border-left: 1px solid #fcd535;
            }
          }

          td {
            &.ant-table-cell {
              border-top: 1px solid #fcd535;
            }

            &:last-of-type {
              border-radius: 0 6px 0 0 !important;
              border-right: 1px solid #fcd535;
            }
          }
        }

        &.ant-table-expanded-row {
          > td.ant-table-cell {
            border-radius: 0px 0 6px 6px;
            border: 1px solid #fcd535;
            border-top: unset;
            // border-bottom: 1px solid var(--border-checked-color);
            padding: 8px 16px 16px 16px;
          }

          .ant-table-wrapper::before {
            display: none;
          }

          .ant-table {
            table {
              background-color: var(--bg-lighter-2-color);
              border-spacing: 0 4px;

              thead th {
                color: var(--table-in-table-header);
                font-size: 12px;
                font-weight: 500;
                padding: 6px 12px;
              }
            }
          }
        }
      }

      .ant-table-wrapper::after {
        display: none;
      }
    }
  }

  .ant-table-content .ant-table-expanded-row-level-1 > td::before {
    content: '';
    width: calc(100% + 2px);
    height: 12px;
    background-color: var(--bg-lighter-2-color);
    position: absolute;
    top: -12px;
    left: -1px;
    border: 1px solid #fcd535;
    border-top: none;
    border-bottom: none;
  }
}

.table-delivery {
  border: 1px solid transparent !important;
  &-checked {
    border: 1px solid transparent !important;
    border: 1px solid var(--border-checked-color) !important;
  }
}

.delivery-create {
  &.table-expandable .ant-table-expanded-row > td .ant-table td {
    padding: 12px 12px !important;
  }
}

.table-create-success {
  &.dark {
    border-radius: 12px;
    padding: 20px;
    border: 1px solid #5e5e5e;
  }
}

.m22-quick-pagination {
  display: flex;
  align-items: center;
  //margin-right: 12px;
  justify-content: space-between;

  .badge {
    font-weight: 400;
    font-size: 14px;
    //color: #5A5A5A;
    margin-right: 10px;

    .previous, .next {
      cursor: pointer;
    }
  }
}

.ant-table.ant-table-empty table tbody tr.ant-table-row:nth-child(even):not(.row-light):not(.row-dark) td {
  background-color: unset;
}