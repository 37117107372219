.connect-market-container {
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 0 0;
  }

  .ant-tabs-nav-wrap {
    height:32px;
    background-color: #FFFFFF;
    border-radius: 6px 6px 0 0;
    -webkit-border-radius: 6px 6px 0 0;
    -moz-border-radius: 6px 6px 0 0;
  }

  .ant-tabs-nav-list {
    background-color: #FFFFFF;
    .ant-tabs-tab {
      background: #FFFFFF;
      border: transparent!important;
      padding: 6px 32px;

      .ant-tabs-tab-btn {
        color: #3340b6;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        border-radius: 12px 12px 0 0;
        -webkit-border-radius: 12px 12px 0 0;
        -moz-border-radius: 12px 12px 0 0;
      }

      &-active {
        background-color: #ECEDF8;
      }
    }
  }

  .ant-tabs-content-holder {
    background: #FFFFFF;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 6px 6px;
  }

  .shop-list-header {
    padding: 8px 16px 0 16px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color:#1A1A1A;
      text-transform: capitalize;
    }

    .badge {
      border-radius: 20px;
      -moz-border-radius: 20px;
      -webkit-border-radius: 20px;
      padding: 0 8px;
      background: #FAA200;
      color: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: 12px;
    }

    button {
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      padding: 5px 16px;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-transform: capitalize;
      background: #3340B6;
      color:#FFFFFF;
      border: 1px solid transparent!important;
      &:hover {
        box-sizing: border-box;
        box-shadow: 0 0 0 3px #CED1F3;
      }
    }
  }

  .shop-list {
    padding: 12px;
    background-color: #FFFFFF;

    thead[clas*='ant-table-thead'] tr {
      border-radius: 4px 4px 0 0;
      -webkit-border-radius: 4px 4px 0 0;
      -moz-border-radius: 4px 4px 0 0;
    }

    thead[class*="ant-table-thead"] th {
      padding: 5px 12px;
      border: none;
      background-color: #F4F6F8;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 12px;
      &:before {
        opacity: 0;
      }
    }

    .status-badge {
      //display: flex;
      align-items: center;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      padding: 0 12px;
      color:#FFFFFF
    }

    .custom-row-hover:hover td {background: #feefd8 !important;}

    .empty-description {
      display: flex;
      flex-direction: column;
    }
  }
}

.connect-shop-modal {
  background: #FFFFFF;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 0 0 0 0;

  .ant-modal-content .ant-modal-header {
    padding: 12px 0;
    border: none;
  }

  .ant-modal-title {
    //padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
  }

  &-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
    span {
      width: 25%;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #1A1A1A;
    }
    input {
      background: #FFFFFF;
      border: 1px solid #C7C7C7;
      box-sizing: border-box;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      &::-webkit-input-placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #BDBDBD;
      }
    }

  }

  .ant-modal-footer {
    padding: 0 0 12px 0;
    button {
      width: 20%;
      padding: 5px 16px;
      border: 1px solid;
      border-radius: 4px;
      background: #FFFFFF;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      justify-content: center;
      align-items: center;

      &.submit {
        border: 1px solid #3340B6;
        background-color: #3340b6;
        color: #FFFFFF;
        &:hover {
          box-shadow: 0 0 0 3px #CED1F3;
        }

        &:disabled {
          background: #F5F5F5;
          border: 1px solid #C7C7C7;
          box-sizing: border-box;
          border-radius: 4px;
          color: #BDBDBD;
          &:hover {
            box-shadow: none !important;
          }
        }
      }

      &.cancel {
        border: 1px solid #C7C7C7;
        color: #1A1A1A;

        &:hover {
          background-color: #707070;
          color: #FFFFFF;
          border: 1px solid #707070;
        }
      }
    }

  }
}
