#root {
  overflow-x: hidden;
}

body {
  height: 100vh;
  overflow: overlay;
}

.dark {
  ::selection {
    color: #000;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #a9a9a9bd;
  border-radius: 10px;
}

.border-card-header {
  box-shadow: 0px 2px 14px #e6e6e6;
  background-color: #ffffff;
  &.m22-box-dark {
    box-shadow: none;
  }
}
// .border-card-header-dark
.title-body {
  font-size: 20px;
  font-weight: 500;
}
.icon-empty {
  width: 40px;
  height: 40px;
}

.custom-line-dash {
  height: 1px;
  background: linear-gradient(to right, var(--dash-color) 8px, transparent 6px);
  background-size: 12px;
}

#cs-live-chat > #cs_chat_iframe >.MuiBox-root.css-1rqe694 {
  color: #1a1a1a!important;
  background-color: rgb(252 213 53)!important;
}

.css-1c7w5yq {
  top: 8px!important;
}

.css-1w2zr90 {
  color: #1a1a1a!important;
  background-color: rgb(240 203 51)!important;
}

.css-14qlc8d{
  color: #1a1a1a!important;
  background-color: rgb(241 203 51)!important;
}

.css-11fo197{
  color: #1a1a1a!important;
}

@import 'helpers/variables';
@import 'themes-variables/index.scss';

@import 'helpers/mixins';

@import 'screens/claim';
@import 'screens/claim-detail';
@import 'screens/profile';
@import 'screens/connect-market';
@import 'screens/profile-service';
@import 'screens/delivery.scss';
@import 'screens/register.scss';

@import 'module/index.scss';
@import 'helpers/classes';
