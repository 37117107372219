.steps-header {
  background-color: white;
  position: fixed !important;
  z-index: 2;
  &.ant-card {
    & .ant-card-body {
      padding: 15px 12px;
      & .ant-steps {
        line-height: 22px;
        color: #1a1a1a;
        & .ant-steps-item-title {
          line-height: 27px;
          font-size: 14px;
        }
      }
    }

    & .line {
      border-top: 1px solid #ebebeb;
    }
  }
  position: relative;

  &.dark {
    &::before {
      content: '';
      background-color: #1f2328;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -24px;
    left: 0;
    width: 100%;
    height: 24px;
    background-color: #edf3f7;
  }
}
.steps-header-content {
  background-color: #ffffff;
}
.shopping-cart-steps {
  .ant-steps-item-title {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    border-color: #bdbdbd;
    color: #bdbdbd;
  }
}

.skeleton-cart {
  z-index: 2;
  & .ant-card-body {
    padding: 16px 12px;
    & .skeleton-item {
      margin-bottom: 12px;
      // border-bottom: 1px solid #ebebeb;
      padding: 12px 0;
    }
  }
  & .ant-card-head {
    padding: 0 12px;
  }
}
.step-disable {
  cursor: not-allowed !important;
}
