.filter-card {
  .ant-card-body {
    padding: 0;
  }
}
.transaction-card {
  border-radius: 6px !important;
  .ant-card-body {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0px;
  }
}
