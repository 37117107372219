.ant-modal-body {
	padding: 0;
}

.ant-modal-header {
	border-bottom: 1px solid #c7c7c7;
	.ant-modal-title {
		display: flex;
		align-items: center;
	}
}
.ant-modal-close-x {
	line-height: 50px !important;
}

.link-create-ticket {
	color: white;
	&:hover {
		color: white;
	}
}
.item-log {
	color: #008df9;
}
