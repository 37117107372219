.register-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url("./../../../../public/sign-up-bg.png");
  background-size: cover;
  background-position: right;

  @media screen and (min-width: 769px) {
    justify-content: flex-start;
    padding-left: 10vw;
  }
}

.register-form {
  width: 460px;
  padding: 40px;
  padding-top: 18px;
  border-radius: 32px;
  background: #FFF;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.20);

  .ant-form-item .ant-form-item-label {
    padding: 0;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border: 1px solid var(--background-border, #C7C7C7);
    background: var(--background-white, #FFF);
  }

  .ant-input {
    height: 46px;
  }

  .ant-input-affix-wrapper {
    height: 46px;

    .ant-input {
      height: 99%;
    }
  }

  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  .ant-input-group .ant-input {
    border-right: 0;
  }

  .ant-form-item-explain>.ant-form-item-explain-error {
    padding-top: 2px;
    margin-bottom: 0;
  }

  .ant-btn-primary {
    font-weight: 500;
  }

  a:link,
  a:visited {
    text-decoration: underline;
    color: #5a5a5a;
    &:hover, &:focus {
      color: var(--color-primary);
    }
  }

}

.register-success {
  @extend .register-form;

  p {
    color: var(--text-color-text-secondary-2, #7D7E7E);
    /* Text Style/Body 1 Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
}