.ant-input-number {
  border-radius: 4px;
  border-color: #c7c7c7;
  font-size: 14px;

  &:hover {
    :not(:disabled) {
      border-color: #ffce63;
    }
    border-right-width: 1px;
    z-index: 1;
  }

  &.ant-input-number-disabled {
    color: #1a1a1a;
  }

  &-focused {
    border-color: #ffce63;

    .ant-input-number-group-addon {
      border-color: #ffce63;
    }
  }
}

.ant-input-number-wrapper {
  .ant-input-number {
    border-radius: 4px 0 0 4px;
    border-right: none;
  }

  .ant-input-number-group-addon {
    background: transparent;
    border-left: none;
    border-color: #c7c7c7;
    border-radius: 0 4px 4px 0;
    color: #bdbdbd;
    padding: 0 6px;
  }

  &:hover {
    .ant-input-number {
      border-right: none;
    }
  }
}

.ant-input-number-group-wrapper {
  &._focused {
    .ant-input-number-wrapper {
      border-color: #ffce63;
      box-shadow: 0 0 0 2px rgb(251 183 57 / 20%);
      border-radius: 4px;
    }

    .ant-input-number-focused {
      border-color: #ffce63;
      box-shadow: none;
    }
    .ant-input-number-group-addon {
      border-color: #ffce63;
    }
  }

  &._disabled {
    .ant-input-number-group-addon {
      background: #f5f5f5;
    }
  }

  &:hover {
    .ant-input-number-group-addon {
      :not(:disabled) {
        border-color: #ffce63;
      }
    }
  }
  &:disabled {
    background-color: #f5f5f5;
  }
}

.ant-input-number-group-wrapper-status-error {
  .ant-input-number-group-addon {
    border-color: #ff4d4f;
  }

  &._focused,
  &:hover {
    .ant-input-number-group-addon {
      border-color: #ff4d4f;
    }
  }
}

.ant-input-password-icon {
  cursor: pointer;
}

// select
span.ant-select-arrow {
  color: #7d7d7e;
}