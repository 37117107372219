.warehouselist .ant-typography-copy {
  color: #7d7e7e;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
  &:hover {
    color: #7d7e7e;
  }
}
