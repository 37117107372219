@import '../../resources/styles/helpers/mixins';
@import '../../resources/styles/helpers/variables';

$br-chat: 12px;

:root {
  --box-shadow-color-box: transparent;
  --file-bg-color: #ccc;
  --file-icon-color: #fff;
}

.dark {
  --box-shadow-color-box: #0000006e;
  --file-bg-color: transparent;
  --file-icon-color: transparent;
}

.box-chat {
  & .chat-logo {
    position: fixed;
    right: 12px;
    bottom: 42px;
    cursor: pointer;
  }

  .alert {
    padding: 10px;

    &__inner {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      border: 1px solid #ffa600;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fff6e5;
      padding: 5px;
    }
  }

  .box-chat-float-button {
    transition: all 0.3s;
    position: fixed;
    width: 329px;
    height: 34px;
    right: 412px;
    z-index: 99;
    box-shadow: 0px 6px 45px 0px var(--box-shadow-color-box);
    filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.1));

    &.expand {
      width: 702px;
      height: 550px;
    }

    &.show-threads {
      width: 660px;
    }
  }

  .collapsed {
    bottom: 0;
    width: 330px;
  }

  .expand {
    bottom: 0;
  }

  .box-chat-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    background: $primary-color;
    border-radius: 12px 12px 0px 0px;
    height: 34px;

    &__top-bar {
      display: flex;
      align-items: center;
      font-weight: 500;
    }

    &__badge {
      display: inline-block;
      background: #ffffff;
      border-radius: 20px;
      font-style: normal;
      margin-left: 6px;
      padding: 0px 8px;
    }
  }

  .box-chat-list-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 500px;
    position: relative;
    min-width: 330px;
  }

  .list-chat {
    flex: 1;
  }

  .box-chat-body {
    background-color: var(--bg-main-color);
    display: flex;
    z-index: 999;

    .box-chat-thread {
      width: 320px;
      min-width: 320px;
      border-right: 1px solid var(--divider-color);
      box-sizing: border-box;

      &__filter {
        width: 100%;
        margin-top: 16px;

        .box-chat-thread-input {
          .ant-input-suffix {
            color: #7d7e7e;
          }
        }

        &__item {
          width: 100%;

          .ant-select-selector {
            border-radius: 4px;
          }
        }
      }

      &__threads {
        padding: 0 16px 32px 16px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 448px;

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(196, 196, 196, 0.6);
          border-radius: 20px;
        }

        .box-chat-thread-item {
          &:not(.selected) {
            &:hover {
              background-color: var(--bg-chat-hover);
            }
          }

          cursor: pointer;
          padding: 8px 12px;
          border-radius: 8px;
          margin-bottom: 4px;
          border: 1px solid transparent;
          display: grid;
          grid-template-columns: 32px auto;
          gap: 8px;
          align-items: center;

          .thread-order {
            display: flex;
            justify-content: space-between;

            >span {
              color: var(--title-chat-unread);
              font-size: 12px;
              font-weight: 700;
            }

            .thread-dot {
              color: $primary-color;
              margin-right: 5px;
            }
          }

          .thread-message {
            color: var(--msg-chat-unread);
            font-weight: 500;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .thread-time {
            font-size: 10px;
            color: var(--time-chat-unread);
          }

          &.read {
            &:hover {
              .thread-order>span {
                color: var(--title-chat-hover);
              }

              .thread-message {
                color: var(--msg-chat-hover);
              }

              .thread-time {
                color: var(--time-chat-hover);
              }
            }

            .thread-order>span {
              color: var(--title-chat-readed);
              font-weight: 500;
            }

            .thread-message {
              color: var(--msg-chat-readed);
              font-weight: 400;
            }

            .thread-order {
              font-weight: 400;
            }

            .thread-time {
              color: var(--time-chat-readed);
            }
          }

          &.selected {
            background-color: var(--bg-chat-active);

            .thread-order>span {
              color: var(--title-chat-active) !important;
            }

            div.thread-message {
              color: var(--msg-chat-active);
            }

            .thread-time {
              color: $txt-color-gray2;
            }
          }
        }
      }
    }

    .status-filter-btn {
      border-radius: 16px;
      padding-left: 16px;
      padding-right: 16px;
      color: var(--text-secondary-color);
      background-color: var(--bg-lighter-4-color);
      border: none;
      font-weight: 500;
      font-size: 12px;

      &:hover {
        border-color: transparent;
      }

      &_selected {
        background-color: $primary-color;
        color: $txt-color-main;

        &:hover {
          border-color: transparent;
        }
      }
    }

    .box-chat-conversions-loading,
    .ant-spin-nested-loading {
      width: 100%;
    }

    .box-chat-messages {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px 12px;
      display: flex;
      flex-direction: column;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.6);
        border-radius: 20px;
      }

      .chat-message-item {
        margin-bottom: 20px;
        max-width: 270px;
        align-self: flex-end;
        gap: 8px;
        display: flex;
        flex-direction: column;

        &__group {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          &__item {
            width: 80px;
            height: 56px;
            text-align: left;

            .ant-image {
              width: 100%;
            }

            & img,
            & video {
              width: 100%;
              border-radius: 4px;
              object-fit: cover;
            }
          }
        }

        &__time-avatar {
          display: flex;
          gap: 6px;

          &__time {
            font-size: 12px;
            color: #bdbdbd;
          }

          &__dot {
            color: #bdbdbd;
            position: relative;
            top: -3px;
          }

          &__avatar {
            width: 24px;
            height: 24px;
            object-position: center center;
            object-fit: cover;
            border-radius: 100%;
          }

          &__name {
            font-size: 12px;
            color: var(--text-secondary-color);
            align-self: center;
          }
        }

        &__message {
          display: flex;
          justify-content: flex-end;
          border-radius: 2px 12px 12px 12px;

          &__attachment-items {
            text-align: right;

            &__inner {
              border-radius: $br-chat 2px $br-chat $br-chat;
              display: inline-block;
            }
          }

          &__text {
            span {
              overflow: hidden;
              min-height: 46px;
              align-items: center;
              padding: 12px;
              border-radius: $br-chat 2px $br-chat $br-chat;
              color: var(--text-primary-color);
              background: var(--bg-primary-color);
              display: inline-flex;

              a {
                color: #008df9;
                text-decoration-line: underline;
              }
            }
          }

          a {
            word-break: break-all;
          }

          &__image {
            box-sizing: border-box;
            width: 48px;
            height: 48px;
            border-radius: 4px;
            margin: 3px;

            &:nth-child(5) {
              margin-right: 0;
            }
          }

          &__video {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            position: relative;

            video {
              width: 100%;
              height: 100%;
            }

            .video-backdrop {
              position: absolute;
              background-color: rgba(0, 0, 0, 0.5);
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 10;
              border-radius: 4px;
            }

            .video-icon {
              position: absolute;
              color: #fff;
              top: calc(50% - 9px);
              left: calc(50% - 9px);
              font-size: 18px;
              z-index: 11;
            }
          }

          &__file {
            color: #3340b6;
            font-weight: bold;
          }
        }

        &__timestamp {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row-reverse;
          gap: 6px;
        }

        &.friend {
          align-self: flex-start;

          .chat-message-item__time-avatar {
            text-align: left;
            display: flex;
            align-items: center;
            flex-direction: row;

            &__avatar {
              order: 1;
              align-self: center;
            }

            &__name {
              order: 2;
              align-self: center;
            }

            &__dot {
              order: 3;
              align-self: center;
            }

            &__time {
              order: 4;
              align-self: center;
            }
          }

          .chat-message-item__message {
            justify-content: flex-start;

            &__text {
              text-align: left;

              span {
                border-radius: 2px $br-chat $br-chat $br-chat;
                background: var(--bg-lighter-4-color);
              }
            }

            &__image {
              box-sizing: border-box;
              width: 48px;
              height: 48px;
              border-radius: 4px;
              margin: 3px;

              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                border: 1px solid red;
              }
            }
          }

          .chat-message-item__timestamp {
            flex-direction: row;
          }
        }
      }
    }

    .chat-input-box {
      display: flex;
      flex-direction: column;
      padding: 0 16px;

      &__input-container {
        display: flex;
        background: var(--bg-lighter-4-color);
        border-radius: 12px;
        padding-right: 16px;
        min-height: 44px;
        align-items: center;
        gap: 12px;
      }

      &__input {
        resize: none;
        height: 32px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 8px !important;
      }

      &__actions {
        display: flex;
        align-items: center;
        gap: 8px;

        &__send {
          cursor: pointer;
          color: #707070;

          &:hover {
            color: $primary-color;
          }
        }

        &__send-file {
          cursor: pointer;
          color: #707070;

          &:hover {
            color: $primary-color;
          }
        }
      }

      &.editing {
        .chat-input-box__actions {
          &__send {
            color: $primary-color;
          }
        }
      }

      &__attachments {
        display: flex;
        flex-wrap: wrap;

        &__item {
          margin: 5px 5px 5px 0;
          box-sizing: border-box;
          width: 56px;
          height: 56px;
          position: relative;
          display: inline-block;

          &:first-child {
            margin-left: 5px;
          }

          &:hover {
            .chat-attachment-remove-icon {
              opacity: 2;
            }

            .attachment-item-backdrop {
              opacity: 1;
            }
          }

          &--image {
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center center;
              border-radius: 4px;
              box-sizing: border-box;
            }
          }

          &--file {
            border: 1px solid #ccc;
            background-color: var(--file-bg-color);
            padding: 5px;
            overflow: hidden;
            border-radius: 4px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            width: 112px;

            .file-icon {
              margin-right: 5px;
              font-size: 20px;
              background-color: var(--file-icon-color);
              border-radius: 100%;
              width: 30px;
              height: 30px;
              display: inline-block;
              text-align: center;
              padding: 5px 3px 3px 3px;
            }

            .att-file-name {
              line-height: 13px;
              width: 50px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .chat-attachment-remove-icon {
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
            color: #fff;
            z-index: 100;
            opacity: 1;
            transition: opacity;
            transition-duration: 0.5s;
          }
        }
      }
    }
  }
}

.box-chat-modal-preview {
  .ant-modal-title {
    word-break: break-word;
    padding-right: 32px;
  }

  .preview-image {
    max-width: 488px;
  }

  .preview-video {
    width: 100%;
    position: relative;

    video {
      width: 100%;
      height: 300px;
    }

    .video-backdrop {
      position: absolute;
      background: linear-gradient(0deg, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.5));
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      border-radius: 4px;
    }

    .video-icon {
      position: absolute;
      top: 107px;
      left: 226px;
      color: #ffffff;
      font-size: 72px;
      cursor: pointer;
      z-index: 11;
    }
  }
}

.light {
  .box-chat-thread {

    .ant-input-suffix,
    .ant-input,
    .ant-select-selection-item,
    .ant-select-clear,
    .ant-input-clear-icon {
      color: #7d7e7e !important;
    }
  }
}

.box-chat {
  .box-chat-float-button.collapsed-left {
    &:not(.collapsed) {
      width: 392px;
    }

    .box-chat-thread {
      width: 0;
      min-width: 0;
      z-index: -1 !important;
    }
  }
}

.chat-message-item {
  &.friend {
    .chat-message-info {
      flex-direction: row-reverse;
      justify-content: flex-start;
    }
  }
}

.comment-close-icon {
  display: inline-flex;
  height: 32px;
  align-items: center;
  gap: 4px;

  .hidden-icon {
    display: inline-block;
    padding: 12px 0 12px 12px;
  }
}