.bt-status {
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  //display: flex;
  height: 20px;
  left: 12px;
  top: 0;
}

.orders-data-shows {
  & .ant-list-header {
    padding: 16px 4px;
    border-bottom: none !important;
  }

  & .ant-list-items {
    padding-left: 0;
  }
}

// .row-extended-hovered.light {
//   background-color: #f4f4f4 !important;
// }

.rowDarkOnHover {
  td {
    background: #32373e!important;
  }
}
.rowLightOnHover {
  td {
    background: #f6f6f6!important;
  }
}
