.divider-bd-custom {
  border: 1px solid $bd-divider-success-box;
}
.faq-modal {
  .ant-modal-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .ant-modal-footer {
    padding-bottom: 12px;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
  border: 1px solid #c7c7c7;
}

.ant-modal-content {
  & .ant-modal-footer {
    & > button {
      text-transform: capitalize;
    }
  }
}

.ant-empty-image {
  height: auto;
}

.data-row_extended {
  cursor: pointer;
}

.ant-breadcrumb {
  font-size: 14px;
  line-height: 22px;

  & > ol {
    align-items: center;
    & > li {
      display: flex;
      align-items: center;
      & .ant-breadcrumb-separator {
        margin: 0 4px;
      }
      &:last-of-type {
        margin-right: 4px;
        & .ant-breadcrumb-link * {
          color: var(--text-primary-color);
          font-weight: 500;
        }
        & .ant-breadcrumb-separator {
          display: none;
        }
      }
    }
  }
}

.ant-popover-inner-content {
  padding: 12px;
  color: var(--text-primary-color);
}

.ant-list-item-meta-avatar {
  margin-right: 12px;
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  background: transparent;
  color: #f59f32;
}

.ant-input {
  background: transparent;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  font-weight: 400;
  &:placeholder {
    color: #707070;
    font-size: 12px;
  }
}

.ant-tooltip-inner {
  background-color: #fbb739;
  color: white;
}

.gsa-tooltip {
  .ant-tooltip-arrow-content::before {
    display: none;
  }
  & .shoppe-gsa-link a {
    color: var(--text-primary-color);
    &:hover {
      color: #fbb739;
    }
  }
}

.ant-menu-inline,
.ant-menu-vertical {
  border-right: 0;
}

.ant-menu-vertical {
  &.ant-menu-inline-collapsed {
    padding-left: 8px !important;
    padding-right: 8px !important;
    & .icon {
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 6px;
    }
  }
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-item {
  padding-left: 25px !important;
  padding-right: 0 !important;
}
ant-menu-submenu-title > ant-menu-submenu-arrow {
  display: none;
}
.ant-input-affix-wrapper {
  border-radius: 4px;
  border: 1px solid #c7c7c7;
}

.ant-radio {
  line-height: 22px;
  top: 2px;
}

.link-override {
  &:hover {
    color: #fbb739;
  }
}

.ant-upload-list-item-actions {
  display: flex;
  align-items: baseline;

  & .anticon.anticon-eye {
    vertical-align: 0;
    height: 18.858px;
    line-height: 1;
    & > svg {
      height: 18.858px;
    }
  }
}
.ant-upload-list-item-card-actions-btn {
  & .anticon.anticon-delete {
    height: 18.858px;
    & > svg {
      height: 18.858px;
    }
  }
}

.ant-tooltip-inner {
  font-size: 12px;
  font-weight: 500;
  color: white;
  border-radius: 6px;
  box-shadow: none;
}
.ant-tooltip-arrow-content {
  box-shadow: none !important;
}
.ant-table-title {
  border-radius: 6px 6px 0 0;
  padding: 16px 8px;
}

.ant-table-cell.ant-table-selection-column {
  border-spacing: initial;
}

.ant-select-selection-placeholder {
  // font-size: 14px;
  line-height: 32px;
  padding-left: 6px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  padding-left: 12px;
  padding-right: 12px;
  // color: #707070;
  font-size: 14px;
}

.ant-layout-sider-trigger {
  display: flex;
  justify-content: center;
  border-radius: 0 8px 8px 0;
  height: 60px !important;
  // &-dark {
  // 	background: #32373e !important;
  // }
}

.ant-layout.ant-layout-has-sider {
  // overflow-y: scroll;
  min-height: 100vh;
}

.custom-menu-selected {
  .ant-menu-item-icon {
    background-color: #fbb739 !important;
    border-radius: 20px !important;
  }
  // background-color: red;
}

.ant-layout.ant-layout-has-sider {
  // overflow-y: scroll;
  min-height: 100vh;
}

.ant-progress-steps-item {
  border-radius: 4px;
  width: 5px !important;
}

.ant-empty-image {
  height: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

// divider
.ant-divider.ant-divider-horizontal {
  border-color: var(--divider-color);

  &.ant-divider-dashed {
    height: 1px;
    background: linear-gradient(to right, var(--dash-color) 6px, transparent 6px);
    background-size: 12px;
    border: none;
  }
}

// alert
div.ant-alert {
  border-radius: 8px;

  &.ant-alert-error,
  &.ant-alert-warning {
    border: none;
  }

  .ant-alert-close-icon {
    font-size: 14px;
    font-weight: bold;
  }

  &.ant-alert-warning {
    background-color: var(--bg-error-2);
    color: var(--text-warning);

    .ant-alert-icon {
      color: $primary-color;
    }
  }

  &.ant-alert-error {
    background-color: var(--bg-error);
    color: var(--text-error);

    .ant-alert-close-icon {
      color: var(--text-primary-color);
    }
  }

  &.ant-alert-with-description {
    padding: 16px;

    .ant-alert-icon {
      font-size: 14px;
      margin-right: 12px;
      margin-top: 4px;
    }
  }

  &.alert-small {
    padding: 12px;
    .ant-alert-description {
      font-size: 12px;
    }
  }
}

// image
div.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.6);
}

img.ant-image-preview-img {
  max-width: 80%;
  max-height: 80%;
}

// notification
.ant-notification {
  .ant-notification-notice {
    background: var(--bg-main-color);
    box-shadow: var(--box-shadow-notification);
    border-radius: 24px;
    &.dark {
      box-shadow: 0px 6px 15px #000000;
    }
    .ant-notification-notice-icon {
      display: none;
    }

    .ant-notification-notice-message {
      margin-left: 16px;
      font-weight: 500;
      text-transform: capitalize;
    }

    .ant-notification-notice-description {
      margin-left: 16px;
      color: var(--text-secondary-color);
    }

    &.ant-notification-notice-success {
      .ant-notification-notice-message {
        color: $success-color;
      }
    }

    &.ant-notification-notice-error .ant-notification-notice-message {
      color: $error-color;
    }

    &.ant-notification-notice-warning .ant-notification-notice-message {
      color: $txt-color-warning;
    }
  }

  .ant-notification-notice-close {
    color: #7d7e7e;
    font-size: 16px;
    right: 24px;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #ebebeb!important;
  box-shadow: none!important;
}

.ant-picker-range .ant-picker-clear {
  right: 8px;
}