div.shopping-cart-table-head {
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 2px 2px;
}

.merchant-card-bottom {
  background-color: #ffffff !important;

  &.dark {
    background-color: #1f2328 !important;
  }
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 80px;
  width: calc(100% - 80px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 42px 24px;
  z-index: 99;
}
.merchant-card-title {
  position: fixed;
  top: 0;
  .ant-card-body {
    padding: 0;
  }
}

.merchant-card-custom {
  background-color: #ffffff !important;
  padding: 0;

  &.ant-card {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    padding: 12px 12px 0px 12px;
    & .ant-card-body {
      padding: 8px 4px;
    }

    & .ant-card-head {
      background-color: var(--bg-lighter-3-color);
      margin-bottom: 0;
      padding: 10px 16px;
      min-height: 36px;
      border-radius: 12px 12px 2px 2px;
      border-bottom: none;
      cursor: pointer;

      &-title {
        padding: 0;
        line-height: 20px;
      }
    }

    & .ant-card-body {
      border-radius: 0 0 6px 6px;
      & .ant-list-item {
        border: none;
        margin-top: 8px;
        margin-bottom: 16px;
        &:hover {
          background-color: var(--bg-table-cart-hover) !important;
        }
        &:nth-child(even) {
          background-color: var(--bg-table-triped);
        }
      }
    }
  }

  &.active {
    border-color: var(--border-checked-color);
    & .ant-btn:not([disabled]) {
      background-color: transparent;
    }
  }

  &.ant-card {
    &.dark {
      background-color: #1f2328 !important;
    }
  }
}
.button-delete-all {
  height: 40px !important;
  width: 160px;
  :hover {
    border-color: red !important;
    & > i,
    & > span {
      color: red !important;
    }
  }
}

.ant-btn:not([disabled]) {
  &.button-delete-all {
    border: none;
    // background-color: white;
    background: transparent;
    &:hover,
    &:active,
    &:focus {
      & > i,
      & > span {
        color: red !important;
        background: none !important;
      }
    }
  }
}

.button-order-custom {
  height: 40px !important;
  width: 160px;
}

.divider {
  .ant-divider-horizontal.ant-divider-with-text-center::before,
  .ant-divider-horizontal.ant-divider-with-text-center::after {
    border-top: 1px solid red;
  }
}
