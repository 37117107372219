.delivery-filter {
	.clean-filter {
		> span:first-child {
			transform: rotate(-90deg);
		}
		padding-right: 0;
		transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
		box-shadow: none;
		&.ant-btn:not([disabled]):hover {
			color: #1a1a1a;
		}
		&[ant-click-animating-without-extra-node="true"] {
			&::after {
				animation: fadeEffect 3s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.8s cubic-bezier(0.08, 0.82, 0.17, 1);
				opacity: 0.2;
				box-shadow: 0px 0px 0px 3px #CED1F3 !important;
			}	
			& .anticon-redo {
				transition: 3s all ease;
				transform: rotate(270deg) !important;
			}
		}
		&.ant-btn:not([disabled]):active, &:focus{
			color: #707070;
		}
	}
	

	.ant-form-item {
		margin: 0;
		color: #1a1a1a;
		font-size: 14px;
		line-height: 22px;
		font-family: 'Roboto', sans-serif;
		display: flex;
		align-items: center;
		.ant-form-item-label {
			& > label {
				font: inherit;
				height: 22px;
				color: #1a1a1a;
				&::after {
					margin: 0;
				}
			}
		}
		input {
			font: inherit;
			border-color: #c7c7c7;
			border-radius: 4px;
			&::placeholder {
				color: #bdbdbd;
			}
			padding: 2px 9px;
			&[type='date'] {
				border: none;
			}
		}

		&.status-cutoff,
		&.query {
			& .ant-form-item-label {
				// flex-basis: 142px;
				flex-shrink: 0;
			}
		}

		.ant-picker-range {
			border-color: #c7c7c7;
			padding: 0 9px;
			border-radius: 4px;
			font: inherit;
		}
	}
	& .ant-row {
		& .type-search {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			font-family: 'Roboto', sans-serif;
			font-size: 14px;
			line-height: 22px;
			& .ant-select {
				flex-basis: 23%;
			}
			& .ant-form-item {
				&:first-of-type {
					margin-right: 4px;
				}
				flex-basis: 100%;
				flex-direction: row-reverse;
			}
		}
	}

	& .ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-color: #c7c7c7;
		box-shadow: none;
		border-radius: 4px;
		line-height: 22px;
		padding-top: 3px;
		padding-bottom: 3px;
		height: 28px;
		font: inherit;
		font-size: 14px;
		& .ant-select-selection-item {
			display: flex;
			align-items: center;
			gap: 4px;
			font-size: 14px;
			.ant-image {
				display: flex;
				align-items: center;
			}
		}
		.ant-select-selection-placeholder {
			display: flex;
			align-items: center;
		}
	}
}


.delivery-request {
	.ant-table-expanded-row {
		& > td {
			&.ant-table-cell {
				border-bottom: none !important;
			}
			padding: 0px auto !important;
			& .ant-table {
				margin: 0 !important;
				.ant-table-thead > tr > th.ant-table-cell {
					background-color: #FFF6E5 !important;
					&::before {
						width: 0;
					}
				}
				td {
					padding: 6px 12px !important;
					background-color: #FFF6E5 !important;
					&.ant-table-cell-row-hover {
						background-color: unset;
					}
					&::hover {
						background-color: unset;
					}
					border-bottom: none !important;
				}
			}
		}
	}
}

