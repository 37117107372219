.personal-note {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: 300px;

  &_in-table {
    max-width: 150px !important;
  }
}

.ant-typography-edit-content {
	padding-top: 8px !important;
	width: 100%;
	left: 0 !important;
	margin-left: 0 !important;
}

.modal-inline-edit {
  font-family: 'Roboto', sans-serif;
	& .ant-modal-content {
    & .ant-modal-header {
      padding: 12px 0;
      border-bottom: 1px solid #C7C7C7;
      & .ant-modal-title {
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
      }
    }
    & .ant-modal-footer {
      padding-bottom: 12px;
    }
  }
}
