.rate-box {
  .rate-star-box {
    display: flex;
    .rate-star {
      color: #ccc !important;
      margin-right: 5px;
      cursor: pointer;
      font-size: 1.5em;
      &:hover {
        color: #FBB739 !important;
      }
    }
  }
  .rate-content-box {
    display: flex;
    .info-group-value {
      flex-grow: 2;
    }
    .rate-content {
      //width: 100%;
      resize: none;
      border-radius: 4px;
      &>.ant-input{
        padding-top: 12px;
      }
    }
  }

  .rate-selected-value-5 {
    .rate-star-1,.rate-star-2,.rate-star-3,.rate-star-4,.rate-star-5 {
      color: #FBB739 !important
    }
  }
  .rate-selected-value-4 {
    .rate-star-1,.rate-star-2,.rate-star-3,.rate-star-4 {
      color: #FBB739 !important
    }
  }
  .rate-selected-value-3 {
    .rate-star-1,.rate-star-2,.rate-star-3 {
      color: #FBB739 !important
    }
  }
  .rate-selected-value-2 {
    .rate-star-1,.rate-star-2 {
      color: #FBB739 !important
    }
  }
  .rate-selected-value-1 {
    .rate-star-1 {
      color: #FBB739 !important
    }
  }
}
