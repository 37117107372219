.m22-box {
  &-light,
  &-dark {
    border-radius: 6px;
    margin-bottom: 16px;
  }
  &-light {
    background: #fff;
  }
  &-dark {
    & .box-order,
    & .white-box,
    & .border-card-header {
      @extend .m22-box-dark;
    }
    background: color(color-dark-component-bg);
    box-shadow: none !important;
    border: none;
  }
}

.anticon {
  &.success {
    color: #4dd69c;
  }

  &.warning {
    color: #fdb924;
  }

  &.info {
    color: #339dff;
  }
  &.error {
    color: #ff4559;
  }
}

.ant-popover-buttons {
  & > .ant-btn {
    &:hover {
      background: transparent;
    }
  }
}

.ant-pagination-item-active a {
  color: #1a1a1a !important;
}

.triangle {
  background-color: transparent;
  height: 50px;
  width: 50px;
  transform: rotate(90deg);
}
.triangle > path {
  fill: #f27734;
  stroke: #f27734;
  stroke-linejoin: round;
  stroke-width: 8px;
}

.dark-box {
  background: #1f2328;
  padding: 20px 38px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05), 0px 7px 24px rgba(17, 17, 17, 0.08);
}

.m22-border-base {
  border: 1px solid #c0c0c0 !important;
  &.ant-btn-default {
    border: 1px solid #c0c0c0 !important;
  }
}

.ant-empty-image {
  height: auto !important;
}

.float-label-tooltip {
  pointer-events: all;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

//selection
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px !important;
  // padding: 0 16px !important;
}

.ant-select-lg {
  font-size: 14px !important;
}

.ant-select-arrow {
  font-size: 16px !important;
}

// date-input
.ant-picker {
  border-radius: 8px !important;
}

// switch
.ant-switch {
  background-color: #c0c0c0 !important;
}

.ant-switch-checked {
  background-color: #fcd535 !important;
}

.ant-modal-footer {
  padding-bottom: 16px !important;
}

// table

.dark {
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #32373e;
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: #1f2328;
  }
  .ant-empty {
    color: white;
    font-weight: 400;
  }
}

// select
.ant-select-item.ant-select-item-option:not(:last-of-type) {
  border-bottom: none;
  padding: 10px 10px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fcd535 !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f6f6f6;
}

.ant-select-dropdown {
  padding: 0 !important;
}
.dark {
  .ant-select-dropdown {
    border-radius: 6px;
    padding: 10px 6px !important;
  }
  .ant-select-dropdown .ant-select-item:not(:last-child) {
    margin-bottom: 8px;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #4d545f !important;
  }
}
// input
.ant-input,
.ant-input-affix-wrapper {
  border-radius: 8px !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.ant-input.text-area-layout {
  padding-top: 8px;
}
.ant-picker {
  padding-left: 12px !important;
}
.ant-picker-range-separator {
  padding-left: 16px !important;
}

.ant-input-affix-wrapper {
  padding-left: 0 !important;
}

.dark {
  .ant-picker-suffix {
    color: #bdbdbd;
  }
}

// form
.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
  display: flex;
  align-items: center;
}

.dark-box {
  background: #1f2328;
  padding: 20px 38px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05), 0px 7px 24px rgba(17, 17, 17, 0.08);
}

.ant-steps-navigation {
  padding-bottom: 0 !important;
}

//badge and tag in form

// checkbox, radio
.ant-checkbox-inner {
  border-radius: 3px !important;
}

.dark {
  & .ant-radio.ant-radio-disabled .ant-radio-inner,
  & .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: #666666 !important;
  }
}
//date

.ant-picker-suffix {
  color: #7d7e7e !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: color(color-black-primary) !important;
}

// tooltip
.ant-tooltip-inner {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

// custom notification

.bg-color {
  &-success {
    background-color: #4dd69c !important;
  }
  &-warning {
    background-color: #fdc750 !important;
  }
  &-danger {
    background-color: #ff6a7a !important;
  }
}

.dark {
  &.header-list-type {
    &.active,
    &:hover {
      background-color: #4d545f;
      color: #fcd535;
      & > i {
        color: #fcd535;
      }
    }
    & > i {
      color: #666666;
    }
  }
}

// UX
.selector-selected {
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #c0c0c0 !important;
    box-shadow: none !important;
  }
}
.selector-focused {
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: color(color-primary) !important;
    box-shadow: none !important;
  }
}

.ant-radio-group {
  & .ant-radio-wrapper {
    &:hover {
      .ant-radio .ant-radio-inner {
        border-color: #fcd535;
      }
    }

    & .ant-radio-disabled .ant-radio-inner {
      border-color: #c0c0c0 !important;
    }
  }
}

.m22 {
  // ********************************************** M22 Typography **********************************************

  &-typo {
    &-money {
      @include typography(500, color(color-black-primary), 22, 14);
      &-plus {
        color: color(color-success);
      }
      &-minus {
        color: color(color-money-plus);
      }
    }

    // ********************************************** M22 Button **********************************************

    &-btn {
      & > i {
        margin-right: 6px;
      }
      &-rounded {
        &.ant-btn-default.m22-btn {
          &.dark {
            &:not(:disabled) {
              background: color(color-black-dark-row-hover);
              color: color(color-button-link-dark);
              border: none !important;
              &:hover {
                background: color(color-selection-active-dark) !important;
                color: color(color-button-link-dark);
              }
              &:focus {
                background: color(color-btn-outline-clicked-bg-dark) !important;
                color: color(color-button-link-dark) !important;
              }
            }

            &:disabled {
              border: none !important;
              color: color(color-disabled-primary-light) !important;
            }
          }
          background-color: color(color-gray-default);
          color: color(color-black-tertiary);
          &:not(:disabled) {
            &:hover {
              background-color: color(color-selection-selected);
            }
            &:focus {
              background-color: color(color-btn-rounded-clicked-bg);
            }
          }
          &:disabled {
            background: color(color-gray-default);
            border: 1px solid color(color-gray-hover);
            &:hover {
              background: color(color-gray-default);
            }
          }
        }

        &__blue {
          &.ant-btn-default.m22-btn {
            &.dark {
              background: none;
              border: 1px solid #fcd535;
              color: #fcd535;
              &:not(:disabled) {
                &:hover,
                &:focus {
                  border: 1px solid #fcd535;
                  color: #fcd535 !important;
                  background: transparent !important;
                }
              }
            }
            background-color: white;
            border: 1px solid transparent;
            color: color(color-blue-secondary);
            border: 1px solid #339dff;
            &:not(:disabled) {
              &:hover,
              &:focus {
                border: 1px solid #339dff;
                color: color(color-blue-secondary);
                background-color: transparent;
              }
            }
            // &:disabled {
            // 	background: color(color-gray-default);
            // 	border: 1px solid color(color-gray-hover);
            // 	&:hover {
            // 		background: color(color-gray-default);
            // 	}
            // }
          }
        }
        &__red {
          &.ant-btn-default.m22-btn {
            &.dark {
              background: none;
              border: 1px solid #ff4559 !important;
              color: #ff4559 !important;
              &:not(:disabled) {
                &:hover,
                &:focus {
                  border: 1px solid #ff4559;
                  color: #ff4559 !important;
                  background: transparent !important;
                }
              }
            }
            background-color: white;
            border: 1px solid transparent;
            color: #ff4559;
            border: 1px solid #ff4559 !important;
            &:not(:disabled) {
              &:hover,
              &:focus {
                border: 1px solid #ff4559 !important;
                color: #ff4559;
                background-color: transparent;
              }
            }
            // &:disabled {
            // 	background: color(color-gray-default);
            // 	border: 1px solid color(color-gray-hover);
            // 	&:hover {
            // 		background: color(color-gray-default);
            // 	}
            // }
          }
        }
      }

      &-outline {
        &.ant-btn-default.m22-btn {
          &.dark {
            &:not(:disabled) {
              background: transparent !important;
              border: 1px solid color(color-gray-tertiary) !important;
              color: color(color-button-link-dark) !important;
              &:hover {
                // color: color(color-primary) !important;
                color: #e6e6e6 !important;
                border: 1px solid #e6e6e6 !important;
                // color(color-primary) !important;
                background: transparent !important;
              }
              &:focus {
                // background: color(color-btn-outline-clicked-dark) !important;
                color: #fcd535 !important;
                border: 1px solid #fcd535 !important;

                // (color-black-primary) !important;
                background: transparent !important;
              }
            }

            &:disabled {
              border: 1px solid color(color-disabled-primary-dark) !important;
              color: color(color-disabled-primary-light) !important;
              background: transparent !important;
            }
          }
          background: white;
          border: 1px solid color(color-border-base);
          color: color(color-black-tertiary);

          &:not(:disabled) {
            &:hover {
              color: #363636;
              // color(color-primary);
              border: 1px solid #5e5e5e;
              // color(color-primary);
            }
            &:focus {
              // background-color: color(color-button-click);
              color: #fcd535;
              // color(color-black-tertiary);
              border: 1px solid #fcd535;
            }
          }

          &:disabled {
            border: none;
          }
        }
      }

      &-cancel {
        &.ant-btn-default.m22-btn {
          background-color: color(color-error-primary);
          color: white;
          border: 1px solid #ffb2ba;
          &:not(:disabled) {
            &:hover,
            &:focus {
              background-color: color(color-error-primary);
              color: white;
            }
          }
          &:disabled {
            background: color(color-gray-default);
            border: 1px solid color(color-gray-hover);
            &:hover {
              background: color(color-gray-default);
            }
          }
        }
      }

      &.ant-btn {
        border-radius: 4px;
        padding-left: 24px;
        padding-right: 24px;
        &-primary {
          &:not(:disabled) {
            @include removeShadowBtn();
            font-weight: 500;
            &:hover {
              // @include primaryHov();
              @include buttonHover();
            }
            &:focus {
              // @include focusedPrimary();
              @include buttonClick();
            }
          }

          &:disabled {
            @include disabledPrimary();
            &:hover {
              @include disabledPrimary();
            }
          }
        }

        &-default {
          //@include removeShadowBtn();
          border-color: #c0c0c0;
          color: color(color-default-btn);

          &:disabled {
            background-color: transparent;
            color: color(color-disabled);
            &:hover {
              background: none;
            }
          }
          &:not(:disabled) {
            &:hover {
              // background-color: color(color-default-hover-light);
              background-color: #ebebeb;
              color: color(color-black-tertiary);
              border-color: transparent;
            }
            &:active {
              background-color: #d9d9d9;
              color: color(color-black-tertiary);
            }
          }
        }

        &-text,
        &-link {
          &:not(:disabled) {
            color: color(color-btn-text);
            &:hover {
              color: color(color-black-quaternary);
              background-color: color(color-gray-default);
            }
          }

          &:disabled {
            color: color(color-disabled) !important;
          }
        }

        &-sm {
          padding-left: 12px;
          padding-right: 12px;
        }
      }
      &.m22-btn-outline-gray {
        color: #363636;
        background-color: #f6f6f6;
        &:hover {
          background-color: #f6f6f6;
        }
      }
      &.dark {
        &.ant-btn-primary[disabled],
        .ant-btn-primary[disabled]:hover,
        .ant-btn-primary[disabled]:focus,
        .ant-btn-primary[disabled]:active {
          border: none !important;
        }
        &.ant-btn-default {
          &:disabled {
            background: #32373e !important;
            color: #666666 !important;
          }
          &:not(:disabled) {
            border: 1px solid #fcd535;
            color: #fcd535;
            &:hover {
              border-color: transparent;
              // background: !important;
              color: #1a1a1a;
            }

            &:focus {
              background: color(color-btn-outline-clicked-dark);
              color: #1a1a1a;
              border-color: transparent !important;
            }
          }
        }

        &.ant-btn {
          &-text,
          &-link {
            &:not(:disabled) {
              background-color: color(color-disabled-primary-dark);
              color: color(color-button-link-dark);
              &:hover {
                // color: #fcd535 !important;
                background-color: color(color-selection-active-dark);
              }
            }

            &:disabled {
              color: #666666 !important;
            }
          }
          &-primary {
            &:disabled {
              background-color: #32373e !important;
              color: #666666 !important;
            }
          }
        }
        &.m22-btn-outline {
          border: 1px solid #5e5e5e;
          color: #e6e6e6;
          &.ant-btn {
            &-default:not(:disabled):focus {
              background-color: #f0c100;
            }
            &-primary:not(:disabled):focus {
              background-color: color(color-btn-outline-clicked-dark);
            }
          }
        }
        &.m22-btn-outline-gray {
          background: #32373e !important;
          color: #e6e6e6 !important;
          border-radius: 4px !important;
          &:hover {
            color: #e6e6e6 !important;
            background: #4d545f !important;
          }
          &.ant-btn-primary:not(:disabled):focus {
            background-color: color(color-btn-outline-clicked-bg-dark);
            color: #e6e6e6 !important;
          }
        }

        &-sm {
          padding-left: 12px;
          padding-right: 12px;
        }
      }

      &.dark {
        &.ant-btn-primary[disabled],
        .ant-btn-primary[disabled]:hover,
        .ant-btn-primary[disabled]:focus,
        .ant-btn-primary[disabled]:active {
          border: none !important;
        }
        &.ant-btn-default {
          &:disabled {
            background: #32373e !important;
            color: #666666 !important;
          }
          &:not(:disabled) {
            border: 1px solid #fcd535;
            color: #fcd535;
            &:hover {
              border-color: transparent;
              background: #ffeb97;
              color: #1a1a1a;
            }

            &:focus {
              background: color(color-btn-outline-clicked-dark);
              color: #1a1a1a;
              border-color: transparent !important;
            }
          }
        }

        &.ant-btn {
          &-text,
          &-link {
            &:not(:disabled) {
              background-color: color(color-disabled-primary-dark);
              color: color(color-button-link-dark);
              &:hover {
                // color: #fcd535 !important;
                background-color: color(color-selection-active-dark);
              }
            }

            &:disabled {
              color: #666666 !important;
            }
          }
          &-primary {
            &:disabled {
              background-color: #32373e !important;
              color: #666666 !important;
            }
          }
        }
        &.m22-btn-outline {
          border: 1px solid #5e5e5e;
          color: #e6e6e6;
          &.ant-btn {
            &-default:not(:disabled):focus {
              background-color: #f0c100;
            }
            &-primary:not(:disabled):focus {
              background-color: color(color-btn-outline-clicked-dark);
            }
          }
        }
        &.m22-btn-outline-gray {
          background: #32373e !important;
          color: #e6e6e6 !important;
          border-radius: 4px !important;
          border: none !important;
          &:hover {
            color: #e6e6e6 !important;
            background: #4d545f !important;
          }
          &.ant-btn-primary:not(:disabled):focus {
            background-color: color(color-btn-outline-clicked-bg-dark);
            color: #e6e6e6 !important;
          }
        }
      }

      &-popover-ok {
        &.dark.ant-btn-text:not(:disabled) {
          color: color(color-primary) !important;
          &:hover {
            background: transparent;
          }
        }
      }

      &-popover-cancel {
        &.dark.ant-btn-text:not(:disabled) {
          color: color(color-button-link-dark) !important;
          &:hover {
            background: transparent;
          }
        }
      }

      &-reset {
        color: color(color-black-secondary);
        cursor: pointer;
        white-space: nowrap;
        margin-right: 12px;
        font-size: 12px;
        line-height: 20px;
        &:hover {
          color: color(color-black-primary);
        }
      }

      &-popover-ok {
        &.dark.ant-btn-text:not(:disabled) {
          color: color(color-primary) !important;
          &:hover {
            background: transparent;
          }
        }
      }

      &-popover-cancel {
        &.dark.ant-btn-text:not(:disabled) {
          color: color(color-button-link-dark) !important;
          &:hover {
            background: transparent;
          }
        }
      }

      &-reset {
        color: color(color-black-secondary);
        cursor: pointer;
        white-space: nowrap;
        margin-right: 12px;
        font-size: 12px;
        line-height: 20px;
        &:hover {
          color: color(color-black-primary);
        }
        &.dark {
          &:hover {
            color: #e6e6e6;
          }
        }
      }
    }
    &-label {
      @include typography(500, color(color-black-primary), 22, 14);
      color: color(color-gray-secondary);

      &_gray {
        color: color(color-gray-quaternary);
        &-secondary {
          color: color(color-gray-tertiary);
        }
        &-tertiary-plus {
          color: color(color-gray-tertiary-plus);
        }
        &-primary {
          color: color(color-gray-quaternary-plus);
          &.dark {
            color: color(color-gray-quaternary-plus);
          }
        }
      }
      &_light {
        font-weight: 300;
      }
      &_reg {
        font-weight: 400;
      }

      &-sm {
        @include typography(400, color(color-black-primary), 20, 12);
        &.dark {
          color: color(color-gray-quaternary-plus);
        }
      }
      &_md {
        font-weight: 500;
        &-large {
          font-size: 16px;
          line-height: 24px;
        }
      }
      &_collapse {
        @include typography(400, color(color-black-primary), 20, 12);
        cursor: pointer;
        & > i {
          margin-right: 5px;
          line-height: 14px;
        }
      }

      &_upper {
        text-transform: capitalize;
      }

      &__black {
        &-menu {
          color: color(color-black-quaternary);
        }
      }
    }

    &-black {
      color: color(color-black-primary);
      &.dark.notice {
        color: color(color-primary);
      }
    }
    &-content {
      &.dark {
        color: color(color-button-link-dark);
      }
      @include typography(400, color(color-black-primary), 22, 14);
      &-blue {
        color: color(color-main-blue);
      }
      &-small {
        font-size: 10px;
      }
      &-md {
        font-weight: 500;
      }

      &-bold {
        font-weight: 700;
      }
      &_black-secondary {
        color: color(color-black-secondary);
      }
      &_status {
        font-weight: 500;
        color: white;
      }
    }

    &__white {
      color: white;
    }
    &-heading {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: color(color-black-primary);
      text-transform: capitalize;

      &_gray {
        color: color(color-gray-quaternary);
        font-size: 14px;
        line-height: 22px;
        &-secondary {
          color: color(color-gray-quaternary-plus);
        }
        &.dark {
          color: color(color-button-link-dark);
        }
      }
      &.dark {
        color: color(color-button-link-dark);
      }
    }
  }

  // ********************************************** M22 Button **********************************************

  &-btn {
    &-rounded {
      &.ant-btn-default.m22-btn {
        &.dark {
          &:not(:disabled) {
            background: color(color-black-dark-row-hover);
            color: color(color-button-link-dark);
            border: none !important;
            &:hover {
              background: color(color-selection-active-dark) !important;
              color: color(color-button-link-dark);
            }
            &:focus {
              background: color(color-btn-outline-clicked-bg-dark) !important;
              color: color(color-button-link-dark) !important;
            }
          }

          &:disabled {
            border: none !important;
            color: color(color-disabled-primary-light) !important;
          }
        }
        background-color: color(color-gray-default);
        color: color(color-black-tertiary);
        &:not(:disabled) {
          &:hover {
            background-color: color(color-selection-selected);
          }
          &:focus {
            background-color: color(color-btn-rounded-clicked-bg);
          }
        }
        &:disabled {
          background: color(color-gray-default);
          border: 1px solid color(color-gray-hover);
          &:hover {
            background: color(color-gray-default);
          }
        }
      }

      &__blue {
        &.ant-btn-default.m22-btn {
          &.dark {
            background: none;
            border: 1px solid #fcd535;
            color: #fcd535;
            &:not(:disabled) {
              &:hover,
              &:focus {
                border: 1px solid #fcd535;
                color: #fcd535 !important;
                background: transparent !important;
              }
            }
          }
          background-color: white;
          border: 1px solid transparent;
          color: color(color-blue-secondary);
          border: 1px solid #339dff;
          &:not(:disabled) {
            &:hover,
            &:focus {
              border: 1px solid #339dff;
              color: color(color-blue-secondary);
              background-color: transparent;
            }
          }
        }
      }
      &__red {
        &.ant-btn-default.m22-btn {
          &.dark {
            background: none;
            border: 1px solid #ff4559 !important;
            color: #ff4559 !important;
            &:not(:disabled) {
              &:hover,
              &:focus {
                border: 1px solid #ff4559;
                color: #ff4559 !important;
                background: transparent !important;
              }
            }
          }
          background-color: white;
          border: 1px solid transparent;
          color: #ff4559;
          border: 1px solid #ff4559 !important;
          &:not(:disabled) {
            &:hover,
            &:focus {
              border: 1px solid #ff4559 !important;
              color: #ff4559;
              background-color: transparent;
            }
          }
        }
      }
    }

    &-outline {
      &.ant-btn-default.m22-btn {
        &.dark {
          &:not(:disabled) {
            background: transparent !important;
            border: 1px solid color(color-gray-tertiary) !important;
            color: color(color-button-link-dark) !important;
            &:hover {
              color: #e6e6e6 !important;
              border: 1px solid #e6e6e6 !important;
              background: transparent !important;
            }
            &:focus {
              color: #fcd535 !important;
              border: 1px solid #fcd535 !important;

              // (color-black-primary) !important;
              background: transparent !important;
            }
          }

          &:disabled {
            border: 1px solid color(color-disabled-primary-dark) !important;
            color: color(color-disabled-primary-light) !important;
            background: transparent !important;
          }
        }
        background: white;
        border: 1px solid color(color-border-base);
        color: color(color-black-tertiary);

        &:not(:disabled) {
          &:hover {
            color: #363636;
            // color(color-primary);
            border: 1px solid #5e5e5e;
            // color(color-primary);
          }
          &:focus {
            // background-color: color(color-button-click);
            // color: #fcd535;
            // color(color-black-tertiary);
            // border: 1px solid #fcd535;
          }
        }

        &:disabled {
          border: none;
        }
      }
    }

    &-cancel {
      &.ant-btn-default.m22-btn {
        background-color: color(color-error-primary);
        color: white;
        border: 1px solid #ffb2ba;
        &:not(:disabled) {
          &:hover,
          &:focus {
            background-color: color(color-error-primary);
            color: white;
          }
        }
        &:disabled {
          background: color(color-gray-default);
          border: 1px solid color(color-gray-hover);
          &:hover {
            background: color(color-gray-default);
          }
        }
      }
    }

    &.ant-btn {
      border-radius: 4px;
      padding-left: 24px;
      padding-right: 24px;
      &-primary {
        &:not(:disabled) {
          @include removeShadowBtn();
          font-weight: 500;
          &:hover,&:focus {
            @include buttonHover();
          }
        }

        &:disabled {
          @include disabledPrimary();
          &:hover {
            @include disabledPrimary();
          }
        }
      }

      &-default {
        @include removeShadowBtn();
        border: 1px solid #c0c0c0;
        color: color(color-default-btn);

        &:disabled {
          background-color: transparent;
          color: color(color-disabled);
          &:hover {
            background: none;
          }
        }
        &:not(:disabled) {
          &:hover {
            // background-color: color(color-default-hover-light);
            //background: #fafafa;
            color: color(color-black-tertiary);
            border: 1px solid #c0c0c0;
            //font-weight: 500;
          }
          &:active {
            background-color: #d9d9d9;
            color: color(color-black-tertiary);
          }
        }
      }

      &-text {
        &:not(:disabled) {
          background: #f6f6f6;
          color: color(color-btn-text);
          font-weight: 500;

          &:hover {
            color: color(color-black-quaternary);
            background-color: #ebebeb;
            //font-weight: 500;
          }
        }

        &:disabled {
          color: color(color-disabled) !important;
        }
      }

      &-sm {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
    &.m22-btn-outline-gray {
      color: #363636;
      background-color: #f6f6f6;
      &:hover {
        background-color: #f6f6f6;
      }
    }
    &.dark {
      &.ant-btn-primary[disabled],
      .ant-btn-primary[disabled]:hover,
      .ant-btn-primary[disabled]:focus,
      .ant-btn-primary[disabled]:active {
        border: none !important;
      }
      &.ant-btn-default {
        &:disabled {
          background: #32373e !important;
          color: #666666 !important;
        }
        &:not(:disabled) {
          border: 1px solid #fcd535;
          color: #fcd535;
          &:hover {
            border-color: transparent;
            // background: !important;
            color: #1a1a1a;
          }

          &:focus {
            background: color(color-btn-outline-clicked-dark);
            color: #1a1a1a;
            border-color: transparent !important;
          }
        }
      }

      &.ant-btn {
        &-text,
        &-link {
          &:not(:disabled) {
            background-color: color(color-disabled-primary-dark);
            color: color(color-button-link-dark);
            &:hover {
              // color: #fcd535 !important;
              background-color: color(color-selection-active-dark);
            }
          }

          &:disabled {
            color: #666666 !important;
          }
        }
        &-primary {
          &:disabled {
            background-color: #32373e !important;
            color: #666666 !important;
          }
        }
      }
      &.m22-btn-outline {
        border: 1px solid #5e5e5e;
        color: #e6e6e6;
        &.ant-btn {
          &-default:not(:disabled):focus {
            background-color: #f0c100;
          }
          &-primary:not(:disabled):focus {
            background-color: color(color-btn-outline-clicked-dark);
          }
        }
      }
      &.m22-btn-outline-gray {
        background: #32373e !important;
        color: #e6e6e6 !important;
        border-radius: 4px !important;
        &:hover {
          color: #e6e6e6 !important;
          background: #4d545f !important;
        }
        &.ant-btn-primary:not(:disabled):focus {
          background-color: color(color-btn-outline-clicked-bg-dark);
          color: #e6e6e6 !important;
        }
      }

      &-sm {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    &.dark {
      &.ant-btn-primary[disabled],
      .ant-btn-primary[disabled]:hover,
      .ant-btn-primary[disabled]:focus,
      .ant-btn-primary[disabled]:active {
        border: none !important;
      }
      &.ant-btn-default {
        &:disabled {
          background: #32373e !important;
          color: #666666 !important;
        }
        &:not(:disabled) {
          border: 1px solid #fcd535;
          color: #fcd535;
          &:hover {
            border-color: transparent;
            background: #ffeb97;
            color: #1a1a1a;
          }

          &:focus {
            background: color(color-btn-outline-clicked-dark);
            color: #1a1a1a;
            border-color: transparent !important;
          }
        }
      }

      &.ant-btn {
        &-text,
        &-link {
          &:not(:disabled) {
            background-color: color(color-disabled-primary-dark);
            color: color(color-button-link-dark);
            &:hover {
              // color: #fcd535 !important;
              background-color: color(color-selection-active-dark);
            }
          }

          &:disabled {
            color: #666666 !important;
          }
        }
        &-primary {
          &:disabled {
            background-color: #32373e !important;
            color: #666666 !important;
          }
        }
      }
      &.m22-btn-outline {
        border: 1px solid #5e5e5e;
        color: #e6e6e6;
        &.ant-btn {
          &-default:not(:disabled):focus {
            background-color: #f0c100;
          }
          &-primary:not(:disabled):focus {
            background-color: color(color-btn-outline-clicked-dark);
          }
        }
      }
      &.m22-btn-outline-gray {
        background: #32373e !important;
        color: #e6e6e6 !important;
        border-radius: 4px !important;
        border: none !important;
        &:hover {
          color: #e6e6e6 !important;
          background: #4d545f !important;
        }
        &.ant-btn-primary:not(:disabled):focus {
          background-color: color(color-btn-outline-clicked-bg-dark);
          color: #e6e6e6 !important;
        }
      }
    }

    &-popover-ok {
      &.dark.ant-btn-text:not(:disabled) {
        color: color(color-primary) !important;
        &:hover {
          background: transparent;
        }
      }
    }

    &-popover-cancel {
      &.dark.ant-btn-text:not(:disabled) {
        color: color(color-button-link-dark) !important;
        &:hover {
          background: transparent;
        }
      }
    }

    &-reset {
      color: color(color-black-secondary);
      cursor: pointer;
      white-space: nowrap;
      margin-right: 12px;
      font-size: 12px;
      line-height: 20px;
      &:hover {
        color: color(color-black-primary);
      }
    }

    &-popover-ok {
      &.dark.ant-btn-text:not(:disabled) {
        color: color(color-primary) !important;
        &:hover {
          background: transparent;
        }
      }
    }

    &-popover-cancel {
      &.dark.ant-btn-text:not(:disabled) {
        color: color(color-button-link-dark) !important;
        &:hover {
          background: transparent;
        }
      }
    }

    &-reset {
      color: color(color-black-secondary);
      cursor: pointer;
      white-space: nowrap;
      margin-right: 12px;
      font-size: 12px;
      line-height: 20px;
      &:hover {
        color: color(color-black-primary);
      }
      &.dark {
        &:hover {
          color: #e6e6e6;
        }
      }
    }
  }
  // ********************************************** M22 Tag **********************************************

  &-badge {
    @include typography(400, color(color-black-primary), 20, 12);
    display: flex;
    align-items: center;
    padding: 2px 16px;
    font-weight: 500;
    border-radius: 16px;
    border: none !important;
    margin: 0;
    white-space: nowrap;
    cursor: pointer;
    margin-right: 12px;
    margin-bottom: 12px;
    background-color: color(color-selection-active);
    color: color(color-black-secondary);

    &:hover {
      background-color: color(color-selection-selected);
    }
    &.active {
      background-color: color(color-primary);
      color: color(color-black-primary);
    }
    &.included,
    &.include:hover {
      border: 1px solid color(color-primary) !important;
      box-shadow: 0px 0px 0px 2px rgba(255, 235, 151, 0.4) !important;
      background-color: transparent !important;
      color: #1a1a1a !important;
    }

    &.dark {
      background-color: color(color-disabled-primary-dark);
      color: #e6e6e6;
      &:hover {
        background-color: #4d545f;
      }
      &.active {
        background-color: #fcd535;
        color: #1a1a1a;
      }
    }
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  // ********************************************** M22 Table **********************************************
  &-empty {
    & .ant-empty-description {
      color: color(color-disabled);
    }

    & .ant-empty-image {
      margin-bottom: 12px;
      &>img{
        width: 120px;
      }
    }
  }

  &-table {
    &.orders {
      & .ant-table {
        & table {
          border-spacing: 0;
          & .ant-table-row {
            &:nth-child(1n) > td {
              &:first-child {
                border-radius: 12px 0 0 0 !important; 
              }
              &:last-child {
                border-radius: 0 12px 0 0 !important;
              }
            }

            &.data-row-extended > td:last-child {
              border-radius: 0 0 12px 12px !important;
            }
          }
        }
      }

      &.dark {
        & .ant-table table .ant-table-row {
          &.row-dark > td {
            background-color:  var(--bg-table-triped);
          }
          &:nth-child:hover {
            .row-dark > td {
              background-color: #32373e !important;
            }
            & > td {
              background-color: #32373e !important;
            }
          }
        }
      }
    }

    &.nested {
      & .ant-table {
        & table {
          border-spacing: 0;
        }
      }
    }
    &.delivery-create {
      & .ant-table {
        .ant-table-tbody {
          .ant-table-cell.ant-table-selection-column {
            padding-left: 16px;
          }
        }
        .ant-table-selection-column {
          padding-left: 14px !important;
        }
        .ant-table-expanded-row > td {
          padding: 2px;
          border-color: var(--border-checked-color);

          &:nth-child(1) {
            border-radius: 0 0 0 0;
            border-left: 1px solid var(--border-checked-color);
          }
          &:last-child {
            border-radius: 0 0 12px 12px;
            border-right: 1px solid var(--border-checked-color);
          }
        }
        .ant-table-expanded-row-level-1 > td::before {
          border-color: var(--border-checked-color);
        }
        &.ant-table-title {
          padding: 0 !important;
        }
        & .ant-table-tbody > tr.ant-table-row-selected > td {
          border: 0px solid transparent;
          background: none;
        }
        & .ant-table-content {
          & .active-row {
            td {
              border-top: 1px solid var(--border-checked-color) !important;
              &:nth-child(1) {
                border-radius: 12px 0 0 0;
                border-left: 1px solid var(--border-checked-color);
              }
              &:last-child {
                border-radius: 0 12px 0 0 !important;
                border-right: 1px solid var(--border-checked-color);
              }
            }
          }
        }
      }
    }
    &.package {
      &.dark {
        & .ant-table {
          & .ant-table-thead {
            & > tr > th {
              &:nth-child(1) {
                border-radius: 6px 0 0 0;
              }
            }
          }

          & .ant-table-content {
            & .ant-table-expanded-row-level-1 {
              position: relative;
              & > td {
                border: 1px solid #5e5e5e;
                border-top: none;
                border-radius: 0 0 12px 12px;
                padding-top: 4px;
                &::before {
                  content: '';
                  width: calc(100% + 2px);
                  height: 14px;
                  // background-color: color(color-nested-table-content-bg);
                  background-color: #32373e;
                  position: absolute;
                  top: -12px;
                  left: -1px;
                  border: 1px solid #5e5e5e;
                  border-top: none;
                  border-bottom: none;
                }
              }
            }

            & .ant-table-row > td {
              border: 1px solid transparent;

              // padding-bottom: 6px;
              &:nth-child(1) {
                border-radius: 12px 0 0 12px;
              }
            }
            & .active-row {
              td {
                border-top: 1px solid #5e5e5e;

                &:nth-child(1) {
                  border-radius: 12px 0 0 0 !important;
                  border-left: 1px solid #5e5e5e;
                }
                &:last-child {
                  border-radius: 0 12px 0 0 !important;
                  border-right: 1px solid #5e5e5e;
                }
              }
            }
          }
        }
      }
      & .ant-table {
        & .ant-table-thead {
          & > tr > th {
            &:nth-child(1) {
              border-radius: 6px 0 0 0;
            }
          }
        }

        & .ant-table-content {
          & .ant-table-expanded-row-level-1 {
            position: relative;
            & > td {
              border: 1px solid color(color-primary);
              border-top: none;
              border-radius: 0 0 12px 12px;
              padding-top: 4px;
              &::before {
                content: '';
                width: calc(100% + 1.5px);
                height: 14px;
                background-color: color(color-nested-table-content-bg);
                position: absolute;
                top: -12px;
                left: -1px;
                border: 1px solid color(color-primary);
                border-top: none;
                border-bottom: none;
              }
            }
          }
          & .ant-table-row > td {
            border: 1px solid transparent;
            padding: 16px;
            // padding-bottom: 6px;
            &:nth-child(1) {
              border-radius: 12px 0 0 12px;
            }
          }
          & .active-row {
            td {
              border-top: 1px solid #fcd535;

              &:nth-child(1) {
                border-radius: 12px 0 0 0 !important;
                border-left: 1px solid #fcd535;
              }
              &:last-child {
                border-radius: 0 12px 0 0;
                border-right: 1px solid #fcd535;
              }
            }
          }
        }
      }
    }

    &.delivery {
      &.dark {
        & .ant-table {
          & .ant-table-thead {
            & > tr > th {
              &:nth-child(2) {
                border-radius: 6px 0 0 0 !important;
              }
            }
          }

          & .ant-table-content {
            & .ant-table-expanded-row-level-1 {
              position: relative;
              & > td {
                border: 1px solid #5e5e5e;
                border-top: none;
                border-radius: 0 0 12px 12px;
                padding-top: 8px;
                &::before {
                  content: '';
                  width: calc(100% + 2px);
                  height: 14px;
                  // background-color: color(color-nested-table-content-bg);
                  background-color: #32373e;
                  position: absolute;
                  top: -12px;
                  left: -1px;
                  border: 1px solid #5e5e5e;
                  border-top: none;
                  border-bottom: none;
                }
              }
            }
            & .ant-table-row > td {
              // padding-bottom: 6px;
              &:nth-child(1) {
                border-radius: 12px 0 0 12px;
              }
            }
            & .active-row {
              td {
                border-top: 1px solid #5e5e5e;

                &:nth-child(2) {
                  border-radius: 12px 0 0 0 !important;
                  border-left: 1px solid #5e5e5e;
                }
                &:last-child {
                  border-radius: 0 12px 0 0;
                  border-right: 1px solid #5e5e5e;
                }
              }
            }
          }
        }
      }
      & .ant-table {
        & .ant-table-thead {
          & > tr > th {
            &:nth-child(1) {
              border-radius: 6px 0 0 0;
            }
          }
        }

        & .ant-table-content {
          & .ant-table-expanded-row-level-1 {
            position: relative;
            & > td {
              border: 1px solid color(color-primary);
              border-top: none;
              border-radius: 0 0 12px 12px;
              padding-top: 8px;
              &::before {
                content: '';
                width: calc(100% + 2px);
                height: 14px;
                background-color: color(color-nested-table-content-bg);
                position: absolute;
                top: -12px;
                left: -1px;
                border: 1px solid color(color-primary);
                border-top: none;
                border-bottom: none;
              }
            }
          }
          & .ant-table-row > td {
            // padding-bottom: 6px;
            &:nth-child(2) {
              border-radius: 12px 0 0 12px !important;
            }
          }
          & .active-row {
            td {
              border-top: 1px solid #fcd535;

              &:nth-child(2) {
                border-radius: 12px 0 0 0 !important;
                border-left: 1px solid #fcd535;
              }
              &:last-child {
                border-radius: 0 12px 0 0;
                border-right: 1px solid #fcd535;
              }
            }
          }
        }
      }
    }
    & .ant-table {
      table {
        border-spacing: 0 12px;
      }
      & .ant-table-thead {
        & > tr > th {
          // @include typography(500, color(color-gray-default), 20, 12);
          background-color: color(color-gray-default);
          border-bottom: none;
          white-space: nowrap;
          padding: 6px 16px;
          &::before {
            display: none !important;
          }
          &:first-child {
            border-radius: 6px 0 0 0 !important;
          }
          &:last-child {
            border-radius: 0 6px 0 0 !important;
          }
        }
      }
      & .ant-table-row {
        & > td {
          border-bottom: none;
          padding: 16px 16px;
          border: 0px solid transparent;
          &:first-child {
            border-radius: 12px 0 0 12px;
          }
          &:last-child {
            border-radius: 0 12px 12px 0px;
          }
        }
      }
      // }
    }

    &.dark {
      & .ant-table {
        & .ant-table-thead {
          & > tr > th {
            background-color: color(color-disabled-primary-dark) !important;
          }
        }
        & .ant-table-row {
          & > td {
            border-bottom: none;
          }
        }
      }
    }

    &-address {
      & .ant-table-title {
        padding-top: 0;
        padding-bottom: 24px;
      }
    }
  }

  // ********************************************** M22 Modal **********************************************
  &-modal {
    & .ant-modal-close .ant-modal-close-x {
      color: color(color-black-secondary);
      &:hover {
        color: color(color-black-primary);
      }
      font-size: 14px;
      line-height: 16px;
    }

    & .ant-modal-content {
      & .ant-modal {
        &-body {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    &.dark {
      &._address-table-custom {
        & .ant-modal-content {
          & .ant-modal-header,
          & .ant-table-body {
            background-color: #1f2328;
          }
          background-color: #1f2328;
        }
      }
      & .ant-modal-close .ant-modal-close-x {
        &:hover {
          color: color(color-button-link-dark);
        }
      }
    }

    & .ant-modal-title {
      font-size: 16px;
      line-height: 22px;
    }
  }

  // ********************************************** M22 Unit style **********************************************
  &-component-active {
    background-color: color(color-primary);
    color: color(color-black-primary);
  }
  &-vertical-tab-radius {
    border-radius: 6px 0px 0px 6px;
  }
  &-tag {
    &.ant-tag {
      border-radius: 16px;
      border: none;
      padding: 0 8px;
      line-height: 16px;
      font-size: 10px;
      &-blue {
        background-color: color(color-main-blue);
        color: white;
      }
    }
  }
  &-form-item {
    &_mg0 {
      margin-bottom: 0 !important;
    }
  }

  // ********************************************** M22 tags **********************************************

  &-tag {
    border-radius: 16px;
    padding: 0 12px;
    border: 1px solid transparent;
    cursor: pointer;
    vertical-align: middle;
    white-space: nowrap;
    &-status {
      border-radius: 25px;
      font-weight: 400;
      color: white;
      padding: 6px 16px;
      white-space: nowrap;
    }
    &-large {
      height: 24px;
      padding: 2px 12px;
    }

    &-small {
      height: 16px;
      padding: 0 8px;
      line-height: 14px;
      font-size: 10px;
    }
    height: 20px;
    font-size: 12px;
    &-main {
      background-color: color(color-selection-active);
      color: color(color-black-secondary);

      &:hover {
        background-color: color(color-selection-selected);
      }
    }
    &.dark {
      &.m22-tag-main {
        background-color: color(color-disabled-primary-dark);
        color: #e6e6e6;
        &:hover {
          background-color: #4d545f;
        }
      }
    }

    &-info {
      border: 1px solid color(color-blue-tertiary);
      color: color(color-blue-secondary);
      background-color: color(color-blue-quaternary);
      &-primary {
        color: white;
        background-color: color(color-blue-secondary);
      }
    }
    &-success {
      &-default {
        background-color: color(color-green-primary);
        border: 1px solid color(color-green-tertiary);
        color: color(color-green-secondary);
      }

      &-primary {
        color: white;
        background-color: color(color-green-secondary);
      }
    }
    &-warning {
      &-default {
        background-color: color(color-warning-secondary);
        border: 1px solid color(color-warning-tertiary);
        color: color(color-warning-primary);
      }

      &-primary {
        color: white;
        background-color: color(color-warning-primary);
      }
    }
    &-error {
      &-default {
        background-color: color(color-error-tertiary);
        border: 1px solid color(color-error-secondary);
        color: color(color-error-primary);
      }

      &-primary {
        color: white;
        background-color: color(color-error-primary);
      }
    }
  }

  // ********************************************** M22 Input **********************************************
  &-input {
    &.ant-input-lg {
      line-height: 25px;
    }

    &.ant-input-affix-wrapper {
      height: 44px;
      & .ant-input-suffix {
        color: color(color-gray-tertiary-plus);
      }
    }
  }
  // ********************************************** M22 pagination **********************************************

  &-pagination {
    display: flex;

    &.customized {
      & .ant-pagination-total-text {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    & .ant-pagination-total-text {
      margin-right: auto;
      order: -2;
      color: color(color-black-secondary);
    }
    & .ant-pagination-options {
      order: -1;
      margin-right: 10px;
    }

    //.ant-pagination-item:hover,
    //.ant-pagination-prev:hover .ant-pagination-item-link,
    //.ant-pagination-next:hover .ant-pagination-item-link {
    //  background-color:#4d545f;
    //  border-color: #4d545f;
    //  color: #1a1a1a;
    //  & a {
    //    color: #1a1a1a;
    //  }
    //}

    & .ant-pagination-item-active:hover {
      background: #fcd535;
      border-color: #fcd535;
    }

    //li.ant-pagination-disabled button.ant-pagination-item-link,
    //li.ant-pagination-disabled:hover button.ant-pagination-item-link {
    //  background: #EBEBEB;
    //  border: 1px solid #c0c0c0;
    //  color: #b1b1b1 !important;
    //}

    &.dark {

      .ant-pagination-item,
      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        border: none;
      }
      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        background-color: #4d545f;
      }

      & .ant-pagination-item:hover,
      .ant-pagination-prev:hover .ant-pagination-item-link,
      .ant-pagination-next:hover .ant-pagination-item-link {
        background-color: #4d545f !important;
        color: white;
        & a {
          color: white;
        }
      }
      //& .ant-pagination-disabled .ant-pagination-item-link,
      //& .ant-pagination-disabled:hover .ant-pagination-item-link {
      //  border: 1px solid #4d545f !important;
      //  background-color: #1f2328 !important;
      //  color: #666666 !important;
      //}

      .ant-pagination-options {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: none !important;
          background-color: #32373e;
          box-shadow: none !important;
        }
      }

      & .ant-pagination-item-active:hover {
        background-color: #fcd535 !important;
        border-color: #fcd535;
      }
    }
  }
  // ********************************************** M22 Step **********************************************

  &-steps {
    &.dark {
      // background-color: color(color-default-btn);
      & .m22-step {
        background: url('../../images/steps/step-default-dark-left-middle.svg') left top no-repeat;
        &_content {
          background: url('../../images/steps/step-default-midle-dark.svg') left top repeat-x;
        }
        &-right {
          background: url('../../images/steps/step-default-last-dark.svg') right top no-repeat;
        }

        &:last-child {
          & .m22-step-right {
            background: url('../../images/steps/step-last-dark-child-1.svg') right top no-repeat;
          }
          &.active {
            & .m22-step-right {
              background: url('../../images/last-step-active-r.png') right top no-repeat;
            }

            & .m22-step_content {
              margin-right: 20px;
            }
          }
        }
      }
    }
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    & .m22-step {
      cursor: pointer;
      width: calc(100% / 3);
      background: url('../../images/steps/step-default-left.svg') left top no-repeat;
      &:not(:first-child) {
        cursor: pointer;
      }
      &:first-child {
        &.active {
          background: url('../../images/steps/step-active-first.svg') left top no-repeat;
          & .step-right {
            background: url('../../images/steps/step-active-last.svg') right top no-repeat;
            & .step_content {
              margin-left: 12px;
              background: url('../../images/steps/step-active-main.svg') left top repeat-x;
            }
          }
        }
      }
      &.active {
        background: url('../../images/steps/step-active-left.svg') left top no-repeat;
        & .m22-step-right {
          background: url('../../images/steps/step-active-last.svg') right top no-repeat;
          & .m22-step_content {
            //margin-left: 0;
            background: url('../../images/steps/step-active-main.svg') left top repeat-x;
          }
        }
      }

      &-right {
        background: url('../../images/steps/step-default-last-middle.svg') right top no-repeat;
      }

      &.active {
        & .m22-step_content > span {
          color: #1a1a1a;
        }
      }

      &_content {
        background: url('../../images/steps/step-default-middle.svg') left top repeat-x;
        // height: 56px;
        margin-right: 20px;
        padding: 16px 24px;
        margin-left: 16px;
        // &:hover {
        // 	& > span {
        // 		color: #1a1a1a;
        // 	}
        // }
        & > span {
          color: #666666;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          // height: 56px;
        }
      }

      &:last-child {
        & .m22-step-right {
          background: url('../../images/steps/step-default-last.svg') right top no-repeat;
        }
        &.active {
          & .m22-step-right {
            background: url('../../images/steps/step-active-middle.svg') right top no-repeat;
          }

          & .m22-step_content {
            margin-right: 20px;
          }
        }
      }

      &-animate {
        transition: opacity 0.3s ease;
      }

      &-fade {
        &-enter {
          &-active {
            animation: openStep 0.5s ease forwards;
          }
        }
        &-exit {
          &-exit-active {
            animation: closeStep 0.3s ease forwards;
          }
        }
      }
    }
  }
  // ********************************************** M22 tabs **********************************************
  &-tabs {
    & .ant-tabs-nav-list {
      height: 32px;
    }
    &.dark {
      & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $primary-color;

        &:focus {
          color: $primary-color !important;
        }
      }
      &.ant-tabs-tab:hover {
        & .ant-tabs-tab-btn {
          color: color(color-gray-quaternary-plus) !important;
        }
        background-color: color(color-selection-active-dark);
      }
      & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
        color: color(color-primary) !important;
      }
      & .ant-tabs-tab .ant-tabs-tab-btn:hover {
        color: color(color-gray-quaternary-plus) !important;
      }
      &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
        background-color: color(color-black-dark-row-hover);
        color: color(color-disabled-primary-light);
        &:hover {
          background-color: color(color-selection-active-dark);
          & .ant-tabs-tab-btn {
            color: color(color-gray-quaternary-plus);
          }
        }
      }

      &.ant-tabs-card {
        & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
          color: color(color-black-quaternary) !important;
        }
        & > .ant-tabs-nav {
          & .ant-tabs {
            &-tab {
              &-active {
                background-color: color(color-primary);
                & > * {
                  color: color(color-black-quaternary) !important;
                }
              }
            }
          }
        }
      }
      & > .ant-tabs-nav {
        &::before {
          border-color: color(color-black-dark-row-hover) !important;
        }
        & .ant-tabs {
          &-tab {
            color: color(color-disabled-primary-light);
            &-active {
              border-bottom: none !important;
              & * {
                &.ant-badge * {
                  color: color(color-black-primary);
                }
              }
            }
          }
        }
      }
    }

    & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #5a5a5a;
    }
    & .ant-tabs-tab .ant-tabs-tab-btn:hover {
      color: #1a1a1a;
    }
    & > .ant-tabs-nav {
      &::before {
        border-bottom: 1px solid color(color-tab-border-light) !important;
      }
      .ant-tabs-tab {
        padding-left: 16px !important;
        padding-right: 16px !important;
        color: color(color-gray-tertiary-plus);
      }
    }

    &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
      border-radius: 6px 6px 0 0;
    }
    &.ant-tabs-card {
      & .ant-tabs-nav .ant-tabs-tab {
        border: none !important;
      }
      &.ant-tabs {
        &-top {
          & .ant-tabs-nav-wrap {
            margin-bottom: 1px;
          }
          & .ant-tabs-tab {
            border-radius: 6px 6px 0 0px !important;
          }
          & .ant-tabs-nav .ant-tabs-tab-active {
            border-bottom-color: transparent !important;
          }
        }
        &-left {
          & .ant-tabs-nav-wrap {
            margin-right: 1px;
          }
          & .ant-tabs-tab {
            border-radius: 6px 0 0 6px !important;
          }
          & .ant-tabs-nav .ant-tabs-tab-active {
            border-right-color: transparent !important;
          }
        }

        &-right {
          & .ant-tabs-nav-wrap {
            margin-left: 1px;
          }
          & .ant-tabs-tab {
            border-radius: 0 6px 6px 0 !important;
          }
          & .ant-tabs-nav .ant-tabs-tab-active {
            border-left-color: transparent !important;
          }
        }
        &-bottom {
          & .ant-tabs-nav-wrap {
            margin-top: 1px;
          }
          & .ant-tabs-tab {
            border-radius: 0 0 6px 6px !important;
          }
          & .ant-tabs-nav .ant-tabs-tab-active {
            border-top-color: transparent !important;
          }
        }
      }
      & > .ant-tabs-nav {
        & .ant-tabs-tab {
          background: color(color-gray-default);
          &.ant-tabs-tab-active {
            background: #fcd535 !important;
            & > * {
              color: #1a1a1a !important;
            }
          }
        }
      }
    }
  }
}

// ********************************************** M22 Unit override don't need import class **********************************************

.ant-input-affix-wrapper-lg {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.ant-picker-input {
  margin-left: 6px;
}
.ant-picker-range-separator {
  color: #b1b1b1 !important;
  margin-right: 6px;
}
.ant-input-lg {
  font-size: 14px !important;
  height: 42px;
  // line-height: 44px !important;
}

.ant-select-dropdown {
  border-radius: 8px;
  border-radius: 6px;
  padding: 10px 6px !important;
  & .ant-select-item {
    border-radius: 6px;
    padding: 10px 10px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  padding-left: 8px !important;
}

.ant-select-selection-item {
  padding-left: 8px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: color(color-black-primary) !important;
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder > td {
  border-bottom: none !important;
  padding-bottom: 4px;
}

.ant-scroll-number-only-unit .current {
  font-weight: 500;
}

a:link,
a:visited {
  &:hover, &:focus {
    color: var(--color-primary);
  }
}

.ant-input-number-lg input {
  height: 44px !important;
}

.ant-card.dark {
  & .ant-card-body {
    background-color: color(color-dark-component-bg);
  }
}

.dark {
  .react-loading-skeleton {
    background-color: rgba(66, 66, 66, 0.253);
    --highlight-color: rgba(255, 255, 255, 0.16);
    --base-color: rgba(66, 66, 66, 0.63);
  }
}

//************************************************ Material UI *****************************************
.float-label {
  position: relative;
  width: 100%;
  & .label,
  & .label-range {
    z-index: 1;
    position: absolute;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: color(color-gray-tertiary-plus);
    top: 50%;
    transform: translate(0, -50%);
    left: 16px;
    pointer-events: none;
    padding: 0 3px;
    transition: all 0.25s ease-in-out;
    &-float {
      top: -12px !important;
      transform: translate(0, 0);
      background-color: white;
      transition: all 0.25s ease-in-out;
      padding: 0 3px;
      font-size: 12px;
      z-index: 2;
      &.area-label {
        top: -12px;
      }
    }
    &.area-label {
      top: 19.5px;
    }
  }

  & .label-range,
  & .label-in-range {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    max-width: calc(50% - 32px);
  }

  &.dark {
    & .label,
    & .label-range {
      &-float {
        background-color: #1f2328;
        color: color(color-gray-quaternary-plus);
      }
    }

    & .ant-select-arrow {
      color: color(color-gray-quaternary-plus) !important;
    }
  }

  & .label-range {
    left: calc(50% + 12px);
  }
}

.heading-data-type {
  background-color: transparent;
  border-radius: 4px;
  // margin-right: 6px;
  width: 26px;
  height: 26px;
  // line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & i {
    color: var(--text-icon-heading-default);
    font-size: 16px;
    line-height: 16px;
    padding: 4px;
  }
  &.active {
    & i {
      color: var(--text-active);
      &:hover {
        color: var(--text-active);
      }
    }
  }
  &:hover {
    & i {
      color: var(--text-icon-heading-hover);
    }
  }
}

.ant-picker-large .ant-picker-input > input {
  font-size: 14px !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #fcd535 !important;
  color: #1a1a1a !important;
}
.ant-picker-time-panel-column {
  overflow: auto !important;
}

.dark {
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    background: #32373E!important;
    border: 1px solid #32373E!important;
    color: #b1b1b1 !important;
  }

  & .ant-pagination li.ant-pagination-item:not( .ant-pagination-item-active):hover,
  li.ant-pagination-prev:not(:disabled):hover button.ant-pagination-item-link,
  li.ant-pagination-next:not(:disabled):hover button.ant-pagination-item-link {
    background-color: #4d545f!important;
    border-color: #4d545f!important;
    color: #e6e6e6;
    a {
      color: #e6e6e6!important;
    }
  }

  .ant-pagination-disabled, .ant-pagination-disabled:hover {
    cursor: not-allowed;
    background-color: #4d545f!important;
  }
}

.light {
  li.ant-pagination-disabled button.ant-pagination-item-link,
  li.ant-pagination-disabled:hover button.ant-pagination-item-link {
    background: #EBEBEB!important;
    border: 1px solid #c0c0c0!important;
    color: #b1b1b1 !important;
  }
}
