@import '/src/resources/styles/helpers/variables';

.success-view-custom {
	background: #fff;
	width: 100%;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	margin-top: 32px;
	
	&.dark {
		background-color: #1F2328;
	}
}

.table-custom {
	thead > tr > th {
		background-color: #ebebeb;
		padding: 8px 16px;
	}
}

.break-word {
	word-break: break-word;
}

.whitespace-pre-wrap {
	white-space: pre-wrap;
}
._btn-order-manager {
	border-radius: 4px !important;
	border: 1px solid #ffd7a5 !important;
	height: 42px !important;
	background-color: #ffffff !important;
	color: #ffa634 !important;
	&._btn-order-manager:hover {
		color: white !important;
		background-color: #ffa634 !important;
	}
}

.success-shopping-cart {
	height: fit-content;
	display: flex;
	align-items: center;
	flex-direction: column;

	& .ant-result-icon {
		margin-bottom: 0;
	}

	& .ant-result-title {
		font-weight: 500;
		font-size: 30px;
		line-height: 24px;
		margin-top: 24px;
		color: $darker;
	}

	.ant-result-icon {
		img {
			width: 240px;
			height: auto;
		}
	}
	& .ant-result-extra {
		margin-top: 32px;

		button {
			padding-left: 92px;
			padding-right: 92px;
		}
	}

	&.dark {
		background-color: #1F2328;
	}
}
