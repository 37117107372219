.ant-typography {
    margin-bottom: 0;
    &.editing {
        left: 0 !important;
        & > textarea {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.whitespace-nor-impt {
    white-space: normal !important;
}
.collapse-icon {
    position: absolute;
    bottom: 0;
    right: 0;
}

.truncate-text {
    position: relative;
}