.package-data {
	tr {
		> td {
			padding: 5px 16px 11px 16px;
			&:first-of-type {
				padding-left: 12px !important;
			}
		}
	}
}

.ant-steps-item-tail {
	top: 7px !important;
}

