._order-item {
	background-color: #ffffff;
	border-color: #ffffff;
	align-items: center;
	&:hover {
		background-color: #f6f6f6 !important;
		cursor: pointer;
	}

	&.dark {
		background-color: #1f2328;
		border: none;
		&:hover {
			background-color: #32373e !important;
		}
	}
}

.row-dark-light-mode {
	background-color: var(--bg-table-triped);
}

.theme-row-dark {
	background-color: #252a2f !important;
}

