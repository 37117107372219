.item-selected {
  background-color: #ffeed9;
  border-radius: 4px;
}

.steps {
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.preview-extension {
  background: url('../../resources/images/extension-preview.png') left top no-repeat;
  background-size: cover;
  width: 100%;
  height: 174px;
}

.line-step {
  height: 4px;
  //border-radius: 4px;
  margin-right: 24px;

  &_child {
    margin-right: 0;
    width: 46.74px;
    //border-radius: 4px;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &.light {
      background: #ebebeb;
    }

    &.dark {
      background-color: #5e5e5e;
    }

    &.active {
      border-left: 1px solid #fcd535;
      background: #fcd535;
    }
  }
}

.box-market {
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //box-shadow: 0px 2px 14px #e6e6e6;
  border: 1px solid #5e5e5e;
  border-radius: 16px;
  padding: 16px;
  flex-wrap: nowrap;
  justify-content: space-between;
  cursor: pointer;
  & > button {
    width: 100%;
  }
  transition: transform 0.3s ease;

  &.light {
    box-shadow: 0 2px 14px #e6e6e6;
    border: 1px solid transparent;
  }

  &:hover {
    background-color: #32373e;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.4);
    transform: scale(1.0469);
    //background: #fff9df;
    .m22-btn.ant-btn-text:not(:disabled):hover {
      background-color: #fff9df !important;
    }

    &.dark {
      box-shadow: none;
      &:hover {
        box-shadow: none;
        color: #fcd535;
        //background-color: transparent;
        .m22-btn.ant-btn-text:not(:disabled):hover {
          background-color: #32373e !important;
        }
        .brand-name {
          color: #ffffff;
        }
      }
    }

    &.light {
      box-shadow: none;
      &:hover {
        box-shadow: none;
        //color:#FCD535;
        background: #fff9df;
        .m22-btn.ant-btn-text:not(:disabled):hover {
          background-color: #32373e !important;
        }
      }
    }

    &.dark {
      box-shadow: none;
      &:hover {
        box-shadow: none;
        color: #fcd535;
        //background-color: transparent;
        .m22-btn.ant-btn-text:not(:disabled):hover {
          background-color: #32373e !important;
        }
      }
    }
  }
}

.step {
  width: calc(100% / 3);
  background: url('../../resources/images/step-default-l.png') left top no-repeat;
  cursor: pointer;
  &:first-child {
    &.active {
      background: url('../../resources/images/step-active-l.png') left top no-repeat;
      & .step-right {
        background: url('../../resources/images/step-active-r.png') right top no-repeat;
        & .step_content {
          margin-left: 12px;
          background: url('../../resources/images/main-active.png') left top repeat-x;
        }
      }
    }
  }
  &.active {
    background: url('../../resources/images/mid-step-active-l.png') left top no-repeat;
    & .step-right {
      background: url('../../resources/images/step-active-r.png') right top no-repeat;
      & .step_content {
        margin-left: 12px;
        background: url('../../resources/images/main-active.png') left top repeat-x;
      }
    }
  }

  &-right {
    background: url('../../resources/images/step-default-r.png') right top no-repeat;
  }

  &.active {
    & .step_content > span {
      color: #1a1a1a;
    }
  }

  &_content {
    background: url('../../resources/images/step-mid-default.png') left top repeat-x;
    height: 56px;
    margin-right: 21px;
    padding: 16px 24px;
    margin-left: 16px;
    & > span {
      color: #b1b1b1;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      // height: 56px;
      display: flex;
      align-items: center;

      &:hover {
        color: #1a1a1a;
      }
    }
  }

  &:last-child {
    & .step-right {
      background: url('../../resources/images/step-end-default-r.png') right top no-repeat;
    }
    &.active {
      & .step-right {
        background: url('../../resources/images/last-step-active-r.png') right top no-repeat;
      }

      & .step_content {
        margin-right: 20px;
      }
    }
  }

  &-animate {
    transition: all 0.3s ease;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &-fade {
    &-enter {
      &-active {
        animation: openStep 0.5s ease forwards;
      }
    }
    &-exit {
      &-exit-active {
        animation: closeStep 0.3s ease forwards;
      }
    }
  }
}

.preview-extension-img .ant-image,
.preview-extension-img {
  // width: 100%;
}

.steps-guide {
  position: relative;
  // min-height: 42vh;
  margin-bottom: 24px;

  &.light {
    .m22-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
      background: #fafafa;
      &:hover {
        background: #f6f6f6;
      }
    }
  }
}

.steps-guide .step-2-tabs {
  &.dark {
    .ant-tabs-tab:not(.ant-tabs-tab-active) {
      background: #32373e;
      border-bottom: 1px solid #333333;
      border-radius: 6px 6px 0 0;

      .ant-tabs-tab-btn {
        color: #666666;
        &:focus {
          color: #1a1a1a;
        }
      }

      &:hover {
        background: #4d545f;
        border-bottom: 1px solid #32373e;

        .ant-tabs-tab-btn {
          color: #bdbdbd;
        }
      }
    }
    .ant-tabs-tab-active {
      color: #1a1a1a;
      background: #fcd535;
      border-bottom: 1px solid #32373e;
      .ant-tabs-tab-btn {
        color: #1a1a1a;

        &:focus {
          color: #1a1a1a;
        }
      }
    }
  }

  &.light {
    .ant-tabs-tab:not(.ant-tabs-tab-active) {
      background: #fafafa;
      border-bottom: 1px solid #f1f1f1;
      border-radius: 6px 6px 0 0;

      .ant-tabs-tab-btn {
        color: #7d7e7e;
        &:focus {
          color: #1a1a1a;
        }
      }

      &:hover {
        background: #f6f6f6;
        border-bottom: 1px solid #f1f1f1;
        .ant-tabs-tab-btn {
          color: #5a5a5a;
        }
      }
    }
    .ant-tabs-tab-active {
      color: #1a1a1a;
      background: #fcd535;
      border-bottom: 1px solid #f1f1f1;
      .ant-tabs-tab-btn {
        color: #1a1a1a;

        &:focus {
          color: #1a1a1a;
        }
      }
    }
  }
}

.tabs-dashboard {
  &.light {
    &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
      background-color: #fcd535 !important;
    }
  }
}
